/*.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}*/

/* scrollReset.css */

html, body {
  scroll-behavior: auto; /* Reset scroll behavior to the default */
  overflow-anchor: none; /* Disable scroll anchor for performance */
  scroll-padding-top: 0; /* Reset scroll padding */
  scroll-snap-margin-top: 0; /* Reset scroll snap margin */
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  scroll-behavior: auto !important; /* Ensure body scroll behavior is set to auto */
}

html {
  scroll-behavior: auto !important; /* Ensure html scroll behavior is set to auto */
}

/*navbar.js*/

.navbar {
  max-width: 1920px;
  margin: 0 auto;
}

.navbar.colorChange {
  background-color: #b9b6b6;
  opacity: .8;
  
}

@media screen and (max-width: 1080px) {
  .navbar.colorChange {
    background-color: transparent;
    opacity: 1;
  }
}

#logo {
  position: relative;
  width: 10%;
  height: 10%;
  position: fixed;
  margin-left: -10%;
}

@media screen and (max-width: 1080px) {
  #logo {
    width: 12%;
    height: 12%;
    margin-left: 0%;
  }
}

.brush-stroke {
  position: absolute;
  width: 20%;
  height: 280%;
  background-image: url(./images/white_brush_stroke.png);
  /*background-image: url(./images/PC2.png);*/
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /*z-index: -1; /* Ensure the brush stroke is behind the logo */
  margin-left: -11%;
  /*border: 3px solid rgb(151, 5, 19);*/
  /*opacity: .5;*/
  margin-top: 1%;
}

@media only screen and (max-width: 1440px) {
  .brush-stroke {
    margin-left: -9%;
  }
}

@media only screen and (max-width: 1280px) {
  .brush-stroke {
    margin-left: -9%;
  }
}

@media only screen and (max-width: 1080px) {
  .brush-stroke {
    display: none;
  }
}

.hamburger {
  display: none;
  margin-top: 5%;
}

@media only screen and (max-width: 1080px) {
  .hamburger {
      display: block;
      cursor: pointer;
      position: fixed;
      left: 90%;
      padding-top: 5%;
      z-index: 100;
  }
}


.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  /*background-color: white;/*#101010;*/
 
}

/*.bar.colorChange {
  background-color: black;
}*/

.hamburger:hover .bar:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.hamburger:hover .bar:nth-child(2) {
  opacity: 0;
}


.hamburger:hover .bar:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

/*top button*/

.to-top-btn {
  position: fixed;
  bottom: 20px;
  /*right: 20px;*/
  font-size: 100%;
  padding: 10px;
  background-color: #541412;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  left: 94%;
  z-index: 20;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5); /* Adjust the shadow size and opacity */
  transition: background-color 0.3s ease; 
}

@media screen and (max-width: 810px) {
  .to-top-btn {
    left: 90%;
  }
}

@media screen and (max-width: 480px) {
  .to-top-btn {
    left: 85%;
  }
}

@media screen and (max-width: 375px) {
  .to-top-btn {
    bottom: 40px;
  }
}

@media screen and (max-width: 360px) {
  .to-top-btn {
    left: 83%;
    bottom: 35px;
  }
}

.to-top-btn:hover {
  background-color: #742d2b;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.8);
}


/*index.js*/

#index_div1 {
  max-width: 1920px;
  margin: 0 auto;
  /*border: 3px solid rgb(151, 5, 19);*/
  height: 100%;
  overflow: hidden;
  /*max-width: 2560px;*/
}


video {
  max-width: 1920px;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
}



#video-background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  -webkit-object-fit: cover;
  object-fit: cover; 
  z-index: 2;
  
}

/*@media only screen and (max-width: 768px) {
  #video-background {
    height: 100%;
  }
}

@media only screen and (max-width: 480px) {
  #video-background {
    height: 100%;
  }
}*/


#test {
  text-align: center;
  /*border: 3px solid rgb(115, 0, 128);*/
  position: relative;
  height: 30rem;
  /*margin-top: 20%;*/
  margin-top: calc(20vh + 10vw);
  z-index: 3;
}

@media only screen and (max-width: 810px) {
  #test {
    margin-top: calc(30vh + 7vw);
  }
}

/* Additional fine-tuning for extremely small or specific screens */
@media only screen and (max-width: 768px) {
  #test {
    margin-top: calc(30vh + 5vw);
  }
}

@media only screen and (max-width: 480px) {
  #test {
    margin-top: calc(35vh + 5vw);
    padding-right: 2%;
    padding-left: 2%;
  }
}

@media only screen and (max-width: 428px) {
  #test {
    margin-top: calc(35vh + 5vw);
    padding-right: 10%;
    padding-left: 10%;
  }
}

@media only screen and (max-width: 375px) {
  #test {
    margin-top: calc(40vh + 5vw);
  }
}

/*@media only screen and (max-width: 1440px) and (orientation: landscape) {
  #test {
    text-align: center;
    /*border: 3px solid rgb(115, 0, 128);*/
   /* position: relative;
    height: 30rem;
    margin-top: 25%;
    z-index: 3;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #test {
    margin-top: 35%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #test {
    margin-top: 20%;
  }
}

@media only screen and (max-width: 896px) and (orientation: landscape) {
  #test {
    margin-top: 10%;
  }
}

@media only screen and (max-width: 810px) {
  #test {
    margin-top: 50%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #test {
    margin-top: 10%;
  }
}


@media only screen and (max-width: 760px) and (orientation: landscape) {
  #test {
    margin-top: 12%;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  #test {
    margin-top: 14%;
  }
}

@media only screen and (max-width: 480px) {
  #test {
    margin-top: 45%;
  }
}

@media only screen and (max-width: 428px) {
  #test {
    margin-top: 65%;
  }
}

@media only screen and (max-width: 375px) {
  #test {
    margin-top: 40%;
  }
}*/



#pic_6 {
  height: 40rem;
  width: 100%;
  opacity: .5;
}

#index_div2 {
  /*border: 3px solid rgb(49, 8, 131);*/
  color:white;
  height: -webkit-fit-content;
  height:fit-content;
  width: -moz-fit-content;
  width:fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  /*font-size: 8ch;*/
  font-size: clamp(2rem, 5vw, 8rem);
  margin: 0 auto;
  font-weight: 900;
  font-family: 'Roboto', sans-serif;
}

/* Additional fine-tuning for smaller screens */
@media (max-width: 760px) {
  #index_div2 {
    font-size: clamp(1.5rem, 5vw, 6rem);
  }
}

@media (max-width: 480px) {
  #index_div2 {
    font-size: clamp(1rem, 8vw, 4rem);
  }
}

/*@media only screen and (max-width: 1440px) and (orientation: landscape) {
  #index_div2 {
    position: relative;
    font-size: 6ch;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #index_div2 {
    position: relative;
    font-size: 5ch;
  }
}

@media only screen and (max-width: 390px) {
  #index_div2 {
    font-size: 5ch;
  }
}*/

#textbox_1 {
  /*border: 3px solid rgb(78, 169, 83);*/
  height: -webkit-fit-content;
  height:fit-content;
  width: -moz-fit-content;
  width:fit-content;
  display: flex;
  align-items: center;
  margin: 0 auto;
  /*margin-top: -15%;*/
  margin-top: calc(100vh * -0.40);
  text-align: center;
  padding: 2rem;
  position: relative;
  /*font-size: 3ch;*/
  font-size: clamp(1rem, 3vw, 3rem);
  font-weight: 400;
  color: white;
  z-index: 3;
}

@media (max-width: 926px) and (orientation: landscape) {
  #textbox_1 {
    font-size: clamp(0.8rem, 4vw, 2.5rem);
    margin-top: calc(100vh * -1);
  }
}

@media (max-width: 812px) and (orientation: landscape) {
  #textbox_1 {
    font-size: clamp(0.8rem, 4vw, 2.5rem);
    margin-top: calc(120vh * -1);
  }
}

@media (max-width: 810px) {
  #textbox_1 {
    font-size: clamp(0.8rem, 4vw, 2.5rem);
    margin-top: calc(100vh * -.3);
  }
}


/* Additional fine-tuning for smaller screens */
@media (max-width: 760px) {
  #textbox_1 {
    font-size: clamp(0.8rem, 4vw, 2.5rem);
    margin-top: calc(100vh * -1.1);
  }
}

@media (max-width: 428px) {
  #textbox_1 {
    font-size: clamp(0.6em, 5vw, 2rem);
    margin-top: calc(100vh * -0.40);
    padding-left: 22%;
    padding-right: 22%;
    
  }
}

@media (max-width: 375px) {
  #textbox_1 {
    font-size: clamp(0.6em, 5vw, 2rem);
    margin-top: calc(100vh * -0.50);
    
  }
}

@media (max-width: 360px) {
  #textbox_1 {
    font-size: clamp(0.6em, 5vw, 2rem);
    margin-top: calc(100vh * -0.50);
    padding-left: 12%;
    padding-right: 12%;
  }
}


/*@media only screen and (max-width: 1440px) and (orientation: landscape) {
  #textbox_1 {
    /*border: 3px solid rgb(78, 169, 83);*/
  /*height: -webkit-fit-content;
  height:fit-content;
  width: -moz-fit-content;
  width:fit-content;
  display: flex;
  align-items: center;
  margin: 0 auto;
  margin-top: -25%;
  text-align: center;
  padding: 2rem;
  position: relative;
  font-size: 3ch;
  color: white;
  z-index: 3;

  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #textbox_1 {
    margin-top: -35%;
  }
}

@media only screen and (max-width: 854px) and (orientation: landscape) {
  #textbox_1 {
    margin-top: -39%;
  }
}

@media only screen and (max-width: 844px) and (orientation: landscape) {
  #textbox_1 {
    margin-top: -38%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #textbox_1 {
    margin-top: -41%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #textbox_1 {
    margin-top: -45%;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  #textbox_1 {
    margin-top: -49%;
  }
}

@media only screen and (max-width: 428px) {
  #textbox_1 {
    margin-top: -45%;
  }
}


@media only screen and (max-width: 375px) {
  #textbox_1 {
    margin-top: -58%;
  }
}*/

#spacer_div {
  position: relative;
  width: 100%;
  height: 40rem;
  /*border: 3px solid rgb(52, 245, 46);*/
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding-top: 10%;
}

/*@media only screen and (max-width: 1080px) {
  #spacer_div {
    height: 40rem;
  }
}*/

/*@media only screen and (max-width: 768px) {
  #spacer_div {
    height: 0%;
  }
}*/

/*@media only screen and (max-width: 480px) {
  #spacer_div {
    height: 0%;
  }
}*/

#spacer_container {
  position: absolute ;
  /*height: -webkit-fit-content;
  height:fit-content;
  width: -moz-fit-content;
  width:fit-content;*/
  width: 80%; /* Or set a specific width if needed */
  height: auto; 
  display: flex;
  text-align: center;
  justify-content: center;
  /*border: 3px solid rgb(241, 27, 27);*/
}

#spacer_sub_div {
  position: relative;
  height:50%;
  width:80%;
  text-align: center;
  justify-content: center;
  padding: 5%;
  /*border: 3px solid rgb(241, 27, 27);*/
}

.center_indexdiv {
  position: relative;
  text-align: center;
  justify-content: center;
  width: 80%;
  height: 98%;
  padding: 5%;
  /*border: 3px solid rgb(17, 7, 216);*/
  opacity: 0;
  transform: translateY(80%);
  transition: opacity 2s, transform 2s ease-in-out;
  margin-bottom: 10%;
}

.center_indexdiv.visible {
  opacity: 1;
  transform: translateY(0);
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  .center_indexdiv {
    margin-bottom: 30%;
  }
}

@media only screen and (max-width: 915px) and (orientation: landscape) {
  .center_indexdiv {
    margin-bottom: 0%;
  }
}


@media only screen and (max-width: 428px) {
  .center_indexdiv {
    flex-direction: column;
    margin-bottom: 30%;
  }
}

@media only screen and (max-width: 414px) {
  .center_indexdiv {
    flex-direction: column;
    margin-bottom: 50%;
  }
}

#explore-services-heading {
  font-size: 5em;
  font-weight: bold;
  text-align: center;
  transition: 0.8s;
  /*text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000, 2px 2px 0 #000;*/
  color: rgb(157, 31, 241);
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #explore-services-heading {
    font-size: 4em;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  #explore-services-heading {
    font-size: 3em;
  }
}

@media only screen and (max-width: 810px) {
  #explore-services-heading {
    font-size: 2.7em;
  }
}

@media only screen and (max-width: 428px) {
  #explore-services-heading {
    font-size: 2.4em;
  }
}

#center_indexdivpara {
  position: relative;
  font-size: 1.5rem;
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #center_indexdivpara {
    font-size: 1.3rem;
  }
}

@media only screen and (max-width: 428px) {
  #center_indexdivpara {
    font-size: 1.1rem;
  }
}

.slid-In_div_container {
  position: relative;
  /*border: 3px solid rgb(241, 27, 27);*/
  /*display: flex;
  flex-direction: row; 
  flex-wrap: wrap;
  justify-content: space-between;*/
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  .slid-In_div_container {
    margin-top: 0%;
  }
}

@media only screen and (max-width: 428px) {
  .slid-In_div_container {
    flex-direction: column
  }
}


.slide_row1 {
  position: relative;
  display: flex;
  /*border: 3px solid rgb(5, 5, 255);*/
  width: 100%;
  box-sizing: border-box;
}

@media only screen and (max-width: 926px) {
  .slide_row1 {
    flex-direction: column
  }
}

.slide-in-div {
  opacity: 0;
  position: relative;
  transform: translateX(-100%);
  transition: opacity 4s, transform 4s;
  width: 50%;
  /*border: 3px solid rgb(255, 84, 5);*/
  /*z-index: 5;*/
}

@media only screen and (max-width: 926px) {
  .slide-in-div {
    width: 100%;
  }
}

.slide-in-div.visible {
  opacity: 1;
  transform: translateX(0);
}

/*.slide-in-div-left {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 4s, transform 4s;
  position: relative;
  width: 50%;
  margin-top: -16%;
  z-index: 5;
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  .slide-in-div-left {
    margin-top: -36%;
  }
}

@media only screen and (max-width: 926px) {
  .slide-in-div-left {
    width: 100%;
    margin-top: 0%;
  }
}*/

#textbox_2 {
  position: relative;
  /*background-color: #541412;*/
  background-image: url(./images/pic_3.jpeg);
  background-size: 100% 100%;
  height: 40rem;
  width: 100%;
  margin-left: 0%;
  /*border: 3px solid rgb(247, 247, 5);*/
  margin-top: 0%;
  /*box-shadow: 0 16px 32px 0 rgba(187, 183, 183, 0.972), 0 6px 20px 0 rgba(187, 183, 183, 0.972);*/
  z-index: 6;
  text-align: center;
  color: #45088b;
}

@media only screen and (max-width: 926px) {
  #textbox_2 {
    position: relative;
    width: 100%;
    height: 25rem;
  }
}

/*@media only screen and (max-width: 1440px) and (orientation: landscape) {
  #textbox_2 {
    position: relative;
    background-color: #541412;
    height: 26rem;
    width: 60%;
    margin-left: 10%;
    margin-top: 20%;
    box-shadow: 0 16px 32px 0 rgba(187, 183, 183, 0.972), 0 6px 20px 0 rgba(187, 183, 183, 0.972);
    z-index: 3;
  }
}*/

/*@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #textbox_2 {
    position: relative;
    width: 300%;
    height: 20rem;
  }
}

/*@media only screen and (max-width: 926px) and (orientation: landscape) {
  #textbox_2 {
    position: relative;
    margin-left: -5%;
  }
}

@media only screen and (max-width: 810px) {
  #textbox_2 {
    position: relative;
    width: 97%;
    margin-left: 2%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #textbox_2 {
    position: relative;
    margin-left: -5%;
    height: 20rem;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #textbox_2 {
    position: relative;
    width: 90%;
    margin-top: 40%;
    margin-left: -10%;
  }
}

@media only screen and (max-width: 428px) {
  #textbox_2 {
    position: relative;
    width: 98%;
    margin-left: 2%;
    margin-top: 35%;
  }
}

@media only screen and (max-width: 412px) {
  #textbox_2 {
    position: relative;
    margin-top: 30%;
  }
}

@media only screen and (max-width: 360px) {
  #textbox_2 {
    position: relative;
    width: 98%;
    margin-left: 2%;
    margin-top: 40%;
  }
}*/

/*#textbox_2_subdiv {
  position: absolute;
  top: 30%;
  border: 3px solid rgb(229, 17, 180);
}*/



#white-space1 {
  position: relative;
  height: 27rem;
  width: 50%;
  /*border: 3px solid rgb(229, 17, 180);*/
  margin-left: 0%;
  font-size: 1.1rem;
}

@media only screen and (max-width: 926px) {
  #white-space1 {
    width: 100%;
  }
}

.separator2 {
  position: absolute;
  border-top: 1px solid black; /* Change color and width as needed */
  margin: 20px 0;
  display: none;
}

@media only screen and (max-width: 926px) {
  .separator2 {
    display: block;
    width: 80%;
    margin-left: 10%;
    margin-top: -3%;
  }
}

@media only screen and (max-width: 810px) {
  .separator2 {
    display: block;
    width: 80%;
    margin-left: 10%;
    margin-top: -5%;
  }
}

#white-space2 {
  position: relative;
  height: 27rem;
  width: 50%;
  /*border: 3px solid rgb(223, 253, 52);*/
  margin-left: 0%;
  font-size: 1.1rem;
}

@media only screen and (max-width: 926px) {
  #white-space2 {
    width: 100%;
  }
}

.white-space-text {
  /*text-align: center;*/
  padding: 5%;
}

@media only screen and (max-width: 1440px) and (orientation: landscape) {
  .white-space-text {
    text-align: center;
    padding: 10%;
  }
}

#textbox_sometext2 {
  position: relative;
  width: 100%;
  height: 40rem;
  margin-left: 0%;
  /*border: 3px solid rgb(52, 245, 46);*/
  margin-top: 0%;
}

/*@media only screen and (max-width: 1440px) and (orientation: landscape) {
  #textbox_sometext2 {
    position: relative;
    margin-top: -0%;
  }
}*/

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #textbox_sometext2 {
   position: relative;
   
  }
}

@media only screen and (max-width: 926px) {
  #textbox_sometext2 {
    width: 100%;
    height: 25rem;
    margin-left: 0%;
    margin-top: 0%;
  }
}

/*@media only screen and (max-width: 896px) and (orientation: landscape) {
  #textbox_sometext2 {
    margin-top: -65%;
    margin-left: 65%;
    width: 70%;
  }
}*/


@media only screen and (max-width: 810px) {
  #textbox_sometext2 {
    height: 38rem;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #textbox_sometext2 {
    height: 25rem;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #textbox_sometext2 {
    height: 18rem;
  }
}

@media only screen and (max-width: 428px) {
  #textbox_sometext2 {
    height: 45rem;
  }
}


/*@media only screen and (max-width: 480px) {
  #textbox_sometext2 {
    margin-left: 20%;
    width: 70%;
  }
}

@media only screen and (max-width: 428px) {
  #textbox_sometext2 {
    height: 65%;
    margin-left: 15%;
  }
}

@media only screen and (max-width: 414px) {
  #textbox_sometext2 {
    height: 68%;
  }
}*/

@media only screen and (max-width: 390px) {
  #textbox_sometext2 {
    height: 50rem;
  }
}

/*@media only screen and (max-width: 360px) {
  #textbox_sometext2 {
    margin-top: -60%;

  }
}*/

#someText1 {
  position: absolute;
  /*text-align: center;*/
  font-size: 20px;
  border: 3px solid transparent;
  width: 100%;
  height: 100%;
  /*margin-left: 60%;
  margin-top: -45%;*/
  color: white;
  padding-top: 10%;
  background: linear-gradient(to bottom right, #000, #45088b, #7209b7, #c625f7);
  padding-left: 5%;
  padding-right: 5%;
  /*border: 3px solid rgb(52, 245, 46);*/
  /*border-radius: 10px; */
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #someText1 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #someText1 {
    height: 150%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #someText1 {
    padding-top: 5%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #someText1 {
    padding-top: 2%;
    font-size: 15px;
  }
}

@media only screen and (max-width: 428px) {
  #someText1 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 375px) {
  #someText1 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 360px) {
  #someText1 {
    font-size: 18px;
  }
}

.separator {
  border-top: 1px solid white; /* Change color and width as needed */
  margin: 20px 0;
}


/*BGanimation*/

.container {
  position: relative;
  width: 100%;
  height: 40rem; /* Make the container tall to allow scrolling */
  /*border: 3px solid #34f52e;*/
}


#bg_container1 {
  position: relative;
  width: 100%;
  height: 40rem; /* Make the container tall to allow scrolling */
  margin-top: -100%;
  /*border: 3px solid #3a07a8;*/
  box-sizing: border-box;
  z-index: 0;
}

#bg_container2 {
  position: relative;
  width: 100%;
  height: 40rem; /* Make the container tall to allow scrolling */
  margin-top: 0%;
  /*border: 3px solid #3a07a8;*/
  box-sizing: border-box;
  z-index: 0;
}

.circle1 {
  position: absolute;
  background-color: #cf2823;
  width: 40rem;
  height: 40rem;
  left: 50%;
  border-radius: 50%;
  margin-top: 20%;
  opacity: .1;
  transition: transform 0.1s; /* Add a transition for smoother animation */
  z-index: 1;
}

@media only screen and (max-width: 1440px) and (orientation: landscape) {
  .circle1 {
    margin-top: -20%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  .circle1 {
    margin-top: -80%;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  .circle1 {
    margin-top: -120%;
  }
}

@media only screen and (max-width: 810px) {
  .circle1 {
    margin-top: -130%;
  }
}

@media only screen and (max-width: 428px) {
  .circle1 {
    margin-top: -350%;
  }
}

@media only screen and (max-width: 390px) {
  .circle1 {
    margin-top: -400%;
  }
}

.circle2 {
  position: absolute;
  background-color: #3a23cf;
  width: 40rem;
  height: 40rem;
  left: 10%;
  border-radius: 50%;
  margin-top: -60%;
  opacity: .1;
  transition: transform 0.1s; /* Add a transition for smoother animation */
}

.animate-circle {
  animation: moveCircle .5s linear; /* Removed 'infinite' to stop continuous animation */
}

@keyframes moveCircle {
  0% {
    transform: translateY(50);
  }
  50% {
    transform: translateY(100px); /* Adjust the movement distance as needed */
  }
  100% {
    transform: translateY(50);
  }
}

/*BGwordanimation.js*/

.word_container {
  position: relative;
  width: 100%;
  height: 40rem; /* Make the container tall to allow scrolling */
  /*border: 3px solid rgb(245, 46, 212);*/
}


.word1 {
  position: absolute;
  width: 85rem;
  height: 10rem;
  right: 45%;
  /*border-radius: 50%;*/
  margin-top: 65%;
  opacity: .1;
  transition: transform 0.2s; /* Add a transition for smoother animation */
}

@media only screen and (max-width: 1440px) and (orientation: landscape) {
  .word1 {
    position: absolute;
    margin-top: 57%;
  }
}

@media screen and (max-width: 1080px) and (orientation: landscape) {
  .word1 {
    margin-top: 45%;
  }
}

@media screen and (max-width: 926px) and (orientation: landscape) {
  .word1 {
    right: 45%;
    margin-top: 55%;
  }
}

@media screen and (max-width: 810px) {
  .word1 {
    right: 45%;
    margin-top: 30%;
  }
}

@media screen and (max-width: 428px) {
  .word1 {
    right: 0%;
    margin-top: -40%;
  }
}


/*@media screen and (max-width: 810px) {
  .word1 {
    right: -60%;
    width: 80rem;
  }
}


@media screen and (max-width: 760px) and (orientation: landscape) {
  .word1 {
    right: -100%;
    margin-top: -30%;
  }
}

@media only screen and (max-width: 480px) {
  .word1 {
    width: 80rem;
    right: 0%;
  }
}

@media only screen and (max-width: 428px) {
  .word1 {
    right: -170%;
    margin-top: -35%;
  }
}

@media only screen and (max-width: 412px) {
  .word1 {
    margin-top: -35%;
  }
}

@media only screen and (max-width: 390px) {
  .word1 {
    right: -190%;
    margin-top: -35%;
  }
}*/

@media only screen and (max-width: 375px) {
  .word1 {
    margin-top: -50%;
  }
}

/*@media only screen and (max-width: 360px) {
  .word1 {
    right: -230%;
    margin-top: -40%;
  }
}*/


#wordanimation {
  position: relative;
  font-size: 80px;
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
}

@media only screen and (max-width: 480px) {
  #wordanimation {
    font-size: 60px;
  }
}

.word2 {
  position: absolute;
  width: 80rem;
  height: 10rem;
  right: 60%;
  /*border-radius: 50%;*/
  margin-top: -30%;
  opacity: .1;
  transition: transform 0.2s; 
}

@media only screen and (max-width: 1440px) and (orientation: landscape) {
  .word2 {
    position: absolute;
    width: 80rem;
    height: 10rem;
    right: 70%;
    margin-top: -40%;
    opacity: .1;
    transition: transform 0.2s;
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  .word2 {
    margin-top: -45%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  .word2 {
    margin-top: -55%;
    
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  .word2 {
    margin-top: -60%;
    right: 110%;
  }
}

@media only screen and (max-width: 896px) and (orientation: landscape) {
  .word2 {
    margin-top: -70%;
    right: 110%;
  }
}

@media only screen and (max-width: 810px) {
  .word2 {
    margin-top: -70%;
    right: 100%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  .word2 {
    margin-top: -80%;
    right: 120%;
  }
}

/*@media only screen and (max-width: 760px) and (orientation: landscape) {
  .word2 {
    margin-top: 75%;
    right: 90%;
  }
}

@media only screen and (max-width: 480px) {
  .word2 {
    margin-top: 120%;
    right: 170%;
  }
}*/

@media only screen and (max-width: 428px) {
  .word2 {
    margin-top: -130%;
    right: 150%;
  }
}

/*@media only screen and (max-width: 414px) {
  .word2 {
    margin-top: 190%;
  }
}

@media only screen and (max-width: 390px) {
  .word2 {
    margin-top: 220%;
    right: 120%;
  }
}*/

@media only screen and (max-width: 375px) {
  .word2 {
    margin-top: -150%;
    right: 170%;
  }
}

/*@media only screen and (max-width: 360px) {
  .word2 {
    margin-top: 215%;
    
  }
}*/

.animate-word {
  animation: moveWord 2s linear; /* Removed 'infinite' to stop continuous animation */
}

@keyframes moveWord {
  0% {
    transform: translateX(50);
  }
  50% {
    transform: translateX(100px); /* Adjust the movement distance as needed */
  }
  100% {
    transform: translateX(50);
  }
}

/*end animations*/

/*slideshow.js*/

.your-component {
  position: relative;
  width: 100%;
  height: 30rem;
  /*border: 3px solid rgb(147, 24, 203);*/
  z-index: 10;
  margin-top: 70%;
 }
 
 @media only screen and (max-width: 1440px) and (orientation: landscape) {
   .your-component {
     position: relative;
     margin-top: 60%;
   }
 }

 @media only screen and (max-width: 1080px) and (orientation: landscape) {
  .your-component {
    position: relative;
    margin-top: 50%;
  }
}
 
 @media only screen and (max-width: 926px) and (orientation: landscape) {
   .your-component {
     position: relative;
     margin-top: 60%;
     width: 100%;
     height: 27rem;
     z-index: 10;
   }
 }

 @media only screen and (max-width: 810px) {
  .your-component {
    position: relative;
    margin-top: 45%;
  }
}

 @media only screen and (max-width: 800px) and (orientation: landscape) {
  .your-component {
    position: relative;
    margin-top: 85%;
  }
}
 
 @media only screen and (max-width: 428px) {
   .your-component {
     height: 40rem;
     margin-top: -40%;
   }
 }
 
 @media only screen and (max-width: 412px) {
   .your-component {
     margin-top: -50%;
   }
 }
 
 @media only screen and (max-width: 384px) {
   .your-component {
     margin-top: -55%;
   }
 }
 
 @media only screen and (max-width: 360px) {
   .your-component {
     margin-top: -60%;
   }
 }

.list-box {
  position: relative;
  display: inline;
  /*border: 3px solid rgb(16, 225, 215);*/
  z-index: 10;
}


#listbox_heading {
  position: relative;
  width: 90%;
  /*border: 3px solid rgb(16, 225, 215);*/
  left: 17%;
}

@media only screen and (max-width: 1440px) and (orientation: landscape) {
  #listbox_heading {
    position: relative;
    width: 90%;
    left: 10.5%;
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #listbox_heading {
    position: relative;
    margin-top: 10%;
    left: 8%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #listbox_heading {
    left: 5%;
    margin-top: 0%;
    width: 100%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #listbox_heading {
    left: 5%;
  }
}

@media only screen and (max-width: 896px) and (orientation: landscape) {
  #listbox_heading {
    left: 2.5%;
  }
}

@media only screen and (max-width: 854px) and (orientation: landscape) {
  #listbox_heading {
    left: 6%;
  }
}

@media only screen and (max-width: 844px) and (orientation: landscape) {
  #listbox_heading {
    left: 6%;
  }
}

@media only screen and (max-width: 810px) {
  #listbox_heading {
    left: 30%;
    margin-top: 10%;
    width: 70%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #listbox_heading {
    left: 4%;
    margin-top: -25%;
    width: 100%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #listbox_heading {
    left: 0%;
    margin-top: -25%;
    width: 100%;
  }
}

@media only screen and (max-width: 428px) {
  #listbox_heading {
    left: 20%;
    margin-top: 35%;
    width: 70%;
  }
}


@media only screen and (max-width: 390px) {
  #listbox_heading {
    left: 15%;
    margin-top: 45%;
    width: 70%;
  }
}

@media only screen and (max-width: 384px) {
  #listbox_heading {
    left: 20%;
  }
}

@media only screen and (max-width: 375px) {
  #listbox_heading {
    margin-left: 0%;
  }
}

@media only screen and (max-width: 360px) {
  #listbox_heading {
    margin-left: -5%;
  }
}

.list-box ul li {
  cursor: pointer;
  display: inline-block;
  padding-right: 9%;
  z-index: 10;
  /*border: 3px solid rgb(16, 225, 215);*/
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  .list-box ul li {
    height: -webkit-fit-content;
    height:fit-content;
    width: -moz-fit-content;
    width:fit-content;
    padding-right: 5%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  .list-box ul li {
    padding-right: 5%;
    height: -webkit-fit-content;
    height:fit-content;
    width: -moz-fit-content;
    width:fit-content;
  }
}


@media only screen and (max-width: 844px) and (orientation: landscape) {
  .list-box ul li {
    padding-right: 3%;
  }
}

@media only screen and (max-width: 810px) {
  .list-box ul li {
    display:list-item;
    list-style-position:outside;
    list-style-type: disc; /* Use disc for filled circles */
    /*list-style-position: inside;*/
    padding-right: 0%;
    height: -webkit-fit-content;
    height:fit-content;
    width: -moz-fit-content;
    width:fit-content;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  .list-box ul li {
    display: inline-block;
    padding-right: 5%;
    
  }
}


@media only screen and (max-width: 760px) and (orientation: landscape) {
  .list-box ul li {
    display: inline-block;
    padding-right: 3%;
    
  }
}


@media only screen and (max-width: 480px) {
  .list-box ul li {
    display:list-item;
    list-style-position:outside;
    list-style-type: disc; /* Use disc for filled circles */
    /*list-style-position: inside;*/
    padding-right: 0%;
    height: -webkit-fit-content;
    height:fit-content;
    width: -moz-fit-content;
    width:fit-content;
  }
}


@media only screen and (max-width: 360px) {
  .list-box ul li {
    display:list-item;
    list-style-position:outside;
    list-style-type: disc; /* Use disc for filled circles */
    /*list-style-position: inside;*/
    padding-right: 0%;
    height: -webkit-fit-content;
    height:fit-content;
    width: -moz-fit-content;
    width:fit-content;
    margin-left: 10%;
  }
}

.list-box ul li h2{
  position: relative;
  font-weight:800;
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  .list-box ul li h2{
    position: relative;
    font-size: 24px;
  }
}

/*@media only screen and (max-width: 854px) and (orientation: landscape) {
  .list-box ul li h2{
    position: relative;
    font-size: 24px;
  }
}*/

@media only screen and (max-width: 760px) and (orientation: landscape) {
  .list-box ul li h2{
    position: relative;
    font-size: 25px;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  .list-box ul li h2{
    position: relative;
    font-size: 22px;
  }
}

.list_element {
  position: relative;
  /*border-bottom: 2px solid;*/
}

.list_element:hover {
  color: #cb1707;
}

.list_element::before,
.list_element::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 50%; /* Start from the center */
  width: 0; /* Start with no width */
  height: 2px;
  background-color: #cb1707;
  transition: width 0.3s ease, left 0.3s ease;
}

.list_element::before {
  transform: translateX(-45%);
}

.list_element::after {
  transform: translateX(45%);
}

.list_element:hover::before {
  width: 50%; /* Extend to the left */
  left: 25%; /* Move the pseudo-element to the left edge */
}

.list_element:hover::after {
  width: 50%; /* Extend to the right */
  left: 25%; /* Move the pseudo-element to the right edge */
}



.list-box ul li:active  {
  opacity: 1;
}

/*slide components*/

.slide_divs_fonts {
  font-size: 25px;
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  .slide_divs_fonts {
    font-size: 22px;
  }
}


@media only screen and (max-width: 896px) and (orientation: landscape) {
  .slide_divs_fonts {
    font-size: 22px;
  }
}

@media only screen and (max-width: 854px) and (orientation: landscape) {
  .slide_divs_fonts {
    font-size: 20px;
  }
}

@media only screen and (max-width: 844px) and (orientation: landscape) {
  .slide_divs_fonts {
    font-size: 20px;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  .slide_divs_fonts {
    font-size: 20px;
  }
}

@media only screen and (max-width: 480px) {
  .slide_divs_fonts {
    font-size: 22px;
  }
}

@media only screen and (max-width: 414px) {
  .slide_divs_fonts {
    font-size: 20px;
  }
}

@media only screen and (max-width: 390px) {
  .slide_divs_fonts {
    font-size: 18px;
  }
}

#slide_div1 {
  position: relative;
  
  width: 60%;
  height: 70%;
  margin-left: 20%;
  margin-top: 7%;
  /*border: 3px solid rgb(24, 94, 207);*/
  z-index: 10;
  text-align: center;
}

#slide_div2 {
  position: relative;
  
  width: 60%;
  height: 70%;
  margin-left: 20%;
  margin-top: 7%;
  /*border: 3px solid rgb(24, 94, 207);*/
  z-index: 10;
  text-align: center
}

#slide_div3 {
  position: relative;
 
  width: 60%;
  height: 70%;
  margin-left: 20%;
  margin-top: 7%;
  /*border: 3px solid rgb(24, 94, 207);*/
  z-index: 10;
  text-align: center
}

.text-box {
  width: 100%;
  height: 80%;
  /*border: 3px solid rgb(78, 169, 83);*/
  animation-duration: .5s;
  z-index: 10;
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  .text-box {
    margin-top: -5%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  .text-box {
    margin-top: -3%;
  }
}

@media only screen and (max-width: 480px) {
  .text-box {
    margin-top: 10%;
  }
}

.fade-in {
  animation-name: fade-in;
  z-index: 10;
}

.fade-out {
  animation-name: fade-out;
  z-index: 10;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/*end slideshow*/

#pic_2 {
  position: relative;
  height: 24rem;
  margin-top: 1.5%;
  
}

.slide-in-div-left {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 4s, transform 4s;
  position: relative;
  width: 50%;
  margin-top: -16%;
  z-index: 5;
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  .slide-in-div-left {
    margin-top: -26%;
  }
}

@media only screen and (max-width: 926px) {
  .slide-in-div-left {
    width: 100%;
    margin-top: 0%;
  }
}


.slide-in-div-left.visible {
  opacity: 1;
  transform: translateX(0);
}

/*copy*/
.slide-in-div {
  opacity: 0;
  position: relative;
  transform: translateX(-100%);
  transition: opacity 4s, transform 4s;
  width: 50%;
  /*border: 3px solid rgb(255, 84, 5);*/
  z-index: 8;
}

@media only screen and (max-width: 926px) {
  .slide-in-div {
    width: 100%;
  }
}

.slide-in-div.visible {
  opacity: 1;
  transform: translateX(0);
}

/*end copy*/

.slid-In_div_container2 {
  position: relative;
  /*border: 3px solid rgb(241, 27, 27);*/
  display: flex;
  flex-direction: row; 
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 0%;
}

@media only screen and (max-width: 926px) {
  .slid-In_div_container2 {
    flex-direction: column;
    flex-wrap: nowrap;
    
  }
}

@media only screen and (max-width: 810px) {
  .slid-In_div_container2 {
    margin-top: 10%;
    
  }
}

.slide_row2 {
  position: relative;
  /*display: flex;*/
  /*border: 3px solid rgb(5, 5, 255);*/
  width: 100%;
  /*box-sizing: border-box;*/

}

@media only screen and (max-width: 926px) {
  .slide_row2 {
    position: relative;
  }
}


.sub_slide_row1 {
  position: relative;
  /*display: flex;*/
  /*flex-direction: column;*/
  /*border: 3px solid rgb(126, 251, 23);*/
}

.someText2 {
  position: relative;
  /*text-align: center;*/
  /*border-bottom: 1px solid transparent;*/
  width: 100%;
  height: 13.35rem;
  margin-left: 0%;
  margin-top: 0%;
  color: white;
  /*border: 3px solid rgb(243, 123, 11);*/
  background: linear-gradient(90deg, rgb(209, 159, 242), rgb(157, 31, 241));
  cursor: pointer; /* Pointer cursor on hover */
  transition: opacity 2s,transform 0.8s;
  /*z-index: 6;*/
  border-bottom: 3px solid rgb(237, 225, 245);
   
}

.someText2:hover {
  background: linear-gradient(90deg,rgb(157, 31, 241), rgb(209, 159, 242));

}

/*@media only screen and (max-width: 1440px) and (orientation: landscape) {
  .someText2 {
    position: relative;
    text-align: center;
    font-size: 45px;
    /*{border: 3px solid transparent;*/
    /*width: 100%;
    /*height: 10%;*/
   /* margin-left: 0%;
    margin-top: 0%;
    color: white;
  }
}

/*@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #someText2 {
    height: 92%

  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #someText2 {
    margin-left: -10%;
    width: 80%;
    height: 80%;
    margin-top: -65%;
    margin-left: -35%;

  }
}

@media only screen and (max-width: 896px) and (orientation: landscape) {
  #someText2 {
    margin-left: -35%;
    width: 70%;
    margin-top: -65%;
  }
}

@media only screen and (max-width: 854px) and (orientation: landscape) {
  #someText2 {
    height: 80%;
  }
}


@media only screen and (max-width: 810px) {
  #someText2 {
    margin-left: -5%;
    width: 70%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #someText2 {
    margin-left: -10%;
    margin-top: -40%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #someText2 {
    width: 75%;
    height: 85%;
    margin-top: -50%;
    margin-left: -30%;
  }
}

@media only screen and (max-width: 480px) {
  #someText2 {
    margin-left: 10%;
    width: 70%;
    margin-top: -60%;
  }
}

@media only screen and (max-width: 428px) {
  #someText2 {
    margin-left: 15%;
    width: 70%;
    margin-top: -50%;
  }
}

@media only screen and (max-width: 412px) {
  #someText2 {
    height: 80%;
  }
}

@media only screen and (max-width: 390px) {
  #someText2 {
    height: 72%;
    margin-top: -40%;
  }
}


@media only screen and (max-width: 375px) {
  #someText2 {
   margin-top: -60%;
  }
}*/

#someTextsub2 {
  position: relative;
  text-align: center;
  height: 100%;
  width: 100%;
  color: white;
  display: flex;
  align-items: center; /* Vertically center items */
  justify-content: center;
  /*border: 3px solid rgb(78, 7, 79);*/
}

#someTextsub2 h1 {
  font-size: 5rem;
  font-weight: bold;
}

@media only screen and (max-width: 428px) {
  #someTextsub2 h1 {
    font-size: 4rem;
    font-weight: bold;
  }
}

/*#someTextsub2:hover {
  background: linear-gradient(90deg,rgb(1, 98, 254), #1e90ff);

}

/*#someTextsub2:hover {
  background: linear-gradient(90deg,rgb(1, 98, 254), #1e90ff);

}*/

/*@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #someTextsub2 {
    font-size: 15px;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #someTextsub2 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 896px) and (orientation: landscape) {
  #someTextsub2 {
    font-size: 17px;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #someTextsub2 {
    padding-top: 5%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #someTextsub2 {
    padding-top: 5%;
    font-size: 15px;
  }
}

@media only screen and (max-width: 480px) {
  #someTextsub2 {
    font-size: 17px;
  }
}*/

#someTextsub3 {
  position: relative;
  /*text-align: center;*/
  font-size: 15px;
  height: 100%;
  width: 100%;
  color: white;
  padding: 3%;
  /*border-radius: 10px;*/
}

#someTextsub4 {
  position: relative;
  /*text-align: center;*/
  font-size: 15px;
  height: 100%;
  width: 100%;
  color: white;
  padding: 3%;
  /*border-radius: 10px;*/
}

#textbox_3 {
  position: relative;
  /*background-color:  #541412;*/
  background-image: url(./images/pic_4.jpeg);
  background-size: 100% 100%;
  height: 40rem;
  width: 100%;
  left: 100%;
  /*border: 3px solid rgb(78, 7, 79);*/
  margin-top: -67.2%;
 /* box-shadow: 0 16px 32px 0 rgba(187, 183, 183, 0.972), 0 6px 20px 0 rgba(187, 183, 183, 0.972);*/
}


@media only screen and (max-width: 1440px) and (orientation: landscape) {
  #textbox_3 {
    position: relative;
    margin-top: -90%;
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #textbox_3 {
    position: relative;
    height: 39.8rem;
    margin-top: -100%;
  }
  
}



@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #textbox_3 {
    margin-top: -118.4%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #textbox_3 {
    left: 0%;
    margin-top: 0%;
    height: 25rem;
  }
}

@media only screen and (max-width: 810px) {
  #textbox_3 {
    height: 25rem;
    left: 0%;
    margin-top: 0%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #textbox_3 {
    left: 0%;
    margin-top: 0%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #textbox_3 {
    left: 0%;
    margin-top: 0%;
  }
}

/*@media only screen and (max-width: 428px) {
  #textbox_3 {
    margin-top: 5%;
    width: 98%;
    left: 2%;
  }
}*/

#index_info_div {
  position: absolute;
  width: 100%;
  height: 45rem;
  margin-top: -20%;
  /*border: 3px solid rgb(240, 61, 12);*/
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1440px) {
  #index_info_div {
    margin-top: -30%;
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #index_info_div {
    margin-top: -35%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #index_info_div {
    margin-top: -40%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #index_info_div {
    flex-direction: column;
    margin-top: -50%;
    display: flex;
    justify-content:left;
    align-items: normal;
  }
}

@media only screen and (max-width: 896px) and (orientation: landscape) {
  #index_info_div {
    margin-top: -60%;
  }
}

@media only screen and (max-width: 810px) {
  #index_info_div {
    flex-direction: column;
    margin-top: -55%;
    display: flex;
    justify-content:left;
    align-items: normal;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #index_info_div {
    margin-top: -65%;
  }
}

@media only screen and (max-width: 428px) {
  #index_info_div {
    margin-top: -95%;
  }
}

@media only screen and (max-width: 412px) {
  #index_info_div {
    margin-top: -110%;
  }
}

#index_info_div_sub1 {
  position: relative;
  /*border: 3px solid rgb(7, 31, 243);*/
  /*height: -webkit-fit-content;
  height:fit-content;
  width: -moz-fit-content;
  width:fit-content;*/
  width: 33%;
  height: 100%;
  font-size: 1.5rem;
  /*padding: 5%;*/
  padding-left: 10%;
  padding-top: 3%;
  text-align: right;
}

@media only screen and (max-width: 926px) {
  #index_info_div_sub1 {
    width: 100%;
    height: 50%;
    padding-left: 5%;
    padding-bottom: 5%;
    text-align: left;
  }
}

@media only screen and (max-width: 926px) {
  #index_info_div_sub1 {
    padding-bottom: 10%;
  }
}

#index_info_div_sub1_heding {
  position: relative;
  font-size: 4rem;
  font-weight: bolder;
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #index_info_div_sub1_heding {
    font-size: 3rem;
    padding-top: 4%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #index_info_div_sub1_heding {
    font-size: 3rem;
    padding-top: 0%;
  }
}

@media only screen and (max-width: 428px) {
  #index_info_div_sub1_heding {
    font-size: 2.6rem;
    padding-top: 0%;
  }
}

#index_info_div_sub2 {
  position: relative;
  /*border: 3px solid rgb(25, 138, 6);*/
  /*height: -webkit-fit-content;
  height:fit-content;
  width: -moz-fit-content;
  width:fit-content;*/
  width: 33%;
  height: 100%;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 4%;
}

@media only screen and (max-width: 926px) {
  #index_info_div_sub2 {
    width: 100%;
    height: 60%;
    padding-top: 0%;
    padding-bottom: 5%;
    margin-top: -5%;
  }
}

#index_info_1 {
  position: relative;
  /*border: 3px solid rgb(227, 7, 243);*/
  margin-bottom: 5%;
  height: 40%;
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #index_info_1 {
    height: 50%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #index_info_1 {
    margin-bottom: -3%;
  }
}

@media only screen and (max-width: 428px) {
  #index_info_1 {
    margin-bottom: 15%;
  }
}

@media only screen and (max-width: 375px) {
  #index_info_1 {
    margin-bottom: 20%;
  }
}

#index_info_2 {
  position: relative;
  /*border: 3px solid rgb(243, 212, 7);*/
}

#index_info_div_sub3 {
  position: relative;
  /*border: 3px solid rgb(7, 243, 239);*/
  /*height: -webkit-fit-content;
  height:fit-content;
  width: -moz-fit-content;
  width:fit-content;*/
  width: 33%;
  height: 100%;
  padding-right: 12%;
  padding-top: 4%;
}

@media only screen and (max-width: 926px) {
  #index_info_div_sub3 {
    width: 100%;
    height: 60%;
    padding-top: 0%;
    padding-left: 5%;
    padding-right: 5%;
  }
}

@media only screen and (max-width: 428px) {
  #index_info_div_sub3 {
   margin-top: 20%;
  }
}

@media only screen and (max-width: 390px) {
  #index_info_div_sub3 {
   margin-top: 20%;
  }
}

@media only screen and (max-width: 375px) {
  #index_info_div_sub3 {
   margin-top: 40%;
  }
}

#index_info_3 {
  position: relative;
  /*border: 3px solid rgb(227, 7, 243);*/
  height: 40%;
  margin-bottom: 5%;
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #index_info_3 {
    height: 50%;
  }
}
@media only screen and (max-width: 926px) and (orientation: landscape) {
  #index_info_3 {
    margin-bottom: -3%;
  }
}

@media only screen and (max-width: 360px) {
  #index_info_3 {
    margin-bottom: 25%;
  }
}

#index_info_4 {
  position: relative;
  /*border: 3px solid rgb(243, 212, 7);*/
}

.index_info_heading {
  font-size: 2rem;
  font-weight: bolder;
}

.index_info_subheading {
  font-size: 1.5rem;
  font-weight: bolder;
  color: #9F496E;
}

#index_div_container {
  position: relative;
  width: 100%;
  height: -webkit-fit-content;
  height:fit-content;
  /*border: 3px solid rgb(50, 246, 53);*/
  margin-top: 25%;
}

@media only screen and (max-width: 1440px) {
  #index_div_container {
    margin-top: 45%;
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #index_div_container {
    margin-top: 75%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #index_div_container {
    margin-top: 60%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #index_div_container {
    margin-top: 65%;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  #index_div_container {
    margin-top: 70%;
  }
}

@media only screen and (max-width: 810px) {
  #index_div_container {
    margin-top: 100%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #index_div_container {
    margin-top: 80%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #index_div_container {
    margin-top: 90%;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  #index_div_container {
    margin-top: 120%;
  }
}

@media only screen and (max-width: 428px) {
  #index_div_container {
    margin-top: 250%;
  }
}

@media only screen and (max-width: 390px) {
  #index_div_container {
    margin-top: 300%;
  }
}

@media only screen and (max-width: 360px) {
  #index_div_container {
    margin-top: 350%;
  }
}

#bottom_index_heading1 {
  position: relative;
  display: flex;
  justify-content: center;
  /*border: 3px solid rgb(50, 246, 53);*/
  width: 40%;
  height: 10rem;
  margin-top:0%;
  left: 10%;
  text-align: center;
  z-index: 10;
  /*text-shadow: 
      -2px -2px 0 #000,  
      2px -2px 0 #000,
      -2px 2px 0 #000,
      2px 2px 0 #000; */
}

@media only screen and (max-width: 1440px) {
  #bottom_index_heading1 {
    position: relative;
    width: 40%;
    height: 10rem;
    margin-top: -10%;
    left: 10%;
    text-align: center;
    z-index: 10;
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) { 
  #bottom_index_heading1 {
    margin-top: -30%;
    left: 10%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) { 
  #bottom_index_heading1 {
    margin-top: 65%;
    left: 10%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #bottom_index_heading1 {
    left: 10%;
    margin-top: 35%;
  }
}

@media only screen and (max-width: 844px) and (orientation: landscape) {
  #bottom_index_heading1 {
    left: 9%;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  #bottom_index_heading1 {
    left: 9%;
    margin-top: 45%;
  }
}

@media only screen and (max-width: 810px) {
  #bottom_index_heading1 {
    margin-top: 30%;
    left: 9%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #bottom_index_heading1 {
    margin-top: -9%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #bottom_index_heading1 {
    margin-top: -8%;
    left: 8%;
  }
}

@media only screen and (max-width: 428px) {
  #bottom_index_heading1 {
    margin-top: -20%;
    left: 31%;
  }
}

/*@media only screen and (max-width: 414px) {
  #bottom_index_heading1 {
    margin-top: 65%;
    left: 31%;
  }
}*/

@media only screen and (max-width: 360px) {
  #bottom_index_heading1 {
    margin-top: -30%;
  }
}

#bottom_index_heading1 h1 {
  position: relative;
  font-size: 7em;
  font-weight: bolder;
  color: rgb(157, 31, 241);
}

@media only screen and (max-width: 1280px) and (orientation: landscape) { 
  #bottom_index_heading1 h1 {
    font-size: 6em;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) { 
  #bottom_index_heading1 h1 {
    font-size: 5em;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) { 
  #bottom_index_heading1 h1 {
    font-size: 4em;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) { 
  #bottom_index_heading1 h1 {
    font-size: 3em;
  }
}

@media only screen and (max-width: 810px) { 
  #bottom_index_heading1 h1 {
    font-size: 3.5em;
  }
}

@media only screen and (max-width: 428px) { 
  #bottom_index_heading1 h1 {
    font-size: 3em;
  }
}

#index_div3 {
  position: relative;
  background-image: url(./images/PC5.png);
  /*display: inline-flex;*/
  /*opacity: .5;*/
  background-size: 100% 100%;
  align-items: center;
  margin-top: -10%;
  height: 40rem;
  width: 40%;
  padding: 2%;
  /*border: 3px solid rgb(50, 246, 53);*/
  margin-bottom: 8%;
  margin-left: 52%;
  z-index: 10;
  box-shadow: 0 16px 32px 0 rgba(187, 183, 183, 0.972), 0 6px 20px 0 rgba(187, 183, 183, 0.972);
  text-shadow: 
      -1px -1px 0 #000,  
      1px -1px 0 #000,
      -1px 1px 0 #000,
      1px 1px 0 #000; 
}

@media only screen and (max-width: 1440px) {
  #index_div3 {
    position: relative;
    margin-top: -20%;
    height: 40rem;
    width: 40%;
    padding: 2%;
    margin-bottom: 8%;
    margin-left: 52%;
    z-index: 10;
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #index_div3 {
   position: relative;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #index_div3 {
    width: 38%;
    margin-top: -30%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #index_div3 {
    height: 25rem;
    margin-top: -25%;
  }
}

@media only screen and (max-width: 844px) and (orientation: landscape) {
  #index_div3 {
    width: 37%;
    height: 23rem;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  #index_div3 {
    width: 37%;
    height: 23rem;
    margin-top: -30%;
  }
}

@media only screen and (max-width: 810px) {
  #index_div3 {
    height: 25rem;
    width: 40%;
    margin-left: 52%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #index_div3 {
    width: 36%;
    height: 22rem;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #index_div3 {
    width: 37%;
    height: 21rem;
    margin-left: 52%;
  }
}


@media only screen and (max-width: 428px) {
  #index_div3 {
    height: 18rem;
    width: 65%;
    margin-left: 18%;
    margin-bottom: 0%;
    margin-top: 10%;
  }
}

@media only screen and (max-width: 360px) {
  #index_div3 {
    margin-top: 10%;
  }
}


#index_div3:hover {
  background-image: none;
  /*background-color: rgba(101, 9, 239, 0.9);*/
  background-size: 100% 100%;
  background-image: linear-gradient(45deg, rgba(101, 9, 239, 0.9), rgba(101, 9, 239, 0.5));
  animation-name: fade;
  animation-duration: 1.5s;
  text-shadow: none;
}

@keyframes fade {
  from {
    opacity: .3;
  }
  to {
    opacity: 1;
  }
}


#indextextdiv1 {
  position: relative;
  display: flex;
  justify-content: center;
  opacity: 1;
  /*border: 3px solid rgb(50, 246, 53);*/
  height: -webkit-fit-content;
  height:fit-content;
  width: -moz-fit-content;
  width:fit-content;
  font-size: 65px;
  margin-top: 10%;
  color: white;
}

@media only screen and (max-width: 1440px) {
  #indextextdiv1 {
    /*margin-left: 28%;*/
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #indextextdiv1 {
    font-size: 65px;
    /*margin-left: 25%;*/
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #indextextdiv1 {
    /*margin-left: 20%;*/
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #indextextdiv1 {
    /*margin-left: 14%;*/
  }
}

@media only screen and (max-width: 915px) and (orientation: landscape) {
  #indextextdiv1 {
    font-size: 55px;
    /*margin-left: 20%;*/
  }
}

@media only screen and (max-width: 883px) and (orientation: landscape) {
  #indextextdiv1 {
    /*margin-left: 18%;*/
  }
}

@media only screen and (max-width: 854px) and (orientation: landscape) {
  #indextextdiv1 {
    /*margin-left: 15%;*/
  }
}

@media only screen and (max-width: 844px) and (orientation: landscape) {
  #indextextdiv1 {
   /* margin-left: 14%;*/
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  #indextextdiv1 {
    /*margin-left: 14%;*/
  }
}

@media only screen and (max-width: 810px) {
  #indextextdiv1 {
    /*margin-left: 22%;*/
    font-size: 45px;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #indextextdiv1 {
    font-size: 50px;
    /*margin-left: 17%;*/
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #indextextdiv1 {
    /*margin-left: 20%;*/
    font-size: 45px;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  #indextextdiv1 {
   /* margin-left: 14%;*/
  }
}

@media only screen and (max-width: 428px) {
  #indextextdiv1 {
    font-size: 40px;
    /*margin-left: 25%;*/
  }
}

@media only screen and (max-width: 414px) {
  #indextextdiv1 {
    font-size: 40px;
    /*margin-left: 23%;*/
  }
}

@media only screen and (max-width: 390px) {
  #indextextdiv1 {
    /*margin-left: 21%;*/
  }
}

@media only screen and (max-width: 375px) {
  #indextextdiv1 {
    /*margin-left: 20%;*/
  }
}

@media only screen and (max-width: 360px) {
  #indextextdiv1 {
    font-size: 35px;
    /*margin-left: 22%;*/
  }
}

#index_div3:hover #indextextdiv1 {
  color: black;
}

#Tengulogocute_2 {
  position: relative;
  display: flex;
  justify-content: center;
  width: 50%;
  height: 50%;
 
  opacity: 0;
}

#index_div3:hover #Tengulogocute_2 {
  opacity: 1;
}

#indextextdiv1_sub {
  position: relative;
  display: flex;
  justify-content: center;
  color: white;
  margin-top: 5%;
  font-size: 35px;
  
}

@media only screen and (max-width: 1440px){
  #indextextdiv1_sub {
   /* margin-left: 5%;*/
    font-size: 30px;
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #indextextdiv1_sub {
    /*margin-left: 10%;*/
    margin-top: 5%;
    font-size: 24px;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #indextextdiv1_sub {
    margin-top: 10%;
    /*margin-left: 6%;*/
    font-size: 20px;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #indextextdiv1_sub {
    margin-top: 5%;
    /*margin-left: 8%;*/
    font-size: 16px;
  }
}

@media only screen and (max-width: 915px) and (orientation: landscape) {
  #indextextdiv1_sub {
    /*margin-left: 6%;*/
    font-size: 16px;
  }
}
@media only screen and (max-width: 883px) and (orientation: landscape) {
  #indextextdiv1_sub {
    /*margin-left: 5%;*/
  }
}

@media only screen and (max-width: 854px) and (orientation: landscape) {
  #indextextdiv1_sub {
    /*margin-left: 2%;*/
    font-size: 16px;
  }
}

@media only screen and (max-width: 844px) and (orientation: landscape) {
  #indextextdiv1_sub {
    margin-top: 5%;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  #indextextdiv1_sub {
    margin-top: 5%;
   /* margin-left: 2%;*/
    font-size: 15px;
  }
}

@media only screen and (max-width: 810px) {
  #indextextdiv1_sub {
    margin-top: 5%;
    /*margin-left: 4%;*/
    font-size: 16px;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #indextextdiv1_sub {
    /*margin-left: 0%;*/
    font-size: 15px;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #indextextdiv1_sub {
    /*margin-left: 2%;*/
    font-size: 14px;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  #indextextdiv1_sub {
    /*margin-left: 2%;*/
    font-size: 12px;
  }
}

@media only screen and (max-width: 428px) {
  #indextextdiv1_sub {
    /*margin-left: 4%;*/
    font-size: 14px;
  }
}

@media only screen and (max-width: 414px) {
  #indextextdiv1_sub {
    /*margin-left: 3%;*/
  }
}

@media only screen and (max-width: 390px) {
  #indextextdiv1_sub {
    /*margin-left: 6%;*/
    font-size: 12px;
  }
}

@media only screen and (max-width: 375px) {
  #indextextdiv1_sub {
    /*margin-left: 4%;*/
  }
}

@media only screen and (max-width: 360px) {
  #indextextdiv1_sub {
    /*margin-left: 2%;*/
  }
}


#index_div3:hover #indextextdiv1_sub {
  color: black;
  text-decoration: none;
}


#index_div4 {
  position: relative;
  /*display: inline-flex;*/
  z-index: 10;
  background-image: url(./images/pic_14.jpeg);
  background-size: 100% 100%;
  margin-top: -23%;
  align-items: center;
  height: 40rem;
  width: 40%;
  padding: 2%;
  /*border: 3px solid rgb(50, 246, 53);*/
  margin-bottom: 8%;
  margin-left: 10%;
  box-shadow: 0 16px 32px 0 rgba(187, 183, 183, 0.972), 0 6px 20px 0 rgba(187, 183, 183, 0.972);
}

@media only screen and (max-width: 1440px) {
  #index_div4 {
    position: relative;
    /*display: inline-flex;*/
    z-index: 10;
    /*background-color: rgb(159, 159, 159, 0.5);*/
    margin-top: -23%;
    height: 40rem;
    width: 40%;
    padding: 2%;
    /*border: 3px solid rgb(50, 246, 53);*/
    margin-bottom: 8%;
    margin-left: 10%;
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #index_div4 {
    margin-top: -30%;
  }
}


@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #index_div4 {
    width: 38%;
    margin-left: 10%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #index_div4 {
    height: 25rem;
  }
}

@media only screen and (max-width: 844px) and (orientation: landscape) {
  #index_div4 {
    width: 37%;
    height: 23rem;
  }
}

@media only screen and (max-width: 810px) {
  #index_div4 {
    margin-top: -25%;
    height: 25rem;
    width: 40%;
    padding: 2%;
    margin-bottom: 8%;
    margin-left: 8%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #index_div4 {
    width: 36%;
    height: 22rem;
    margin-left: 12%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #index_div4 {
    width: 37%;
    height: 21rem;
    margin-left: 10%;
    margin-top: -27%;
  }
}

@media only screen and (max-width: 428px) {
  #index_div4 {
    height: 18rem;
    width: 65%;
    margin-left: 18%;
    margin-bottom: 0%;
    margin-top: 8%;
  }
}


#index_div4:hover {
  /*background-color: rgba(69, 11, 13, 0.9);*/
  /*background-image: linear-gradient(45deg, rgba(69, 11, 13, 0.9), rgba(69, 11, 13, 0.5));
  background-image: none;
  animation-name: fade;
  animation-duration: 1.5s;
  text-shadow: none;*/
  background-image: none;
  /*background-color: rgba(101, 9, 239, 0.9);*/
  background-size: 100% 100%;
  background-image: linear-gradient(45deg, rgb(69, 11, 13), rgba(117, 19, 22, 0.63));
  animation-name: fade;
  animation-duration: 1.5s;
  text-shadow: none;
}

@keyframes fade {
  from {
    opacity: .5;
  }
  to {
    opacity: 1;
  }
}

#indextextdiv2 {
  position: relative;
  display: flex;
  justify-content: center;
  opacity: 1;
  /*border: 3px solid rgb(50, 246, 53);*/
  height: -webkit-fit-content;
  height:fit-content;
  width: -moz-fit-content;
  width:fit-content;
  font-size: 65px;
  margin-top: 10%;
  color: white;
  text-shadow: 
      -1px -1px 0 #000,  
      1px -1px 0 #000,
      -1px 1px 0 #000,
      1px 1px 0 #000; 
}

@media only screen and (max-width: 1440px) {
  #indextextdiv2 {
    /*margin-left: 27%;*/
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #indextextdiv2 {
    /*margin-left: 25%;*/
    font-size: 65px;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #indextextdiv2 {
    /*margin-left: 18%;*/
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #indextextdiv2 {
    /*margin-left: 11%;*/
  }
}

@media only screen and (max-width: 915px) and (orientation: landscape) {
  #indextextdiv2 {
    font-size: 55px;
    /*margin-left: 16%;*/
  }
}

@media only screen and (max-width: 883px) and (orientation: landscape) {
  #indextextdiv2 {
    /*margin-left: 13%;*/
  }
}

@media only screen and (max-width: 854px) and (orientation: landscape) {
  #indextextdiv2 {
    /*margin-left: 13%;*/
  }
}

@media only screen and (max-width: 844px) and (orientation: landscape) {
  #indextextdiv2 {
    /*margin-left: 13%;*/
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  #indextextdiv2 {
   /*margin-left: 11%;*/
    
  }
}

@media only screen and (max-width: 810px) {
  #indextextdiv2 {
    /*margin-left: 22%;*/
    font-size: 45px;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #indextextdiv2 {
    font-size: 50px;
    /*margin-left: 13%;*/
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #indextextdiv2 {
    /*margin-left: 16%;*/
    font-size: 45px;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  #indextextdiv2 {
    /*margin-left: 10%;*/
  }
}

@media only screen and (max-width: 428px) {
  #indextextdiv2 {
    font-size: 40px;
    /*margin-left: 20%;*/
  }
}

@media only screen and (max-width: 390px) {
  #indextextdiv2 {
    font-size: 40px;
    /*margin-left: 18%;*/
  }
}

@media only screen and (max-width: 375px) {
  #indextextdiv2 {
    font-size: 40px;
    /*margin-left: 15%;*/
  }
}

@media only screen and (max-width: 360px) {
  #indextextdiv2 {
    font-size: 35px;
    /*margin-left: 20%;*/
  }
}

#index_div4:hover #indextextdiv2 {
  color: black;
  text-shadow: none;
}

#Tengulogocute_3 {
  position: relative;
  display: flex;
  justify-content: center;
  width: 50%;
  height: 50%;
  opacity: 0;
}

#index_div4:hover #Tengulogocute_3 {
  opacity: 1;
}

#indextextdiv2_sub {
  position: relative;
  display: flex;
  justify-content: center;
  color: white;
  margin-top: 5%;
  font-size: 35px;
  text-shadow: 
      -1px -1px 0 #000,  
      1px -1px 0 #000,
      -1px 1px 0 #000,
      1px 1px 0 #000; /* Outline color and thickness */
}

@media only screen and (max-width: 1440px) {
  #indextextdiv2_sub {
    margin-top: 5%;
    /*margin-left: 0%;*/
    font-size: 30px;
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #indextextdiv2_sub {
    margin-top: 5%;
    /*margin-left: 6%;*/
    font-size: 24px;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #indextextdiv2_sub {
    /*margin-left: 2%;*/
    font-size: 20px;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #indextextdiv2_sub {
    /*margin-left: 4%;*/
    font-size: 16px;
  }
}

@media only screen and (max-width: 896px) and (orientation: landscape) {
  #indextextdiv2_sub {
    /*margin-left: 4%;*/
  }
}


@media only screen and (max-width: 883px) and (orientation: landscape) {
  #indextextdiv2_sub {
   /* margin-left: 2%;*/
  }
}

@media only screen and (max-width: 854px) and (orientation: landscape) {
  #indextextdiv2_sub {
    /*margin-left: 1%;*/
  }
}

@media only screen and (max-width: 844px) and (orientation: landscape) {
  #indextextdiv2_sub {
   /* margin-left: 2%;*/
    font-size: 15px;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  #indextextdiv2_sub {
    /*margin-left: 2%;*/
    font-size: 14px;
  }
}

@media only screen and (max-width: 810px) {
  #indextextdiv2_sub {
    margin-top: 5%;
    /*margin-left: 2%;*/
    font-size: 16px;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #indextextdiv2_sub {
    /*margin-left: 0%;*/
    font-size: 14px;
  }
}

@media only screen and (max-width: 800px) and (orientation: portrait) {
  #indextextdiv2_sub {
    /*margin-left: 0%;*/
    font-size: 16px;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #indextextdiv2_sub {
    /*margin-left: 3%;*/
    font-size: 13px;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  #indextextdiv2_sub {
    /*margin-left: 2%;*/
    font-size: 11px;
  }
}

@media only screen and (max-width: 428px) {
  #indextextdiv2_sub {
    margin-top: 5%;
    /*margin-left: 2%;*/
    font-size: 14px;
  }
}

@media only screen and (max-width: 414px) {
  #indextextdiv2_sub {
    /*margin-left: 0%;*/
    font-size: 14px;
  }
}

@media only screen and (max-width: 412px) {
  #indextextdiv2_sub {
    /*margin-left: 3%;*/
    font-size: 13px;
  }
}

@media only screen and (max-width: 390px) {
  #indextextdiv2_sub {
    /*margin-left: 4%;*/
    font-size: 12px;
  }
}

@media only screen and (max-width: 375px) {
  #indextextdiv2_sub {
    /*margin-left: 2%;*/
  }
}

@media only screen and (max-width: 360px) {
  #indextextdiv2_sub {
    /*margin-left: 4%;*/
    font-size: 11px;
  }
}


#indextextdiv2_sub {
  color: white;
}

#index_div4:hover #indextextdiv2_sub {
  color: black;
  text-shadow: none;
}



#index_div5 {
  position: relative;
  /*display: inline-flex;*/
  z-index: 10;
  background-image: url(./images/pic_15.jpeg);
  background-size: 100% 100%;
  align-items: center;
  margin-top: -22%;
  height: 40rem;
  width: 40%;
  padding: 2%;
  /*border: 3px solid rgb(50, 246, 53);*/
  margin-bottom: 8%;
  margin-left: 52%;
  box-shadow: 0 16px 32px 0 rgba(187, 183, 183, 0.972), 0 6px 20px 0 rgba(187, 183, 183, 0.972);
  text-shadow: 
      -1px -1px 0 #000,  
      1px -1px 0 #000,
      -1px 1px 0 #000,
      1px 1px 0 #000;
}

@media only screen and (max-width: 1440px) {
  #index_div5 {
    position: relative;
  /*display: inline-flex;*/
  z-index: 10;
  /*background-color: rgb(159, 159, 159, 0.5);*/
  margin-top: -35%;
  height: 40rem;
  width: 40%;
  padding: 2%;
  /*border: 3px solid rgb(50, 246, 53);*/
  margin-bottom: 8%;
  margin-left: 52%;
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #index_div5 {
    margin-top: -30%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #index_div5 {
    width: 38%;
    margin-top: -37%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #index_div5 {
    height: 25rem;
    margin-top: -23%;
  }
}

@media only screen and (max-width: 844px) and (orientation: landscape) {
  #index_div5 {
    width: 37%;
    height: 23rem;
  }
}

@media only screen and (max-width: 810px) {
  #index_div5 {
    margin-top: -25%;
    height: 25rem;
    width: 40%;
    padding: 2%;
    margin-bottom: 8%;
    margin-left: 52%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #index_div5 {
    width: 36%;
    height: 22rem;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #index_div5 {
    width: 37%;
    height: 21rem;
    margin-left: 52%;
  }
}

@media only screen and (max-width: 428px) {
  #index_div5 {
    height: 18rem;
    width: 65%;
    margin-left: 18%;
    margin-bottom: 0%;
    margin-top: 8%;
  }
}

#index_div5:hover {
  /*background-color: rgba(158, 53, 210, 0.9);
  background-image: none;
  animation-name: fade;
  animation-duration: 1.5s; 
  text-shadow: none;*/
  background-image: none;
  /*background-color: rgba(101, 9, 239, 0.9);*/
  background-size: 100% 100%;
  background-image: linear-gradient(45deg, rgba(158, 53, 210, 0.9), rgba(158, 53, 210, 0.5));
  animation-name: fade;
  animation-duration: 1.5s;
  text-shadow: none;   
}

@keyframes fade {
  from {
    opacity: .5;
  }
  to {
    opacity: 1;
  }
}

#indextextdiv3 {
  position: relative;
  display: flex;
  justify-content: center;
  opacity: 1;
  /*border: 3px solid rgb(50, 246, 53);*/
  height: -webkit-fit-content;
  height:fit-content;
  width: -moz-fit-content;
  width:fit-content;
  font-size: 65px;
  margin-top: 10%;
  color: white;
}

@media only screen and (max-width: 1440px) {
  #indextextdiv3 {
    margin-top: 10%;
   /* margin-left: 18%;*/
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #indextextdiv3 {
    font-size: 65px;
    margin-top: 10%;
    /*margin-left: 14%;*/
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #indextextdiv3 {
    margin-top: 10%;
    /*margin-left: 6%;*/
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #indextextdiv3 {
    font-size: 64px;
    /*margin-left: 0%;*/
  }
}

@media only screen and (max-width: 915px) and (orientation: landscape) {
  #indextextdiv3 {
    font-size: 55px;
    /*margin-left: 4%;*/
  }
}

@media only screen and (max-width: 883px) and (orientation: landscape) {
  #indextextdiv3 {
    /*margin-left: 2%;*/
  }
}

@media only screen and (max-width: 854px) and (orientation: landscape) {
  #indextextdiv3 {
    /*margin-left: 3%;*/
  }
}

@media only screen and (max-width: 844px) and (orientation: landscape) {
  #indextextdiv3 {
   /* margin-left: 2%;*/
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  #indextextdiv3 {
    /*margin-left: 1%;*/
  }
}

@media only screen and (max-width: 810px) {
  #indextextdiv3 {
    font-size: 45px;
    /*margin-left: 12%;*/
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #indextextdiv3 {
    /*margin-left: 2%;*/
    font-size: 50px;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #indextextdiv3 {
   /* margin-left: 6%;*/
    font-size: 45px;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  #indextextdiv3 {
    /*margin-left: 0%;*/
  }
}

@media only screen and (max-width: 428px) {
  #indextextdiv3 {
    font-size: 40px;
   /* margin-left: 12%;*/
  }
}

@media only screen and (max-width: 390px) {
  #indextextdiv3 {
    font-size: 40px;
    /*margin-left: 8%;*/
  }
}

@media only screen and (max-width: 375px) {
  #indextextdiv3 {
    font-size: 40px;
    /*margin-left: 6%;*/
  }
}

@media only screen and (max-width: 360px) {
  #indextextdiv3 {
    font-size: 35px;
    /*margin-left: 10%;*/
  }
}



#index_div5:hover #indextextdiv3 {
  color: black;
}

#Tengulogocute_4 {
  position: relative;
  display: flex;
  justify-content: center;
  width: 50%;
  height: 50%;
  opacity: 0;
}

#index_div5:hover #Tengulogocute_4 {
  opacity: 1;
}

#indextextdiv3_sub {
  position: relative;
  display: flex;
  justify-content: center;
  color: white;
  margin-top: 5%;
  font-size: 35px;
}

@media only screen and (max-width: 1440px) {
  #indextextdiv3_sub {
    margin-top: 5%;
    /*margin-left: 16%;*/
    font-size: 30px;
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #indextextdiv3_sub {
    margin-top: 5%;
    /*margin-left: 20%;*/
    font-size: 24px;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #indextextdiv3_sub {
    /*margin-left: 15%;*/
    font-size: 20px;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #indextextdiv3_sub {
    /*margin-left: 18%;*/
    font-size: 16px;
  }
}

@media only screen and (max-width: 883px) and (orientation: landscape) {
  #indextextdiv3_sub {
    /*margin-left: 15%;*/
  }
}

@media only screen and (max-width: 854px) and (orientation: landscape) {
  #indextextdiv3_sub {
    /*margin-left: 14%;*/
  }
}

@media only screen and (max-width: 844px) and (orientation: landscape) {
  #indextextdiv3_sub {
    /*margin-left: 13%;*/
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  #indextextdiv3_sub {
   /* margin-left: 12%;*/
  }
}

@media only screen and (max-width: 810px) {
  #indextextdiv3_sub {
    font-size: 16px;
    margin-top: 5%;
    /*margin-left: 16%;*/
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #indextextdiv3_sub {
    font-size: 15px;
    /*margin-left: 12%;*/
  }
}

@media only screen and (max-width: 760spx) and (orientation: landscape) {
  #indextextdiv3_sub {
    /*margin-left: 6%;*/
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  #indextextdiv3_sub {
    /*margin-left: 6%;*/
  }
}

@media only screen and (max-width: 428px) {
  #indextextdiv3_sub {
    /*margin-left: 15%;*/
    font-size: 14px;
  }
}


@media only screen and (max-width: 390px) {
  #indextextdiv3_sub {
    /*margin-left: 16%;*/
    font-size: 12px;
  }
}

@media only screen and (max-width: 375px) {
  #indextextdiv3_sub {
    /*margin-left: 15%;*/
  }
}


#indextextdiv3_sub {
  color: white;
}

#index_div5:hover #indextextdiv3_sub {
  color: black;
}


#bottom_index_heading2 {
  position: relative;
  /*border: 3px solid rgb(50, 246, 53);*/
  width: 40%;
  height: 10rem;
  left: 12%;
  margin-top: -15%;
  text-align: center;
  z-index: 10;
}

/*portfolio.js*/

.lineconst {
  position: relative;
  color: red;
}

#portfolio_div1 {
  max-width: 1920px;
  margin: 0 auto;
  /*border: 3px solid rgb(6, 186, 33);*/
  height: 100%;
  overflow: hidden;
}

#port_head_div {
  position: relative;
  /*border: 3px solid rgb(4, 131, 243);*/
  margin-top: 20%;
  width: 100%;
  height: 30rem;
}


#port_logo_div {
  position: relative;
  /*border: 3px solid rgb(217, 3, 3);*/
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 45%;
}

@media only screen and (max-width: 1440px) and (orientation: landscape) {
  #port_logo_div {
    position: relative;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: fit-content;
    left: 43%;
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #port_logo_div {
    margin-top: 25%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #port_logo_div {
    left: 39%;
    margin-top: 30%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #port_logo_div {
    left: 39%;
    margin-top: -7%;
  }
}


@media only screen and (max-width: 810px) {
  #port_logo_div {
    left: 39%;
    margin-top: 55%;
    text-align: center;
  }
}
@media only screen and (max-width: 800px) and (orientation: landscape) {
  #port_logo_div {
    margin-top: -10%;
  }
}


@media only screen and (max-width: 760px) and (orientation: landscape){
  #port_logo_div {
    left: 39%;
    margin-top: -8%;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  #port_logo_div {
    margin-top: -2%;
  }
}


@media only screen and (max-width: 480px) {
  #port_logo_div {
    left: 30%;
    margin-top: 60%;
  }
}

@media only screen and (max-width: 414px) {
  #port_logo_div {
    left: 25%;
    margin-top: 60%;
  }
}

@media only screen and (max-width: 390px) {
  #port_logo_div {
    left: 25%;
    margin-top: 60%;
  }
}

@media only screen and (max-width: 375px) {
  #port_logo_div {
    left: 23%;
    margin-top: 60%;
  }
}

@media only screen and (max-width: 360px) {
  #port_logo_div {
    left: 23%;
    margin-top: 60%;
  }
}

#port_logo_heading_p {
  font-size: 50px;
}

#port_logo_para_div {
  position: relative;
  /*border: 3px solid rgb(217, 3, 3);*/
  font-size: 30px;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 28%;
  margin-top: 7%;
}

@media only screen and (max-width: 1440px) {
  #port_logo_para_div {
    position: relative;
  /*border: 3px solid rgb(217, 3, 3);*/
  font-size: 30px;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 20%;
  margin-top: 7%;
  }
}


@media only screen and (max-width: 1080px) {
  #port_logo_para_div {
    left: 10%;
  }
}

@media only screen and (max-width: 926px) {
  #port_logo_para_div {
    width: 80%;
    text-align: center;
    left: 10%;
  }
}

@media only screen and (max-width: 854px) {
  #port_logo_para_div {
    margin-top: 4%;
  }
}

@media only screen and (max-width: 844px) {
  #port_logo_para_div {
    margin-top: 5%;
  }
}

@media only screen and (max-width: 812px) {
  #port_logo_para_div {
    margin-top: 5%;
  }
}

@media only screen and (max-width: 810px) {
  #port_logo_para_div {
    left: 10%;
    width: 80%;
    text-align: center;
  }
}


@media only screen and (max-width: 768px) {
  #port_logo_para_div {
    left: 10%;
    width: 80%;
    text-align: center;
  }
}

@media only screen and (max-width: 480px) {
  #port_logo_para_div {
    left: 10%;
    width: 80%;
    text-align: center;
  }
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent; /* Width and color of the left border */
  border-right: 10px solid transparent; /* Width and color of the right border */
  border-top: 15px solid black; /* Height and color of the top border (creates the arrow) */
  margin-left: 50%;
  margin-top: 5%;
}

#all_twistdivs {
  position: relative;
  /*border: 3px solid rgb(217, 3, 3);*/
  margin-top: -0%;
}

@media only screen and (max-width: 1440px) {
  #all_twistdivs {
    position: relative;
    /*border: 3px solid rgb(217, 3, 3);*/
    margin-top: -15%;
  }
}

@media only screen and (max-width: 1080px) {
  #all_twistdivs {
    margin-top: -12%;
  }
}

@media only screen and (max-width: 844px) {
  #all_twistdivs {
    margin-top: -18%;
  }
}

@media only screen and (max-width: 812px) {
  #all_twistdivs {
    margin-top: -18%;
  }
}

@media only screen and (max-width: 810px) {
  #all_twistdivs {
    margin-top: 15%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #all_twistdivs {
    margin-top: -10%;
  }
}

@media only screen and (max-width: 768px) and (orientation: landscape){
  #all_twistdivs {
    margin-top: -7%;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  #all_twistdivs {
    margin-top: -25%;
  }
}

.twisting-div {
  position: relative;
  opacity: 0;
  width: 40%;
  height: 45rem;
  margin-left: 8%;
  margin-top: 5%;
  /*border: 3px solid rgb(217, 3, 3);*/
  /*animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-duration: 1s;
  transform: rotateY(180deg);
  transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;*/
  transform: scale(0.5, 0.5) rotateY(90deg);
  display: block;
  overflow: hidden;
  visibility: hidden; /* Change here */
  box-shadow: 0 8px 16px 0 rgba(229, 220, 220, 0.72), 0 6px 20px 0 rgba(229, 220, 220, 0.72)
}

@media only screen and (max-width: 1440px) {
  .twisting-div {
    position: relative;
    opacity: 0;
    width: 40%;
    height: 40rem;
    margin-left: 8%;
    margin-top: 5%;
    transform: scale(0.5, 0.5) rotateY(90deg);
    display: block;
    overflow: hidden;
    visibility: hidden; 
  }
}

@media only screen and (max-width: 926px) {
  .twisting-div {
    height: 25rem;
  }
}

@media only screen and (max-width: 854px) and (orientation: landscape) {
  .twisting-div {
    height: 23rem;
  }
}

@media only screen and (max-width: 844px) and (orientation: landscape) {
  .twisting-div {
    height: 23rem;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  .twisting-div {
    height: 23rem;
  }
}


@media only screen and (max-width: 810px) {
  .twisting-div {
    width: 80%;
    height: 40rem;
    margin-left: 10%;
    margin-top: 10%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  .twisting-div {
    height: 20rem;
  }
}

@media only screen and (max-width: 768px) {
  .twisting-div {
    width: 80%;
    height: 40rem;
    margin-left: 10%;
    margin-top: 10%;
  }
}

@media only screen and (max-width: 768px) and (orientation: landscape) {
  .twisting-div {
    width: 80%;
    height: 20rem;

  }
}

@media only screen and (max-width: 480px) {
  .twisting-div {
    width: 80%;
    height: 30rem;
    margin-left: 10%;
  }
}

#twistdiv2 {
  position: relative;
  left: 50%;
}


.animate-pop001 {
  opacity: 1;
  transform: scale(1, 1) rotateY(0deg);
  animation-name: animate-pop001;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-duration: 2s;
}

@keyframes animate-pop001 {
  0% {
    opacity: 0;
    transform: scale(0.5, 0.5) rotateY(90deg);
  }

  100% {
    opacity: 1;
    transform: scale(1, 1) rotateY(0deg);
  }
}




#port_image1 {
  position: relative;
  width: 100%;
  height: 200%;
}

#port_image2 {
  position: relative;
  width: 100%;
  height: 400%;
}

#port_image3 {
  position: relative;
  width: 100%;
  height: 300%;
}

#port_image4 {
  position: relative;
  width: 100%;
  height: 90%;
}

#port_image5 {
  position: relative;
  width: 100%;
  height: 100%;
}

#port_image6 {
  position: relative;
  width: 100%;
  height: 100%;
}

#port_image7 {
  position: relative;
  width: 100%;
  height: 200%;
}


#href1port {
  position: relative;
  z-index: 10;
}

.position-left {
  position: relative;
  /* Styles for the left position */
}


.position-center {
  position: relative;
  /* Styles for the center position */
}

.position-right {
  position: relative;
  left: 45%;
  margin-top: -35%;
  /* Styles for the right position */
}

@media only screen and (max-width: 1440px) {
  .position-right {
    position: relative;
    left: 45%;
    margin-top: -40%;
    /* Styles for the right position */
  }
}

@media only screen and (max-width: 1280px) {
  .position-right {
     margin-top: -48%;
  }
}

@media only screen and (max-width: 1080px) {
  .position-right {
     margin-top: -55%;
  }
}

@media only screen and (max-width: 926px) {
  .position-right {
     margin-top: -40%;
  }
}

@media only screen and (max-width: 896px) {
  .position-right {
     margin-top: -40%;
  }
}

@media only screen and (max-width: 844px) {
  .position-right {
     margin-top: -40%;
  }
}

@media only screen and (max-width: 812px) {
  .position-right {
     margin-top: -42%;
  }
}

@media only screen and (max-width: 810px) {
  .position-right {
    left: 0%;
    margin-top: 10%;
  }
}

@media only screen and (max-width: 768px) {
  .position-right {
    left: 0%;
    margin-top: 10%;
  }
}

@media only screen and (max-width: 480px) {
  .position-right {
    left: 0%;
    margin-top: 10%;
  }
}

.overlay_1 {
  position: absolute;
  bottom: 0;
  left: 100%;
  right: 0;
  overflow: hidden;
  width: 0;
  height: 100%;
  transition: 1s ease;
}

.twisting-div:hover .overlay_1 {
  width: 100%;
  left: 0;
}

#overlay1 {
  background-color: #5e0b0b;
}

#overlay2 {
  background-color: black;
}

#overlay3 {
  background-color: #a835cf;
}

#overlay4 {
  background-color: #d3981a;
}

#overlay5 {
  background-color: #e46719;
}

#overlay6 {
  background-color: #0b6fe2;
}

#overlay7 {
  background-color: #053dac;
}

.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  white-space: nowrap;
}

/*@media only screen and (max-width: 720px) {
  .text {
    margin-top: 5%;
  }
}*/





.port_nav-link {
  position: relative;
  color: white;
}

.port_nav-link:hover {
  color: rgb(47, 241, 13);
} 

/*webdev.js*/

#wd_div1 {
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  /*border: 3px solid rgb(151, 5, 19);*/
  height: 100%;
  overflow: hidden;
  
}

#wd_picdiv1 {
  position: relative;
  width: 100%;
  height: auto;
}

#pic_8 {
  position: relative;
  /*height: 55rem;
  width: 100%;*/
  z-index: 3;
  /*height: 100vh;
  width: 100vw;*/
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -0%);
  object-fit: cover;
  
}

@media only screen and (max-width: 810px) and (orientation: portrait) {
  #pic_8 {
    height: 70vh;
  }
}

@media only screen and (max-width: 428px) {
  #pic_8 {
    height: 70vh;
  }
}

@media only screen and (max-width: 390px) {
  #pic_8 {
    height: 70vh;
  }
}

/*#wd_div2 {
  border: 3px solid rgb(115, 0, 128);
  position: relative;
  width: 100%;
  height: 40rem;
  margin-top: 33%;
}*/




#wd_heading_div {
  position: absolute;
  /*border: 3px solid rgb(30, 198, 52);*/
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 30%;
  margin-top: -35%;
  color: white;
  font-size: 35px;
  z-index: 10;
  text-shadow: 
      -1px -1px 0 #000,  
      1px -1px 0 #000,
      -1px 1px 0 #000,
      1px 1px 0 #000;
}

@media only screen and (max-width: 1440px) {
  #wd_heading_div {
    /*position: relative;*/
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: fit-content;
    left: 15%;
    margin-top: -40%;
    color: white;
    font-size: 35px;
    z-index: 10;
  }
}



@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #wd_heading_div {
    /*position: relative;*/
    margin-top: -42%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #wd_heading_div {
    /*position: relative;*/
    margin-top: -45%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #wd_heading_div {
    /*position: relative;*/
    margin-top: -45%;
  }
}

@media only screen and (max-width: 844px) and (orientation: landscape) {
  #wd_heading_div {
    /*position: relative;*/
    margin-top: -45%;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  #wd_heading_div {
    /*position: relative;*/
    margin-top: -45%;
  }
}

@media only screen and (max-width: 810px) {
  #wd_heading_div {
   /*position: relative;*/
   margin-top: -60%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #wd_heading_div {
    /*position: relative;*/
    margin-top: -45%;
  }
}

@media only screen and (max-width: 800px) and (orientation: portrait) {
  #wd_heading_div {
    /*position: relative;*/
    margin-top: -75%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #wd_heading_div {
    /*position: relative;*/
    margin-top: -40%;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  #wd_heading_div {
   /*position: relative;*/
    margin-top: -40%;
  }
}


@media only screen and (max-width: 428px) {
  #wd_heading_div {
    position: relative;
    margin-top: -97%;
    left: 10%;
  }
}

@media only screen and (max-width: 414px) {
  #wd_heading_div {
    position: relative;
    margin-top: -93%;
  }
}

@media only screen and (max-width: 390px) and (orientation: portrait) {
  #wd_heading_div {
    /*position: relative;*/
    margin-top: -95%;
    left: 10%;
  }
}

@media only screen and (max-width: 375px) and (orientation: portrait) {
  #wd_heading_div {
   /* position: relative;*/
    margin-top: -80%;
    left: 15%;
  }
}

@media only screen and (max-width: 360px) and (orientation: portrait) {
  #wd_heading_div {
    /*position: relative;*/
    margin-top: -95%;
    left: 10%;
  }
}


#wd_subheading_div {
  position: absolute;
  /*border: 3px solid rgb(30, 198, 52);*/
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 20%;
  margin-top: 2%;
  color: white;
  font-size: 25px;
  z-index: 10;
  text-shadow: 
      -1px -1px 0 #000,  
      1px -1px 0 #000,
      -1px 1px 0 #000,
      1px 1px 0 #000;
}

@media only screen and (max-width: 1440px) {
  #wd_subheading_div {
    /*position: relative;*/
    width: -moz-fit-content;
    width: fit-content;
    height: auto;
    left: 15%;
    margin-top: -35%;
    color: white;
    font-size: 25px;
    z-index: 10;
    padding-right: 10%;
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #wd_subheading_div {
    /*position: relative;*/
    width: 70%;
    margin-top: -37%;
    
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #wd_subheading_div {
    /*position: relative;*/
    width: 70%;
    margin-top: -38%;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  #wd_subheading_div {
    /*position: relative;*/
    width: 70%;
  }
}

@media only screen and (max-width: 810px) {
  #wd_subheading_div {
   /*position: relative;*/
   width: 80%;
   margin-top: -45%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #wd_subheading_div {
   /*position: relative;*/
   width: 70%;
   margin-top: -38%;
  }
}

@media only screen and (max-width: 800px) and (orientation: portrait) {
  #wd_subheading_div {
   /*position: relative;*/
   width: 80%;
   margin-top: -55%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #wd_subheading_div {
    /*position: relative;*/
    width: 70%;
    margin-top: -30%;
  }
}


@media only screen and (max-width: 428px) {
  #wd_subheading_div {
    /*position: relative;*/
    margin-top: 15%;
    left: 10%;
    font-size: 1.2rem;
    background: rgba(0, 0, 0, 0.5);
    width: 72%;
    padding-right: 0%;
  }
}

@media only screen and (max-width: 414px) {
  #wd_subheading_div {
    /*position: relative;*/
    margin-top: 5%;
    width: 72%;
    left: 10%;
  }
}

@media only screen and (max-width: 390px) {
  #wd_subheading_div {
    /*position: relative;*/
    font-size: 20px;
    width: 72%;
    left: 10%;
  }
}

@media only screen and (max-width: 384px) {
  #wd_subheading_div {
    /*position: relative;*/
    left: 10%;
  }
}

@media only screen and (max-width: 375px) {
  #wd_subheading_div {
    /*position: relative;*/
    font-size: 20px;
    width: 75%;
    left: 10%;
  }
}

@media only screen and (max-width: 360px) {
  #wd_subheading_div {
    /*position: relative;*/
    font-size: 20px;
    width: 80%;
    left: 10%;
  }
}

#wd_div3 {
  text-align: center;
  /*border: 3px solid rgb(0, 128, 21);*/
  position: relative;
  height: 40rem;
  margin-top: 0%;
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #wd_div3 {
    position: relative;
    margin-top: 55%;
  }
}

@media only screen and (max-width: 810px) {
  #wd_div3 {
   position: relative;
  }
}


@media only screen and (max-width: 760px) and (orientation: landscape) {
  #wd_div3 {
    position: relative;
  }
}

@media only screen and (max-width: 480px) {
  #wd_div3 {
    position: relative;
  }
}


#wd_div4 {
  margin-top: 40%;
  left: 10%;
  height: 70%;
  width: 30%;
  font-size: 24px;
  background-color: #a62ed5;
  opacity: 1;
  position: relative;
  padding: 3%;
  z-index: 12;
  border-radius: 10px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.72), 0 6px 20px 0 rgba(0, 0, 0, 0.72);
}

@media only screen and (max-width: 1440px) {
  #wd_div4 {
    margin-top: 15%;
    left: 10%;
    height: 70%;
    width: 30%;
    font-size: 24px;
    background-color: #a62ed5;
    opacity: 1;
    position: relative;
    padding: 3%;
    z-index: 12;
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #wd_div4 {
    position: relative;
    
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #wd_div4 {
    position: relative;
    padding-top: 8%;
    margin-top: -35%;
    height: 64%;
    width: 44%;
    left: 5%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #wd_div4 {
    position: relative;
    height: 54%;
    width: 44%;
    left: 5%;
    font-size: 22px;
  }
}

@media only screen and (max-width: 883px) and (orientation: landscape) {
  #wd_div4 {
    position: relative;
    
  }
}

@media only screen and (max-width: 844px) and (orientation: landscape) {
  #wd_div4 {
    position: relative;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  #wd_div4 {
    position: relative;
    font-size: 20px;
    padding-top: 3%;
    height: 53%;
    width: 42%;
    left: 5%;
  }
}

@media only screen and (max-width: 810px) {
  #wd_div4 {
    position: relative;
    
    height: 60%;
    width: 42%;
    left: 5%;
    font-size: 22px;
  }
}

@media only screen and (max-width: 800px) and (orientation: portrait) {
  #wd_div4 {
    position: relative;
    margin-top: 25%;
    height: 50%;
    width: 42%;
    left: 5%;
    font-size: 18px;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #wd_div4 {
    position: relative;
    
    height: 47%;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  #wd_div4 {
    position: relative;
    
  }
}

@media only screen and (max-width: 480px) {
  #wd_div4 {
    position: relative;
    font-size: 20px;
    height: 40%;
    width: 80%;
    padding-top: 15%;
    
  }
}

@media only screen and (max-width: 428px) {
  #wd_div4 {
    position: relative;
    margin-top: 180%;
    height: 50%;
    left: 10%;
  }
}

@media only screen and (max-width: 390px) {
  #wd_div4 {
    position: relative;
    margin-top: 170%;
    padding-top: 10%;
  }
}

#wd_div5 {
  position: relative;
  margin-top: -30%;
  left: 30%;
  height: 110%;
  width: 60%;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.72), 0 6px 20px 0 rgba(0, 0, 0, 0.72);
  
}

@media only screen and (max-width: 1440px) {
  #wd_div5 {
    position: relative;
    margin-top: -35%;
    left: 30%;
    height: 90%;
    width: 60%;
  }
}

@media only screen and (max-width: 1280px) {
  #wd_div5 {
    position: relative;
    margin-top: -38%;
    height: 80%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #wd_div5 {
    position: relative;
    margin-top: -45%;
    left: 35%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #wd_div5 {
    position: relative;
    margin-top: -40%;
    left: 30%;
    height: 64%;
    width: 65%;
  }
}

@media only screen and (max-width: 883px) and (orientation: landscape) {
  #wd_div5 {
    position: relative;
    margin-top: -42%;
  }
}

@media only screen and (max-width: 844px) and (orientation: landscape) {
  #wd_div5 {
    position: relative;
    margin-top: -44%;
    height: 60%;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  #wd_div5 {
    position: relative;
    margin-top: -43%;
    left: 30%;
    height: 58%;
    width: 65%;
  }
}


@media only screen and (max-width: 810px) {
  #wd_div5 {
    position: relative;
    margin-top: -55%;
    height: 80%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #wd_div5 {
    position: relative;
    margin-top: -42%;
    height: 55%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #wd_div5 {
    position: relative;
    margin-top: -42%;
    height: 55%;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  #wd_div5 {
    position: relative;
    margin-top: -48%;
  }
}

@media only screen and (max-width: 480px) {
  #wd_div5 {
    position: relative;
    margin-top: -150%;
    left: 5%;
    height: 60%;
    width: 90%;
  }
}


#pic_7 {
  position: relative;
  height:100%;
  width: 100%;
  z-index: 10;
}

#wd_div5_5 {
  position: relative;
  /*border: 3px solid rgb(203, 88, 50);*/
  width: 100%;
  height: 65rem;
  margin-top: 0%;
}

@media only screen and (max-width: 1440px) and (orientation: landscape) {
  #wd_div5_5 {
    position: relative;
    width: 100%;
    height: 45rem;
    margin-top: 0%;
  }
}

.circle3 {
  position: absolute;
  background-color: #ae2cd2;
  width: 40rem;
  height: 40rem;
  left: 50%;
  border-radius: 50%;
  margin-top: -40%;
  opacity: .1;
  transition: transform 0.2s;
  z-index: 0;
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  .circle3 {
    margin-top: 50%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  .circle3 {
    margin-top: 20%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  .circle3 {
    margin-top: 60%;
    left: 40%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  .circle3 {
    margin-top: 80%;
  }
}

@media only screen and (max-width: 428px){
  .circle3 {
    margin-top: 100%;
    left: 30%;
  }
}

@media only screen and (max-width: 375px){
  .circle3 {
    margin-top: 120%;
    left: 20%;
  }
}

.word3 {
  position: absolute;
  width: 85rem;
  height: 10rem;
  right: 25%;
  /*border-radius: 50%;*/
  margin-top: -95%;
  top: 210%;
  opacity: .1;
  z-index: 0;
  transition: transform 0.2s; /* Add a transition for smoother animation */
}

@media only screen and (max-width: 1440px) and (orientation: landscape) {
  .word3 {
    position: absolute;
    width: 85rem;
    height: 10rem;
    right: 25%;
    margin-top: -98%;
    top: 210%;
    opacity: .1;
    z-index: 0;
    transition: transform 0.2s;
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  .word3 {
    position: relative;
    right: 48%;
    margin-top: -115%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  .word3 {
    position: relative;
    right: 48%;
    margin-top: -130%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  .word3 {
    position: relative;
    right: 68%;
    margin-top: -180%;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  .word3 {
   position: relative;
    right: 65%;
    margin-top: -180%;
  }
}

@media only screen and (max-width: 810px) {
  .word3 {
   position: relative;
   right: 48%;
   margin-top: -180%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  .word3 {
    position: relative;
    right: 65%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  .word3 {
    position: relative;
    margin-top: -250%;
    right: 90%;
  }
}

@media only screen and (max-width: 480px) {
  .word3 {
    position: relative;
  }
}

@media only screen and (max-width: 428px) {
  .word3 {
    position: relative;
    margin-top: -245%;
    right: 160%;
  }
}

@media only screen and (max-width: 414px) {
  .word3 {
    position: relative;
    margin-top: -255%;
    right: 160%;
  }
}

@media only screen and (max-width: 412px) {
  .word3 {
    position: relative;
    margin-top: -265%;
    right: 160%;
  }
}

@media only screen and (max-width: 390px) {
  .word3 {
    position: relative;
    margin-top: -280%;
  }
}

@media only screen and (max-width: 384px) {
  .word3 {
    position: relative;
    margin-top: -300%;
  }
}

@media only screen and (max-width: 375px) {
  .word3 {
    position: relative;
    margin-top: -315%;
  }
}

@media only screen and (max-width: 360px) {
  .word3 {
    position: relative;
    margin-top: -330%;
  }
}

/*slideshow_2.js*/


.slider-container2 {
  position: relative;
  /*border: 3px solid rgb(49, 37, 142);*/
  width: 90%; 
  height: 50rem;
  margin-top: 10%;
  overflow: hidden;
  left: 5%;
}

@media only screen and (max-width: 1440px) and (orientation: landscape) {
  .slider-container2 {
    position: relative;
    width: 90%; 
    height: 40rem;
    margin-top: 10%;
    overflow: hidden;
    left: 5%;
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  .slider-container2 {
   position: relative;
   margin-top: 0%;
  }
}


@media only screen and (max-width: 926px) and (orientation: landscape) {
  .slider-container2 {
   position: relative;
   margin-top: -20%;
  }
}

@media only screen and (max-width: 844px) and (orientation: landscape) {
  .slider-container2 {
   position: relative;
   margin-top: -30%;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  .slider-container2 {
   position: relative;
   margin-top: -25%;
  }
}


@media only screen and (max-width: 810px) {
  .slider-container2 {
   position: relative;
   width: 90%; 
   margin-top: -20%;
   left: 5%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  .slider-container2 {
   position: relative;
   width: 90%; 
   margin-top: -36%;
   left: 5%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  .slider-container2 {
    position: relative;
    margin-top: -48%;
  }
}

@media only screen and (max-width: 480px) {
  .slider-container2 {
    position: relative;
  }
}

@media only screen and (max-width: 428px) {
  .slider-container2 {
    position: relative;
    height: 50rem;
    margin-top: -30%;
  }
}


@media only screen and (max-width: 414px) {
  .slider-container2 {
    position: relative;
    margin-top: -40%;
  }
}

@media only screen and (max-width: 412px) {
  .slider-container2 {
    position: relative;
    left: 0%;
    width: 100%;
  }
}

@media only screen and (max-width: 384px) {
  .slider-container2 {
    position: relative;
    margin-top: -60%;
  }
}


.text-container {
  position: relative;
  /*border: 3px solid rgb(30, 198, 52);*/
  height: 73%;
  width: 8%;
  left: 78%;
  margin-top: 8%;
  transform: rotate(-270deg);
  z-index: 13;
}

@media only screen and (max-width: 1440px) {
  .text-container {
    position: relative;
    height: 73%;
    width: 10%;
    left: 75%;
    margin-top: 0%;
    transform: rotate(-270deg);
    z-index: 13;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  .text-container {
   position: relative;
   left: 72%;
   width: 12%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  .text-container {
   position: relative;
   left: 58%;
   width: 13%;
   margin-top: 10%;
  }
}

@media only screen and (max-width: 854px) and (orientation: landscape) {
  .text-container {
   position: relative;
   left: 56%;
   width: 14%;
   margin-top: 10%;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  .text-container {
   position: relative;
   left: 52%;
   width: 15%;
   margin-top: 10%;
  }
}

@media only screen and (max-width: 810px) {
  .text-container {
   position: relative;
   left: 52%;
   width: 15%;
   margin-top: 0%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  .text-container {
   position: relative;
   left: 50%;
   width: 17%;
   margin-top: 20%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  .text-container {
    position: relative;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  .text-container {
    position: relative;
    left: 50%;
    width: 19%;
    margin-top: 25%;
  }
}

@media only screen and (max-width: 480px) {
  .text-container {
    position: relative;
    left: 40%;
    width: 15%;
    margin-top: -30%;
  }
}

@media only screen and (max-width: 428px) {
  .text-container {
    position: relative;
    left: 30%;
    width: 15%;
    margin-top: -40%;
  }
}

@media only screen and (max-width: 414px) {
  .text-container {
    position: relative;
    left: 31%;
  }
}

@media only screen and (max-width: 375px) {
  .text-container {
    position: relative;
  }
}



.text-slide {
  position: relative;
  margin-top: 150%;
  height: 80px;
  width: 450px;;
  right: 120%;
  padding: 5%;
  /*border: 3px solid rgb(227, 104, 16);*/
  transform: rotate(270deg);
  font-size: 35px;
  text-decoration: underline;
}

@media only screen and (max-width: 1440px) {
  .text-slide {
    position: relative;
    margin-top: 100%;
    height: 80px;
    width: 450px;;
    right: 120%;
    padding: 5%;
    /*border: 3px solid rgb(227, 104, 16);*/
    transform: rotate(270deg);
    font-size: 35px;
    text-decoration: underline;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  .text-slide {
   position: relative;
   height: 110px;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  .text-slide {
   position: relative;
  
  }
}

@media only screen and (max-width: 810px) {
  .text-slide {
   position: relative;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  .text-slide {
    position: relative;
  }
}

@media only screen and (max-width: 428px) {
  .text-slide {
    position: relative;
    height: 330px;
    font-size: 34px;
  }
}

@media only screen and (max-width: 375px) {
  .text-slide {
    position: relative;
    font-size: 30px;
  }
}

.text-left1 {
  position: relative;
  margin-top: 25%;
  font-weight: bolder;
}

@media (max-width: 926px) {
  .text-left1 {
    margin-top: 0%;
    
  }
}

@media (max-width: 1920px) {
  .text-left1 span.letter-f {
    padding-left: 11%;
  }
}

@media (max-width: 1440px) {
  .text-left1 span.letter-f {
    padding-left: 5%;
  }
}

@media (max-width: 926px) {
  .text-left1 span.letter-f {
    padding-left: 0%;
  }
}

@media (max-width: 812px) {
  .text-left1 span.letter-f {
    padding-left: 0%;
  }
}

@media (max-width: 926px) {
  .text-left1 {
    margin-top: 10%;
    
  }
}

@media (max-width: 810px) {
  .text-left1 span.letter-f {
    padding-left: 0%;
  }
}

@media (max-width: 428px) {
  .text-left1 span.letter-f {
    padding-left: 5%;
  }
}

@media (max-width: 414px) {
  .text-left1 span.letter-f {
    padding-left: 4%;
  }
}

@media (max-width: 412px) {
  .text-left1 span.letter-f {
    padding-left: 6%;
  }
}

@media (max-width: 390px) {
  .text-left1 span.letter-f {
    padding-left: 4%;
  }
}


@media (max-width: 375px) {
  .text-left1 span.letter-f {
    padding-left: 7%;
  }
}

@media (max-width: 360px) {
  .text-left1 span.letter-f {
    padding-left: 7%;
  }
}

.text-left2 {
  position: relative;
  margin-top: 35%;
  font-weight: bolder;
}
@media (max-width: 1920px) {
  .text-left2 span.letter-w {
   padding-left: 26%;
  }
}

@media (max-width: 1440px) {
  .text-left2 span.letter-w {
   padding-left: 22%;
  }
}

@media (max-width: 926px) {
  .text-left2 span.letter-w {
    color: white;
    padding-left: 19%;
  }

  .text-left2 span.letter-e {
    color: white;
  }

  .text-left2 span.letter-b {
    color: white;
  }

  .text-left2 span.letter-d {
    color: white;
  }

  .text-left2 span.letter-e2 {
    color: white;
  }
}

@media (max-width: 896px) {
  .text-left2 span.letter-w {
    color: white;
    padding-left: 18%;
  }
}

@media (max-width: 854px) {
  .text-left2 span.letter-w {
    color: white;
    padding-left: 19%;
  }

  .text-left2 span.letter-v {
    color: white;
  }
}

@media (max-width: 844px) {
  .text-left2 span.letter-w {
    color: white;
    padding-left: 18%;
  }
}

@media (max-width: 812px) {
  .text-left2 span.letter-w {
    color: white;
    padding-left: 18%;
  }

  .text-left2 span.letter-e {
    color: white;
  }

  .text-left2 span.letter-b {
    color: white;
  }

  .text-left2 span.letter-d {
    color: white;
  }

  .text-left2 span.letter-e2 {
    color: white;
  }

  .text-left2 span.letter-v {
    color: white;
  }

  .text-left2 span.letter-e3 {
    color: white;
  }

  .text-left2 span.letter-l {
    color: white;
  }

  .text-left2 span.letter-o {
    color: black;
  }

  .text-left2 span.letter-p {
    color: black;
  }

  .text-left2 span.letter-m {
    color: black;
  }
}



@media (max-width: 810px) {
  .text-left2 span.letter-w {
    color: white;
    padding-left: 18%;
  }

  .text-left2 span.letter-e {
    color: white;
  }

  .text-left2 span.letter-b {
    color: white;
  }

  .text-left2 span.letter-d {
    color: white;
  }

  .text-left2 span.letter-e2 {
    color: white;
  }

  .text-left2 span.letter-v {
    color: white;
  }

  .text-left2 span.letter-e3 {
    color: white;
  }

  .text-left2 span.letter-l {
    color: black;
  }

  .text-left2 span.letter-o {
    color: black
  }

  .text-left2 span.letter-p {
    color: black;
  }

  .text-left2 span.letter-m {
    color: black;
  }
}

@media (max-width: 800px) and (orientation: landscape) {
  .text-left2 span.letter-w {
    color: white;
    padding-left: 18%;
  }

  .text-left2 span.letter-e {
    color: white;
  }

  .text-left2 span.letter-b {
    color: white;
  }

  .text-left2 span.letter-d {
    color: white;
  }

  .text-left2 span.letter-e2 {
    color: white;
  }

  .text-left2 span.letter-v {
    color: white;
  }

  .text-left2 span.letter-e3 {
    color: white;
  }

  .text-left2 span.letter-l {
    color: white;
  }

  .text-left2 span.letter-o {
    color: white;
  }

  .text-left2 span.letter-p {
    color: white;
  }

  .text-left2 span.letter-m {
    color: white;
  }
}

@media (max-width: 800px) and (orientation: portrait) {
  
  .text-left2 span.letter-w {
    padding-left: 17.5%;
  }

  .text-left2 span.letter-l {
    color: black;
  }

  .text-left2 span.letter-o {
    color: black;
  }

  .text-left2 span.letter-p {
    color:black;
  }

  .text-left2 span.letter-m {
    color:black;
  }
}


@media (max-width: 480px) {
  .text-left2 span.letter-w {
    color: black;
    padding-left: 22%;
  }

  .text-left2 span.letter-e {
    color: black;
  }

  .text-left2 span.letter-b {
    color: black;
  }
  .text-left2 span.letter-d {
    color: black;
  }

  .text-left2 span.letter-e2 {
    color: black;
  }

  .text-left2 span.letter-v {
    color: black;
  }

  .text-left2 span.letter-e3 {
    color: black
  }

  .text-left2 span.letter-l {
    color: black;
  }
}

@media (max-width: 428px) {
  .text-left2 span.letter-w {
    color: black;
    padding-left: 18%;
  }

  .text-left2 span.letter-o {
    color: black;
  }

  .text-left2 span.letter-p {
    color: black;
  }

  .text-left2 span.letter-m {
    color: black;
  }
}

@media (max-width: 415px) {
  .text-left2 span.letter-w {
    color: black;
    padding-left: 15%;
  }
}

@media (max-width: 412px) {
  .text-left2 span.letter-w {
    color: black;
    padding-left: 18%;
  }
}

@media (max-width: 390px) {
  .text-left2 span.letter-w {
    color: black;
    padding-left: 16%;
  }
}

@media (max-width: 375px) {
  .text-left2 span.letter-w {
    color: black;
    padding-left: 19%;
  }
}

@media (max-width: 360px) {
  .text-left2 span.letter-w {
    color: black;
    padding-left: 18%;
  }
}

.text-left3 {
  position: relative;
  margin-top: -40%;
  font-weight: bolder;
}

@media (max-width: 1920px) {
  .text-left3{
    margin-top: -30%;
  }
}

@media (max-width: 1440px) {
  .text-left3{
    margin-top: -40%;
  }
}

@media (max-width: 1024px) {
  .text-left3{
    padding-left: 5%;
  }
}

@media (max-width: 926px) {
  .text-left3{
    padding-left: 0%;
  }
}

@media (max-width: 812px) {
  .text-left3{
    margin-top: -30%;
  }
}

@media (max-width: 480px) {
  .text-left3{
    margin-top: -60%;
  }
}

@media (max-width: 428px) {
  .text-left3{
    margin-top: -76%;
  }
}

@media (max-width: 412px) {
  .text-left3{
    margin-top: -78%;
  }
}

@media (max-width: 390px) {
  /* Change color of 'W' */
  .text-left3{
    margin-top: -80%;
  }
}

@media (max-width: 375px) {
  /* Change color of 'W' */
  .text-left3{
    margin-top: -100%;
  }
}


/*@media (max-width: 1024px) {
  .text-left3{
    padding-left: 0%;
  }

  
}*/

.text-left4 {
  position: relative;
  margin-top: -85%;
  font-weight: bolder;
}

@media (max-width: 1440px) {
  .text-left4 {
    margin-top: -80%;
  }
}

@media (max-width: 1280px) {
  .text-left4 {
    margin-top: -85%;
  }
}

@media (max-width: 1080px) {
  .text-left4 span.letter-u {
    color: black;
    padding-left: 8%;
  }
}

@media (max-width: 926px) {
  .text-left4 span.letter-u {
    color: white;
    padding-left: 5%;
  }

  .text-left4 span.letter-x {
    color: white;
  }

}

@media (max-width: 883px) {
  .text-left4 span.letter-u {
    color: white;
    padding-left: 6.5%;
  }
}

@media (max-width: 854px) {
  .text-left4 span.letter-u {
    color: white;
    padding-left: 3.5%;
  }
  .text-left4 span.letter-d {
    color: white;
  }
}

@media (max-width: 844px) {
  .text-left4 span.letter-u {
    color: white;
    padding-left: 2.8%;
  }
}

@media (max-width: 812px) {
  
  .text-left4 span.letter-u {
    color: white;
    padding-left: 3.8%;
  }

  .text-left4 span.letter-x {
    color: white;
  }

  .text-left4 span.letter-d {
    color: white;
  }

  .text-left4 span.letter-e {
    color: white;
  }

  .text-left4 span.letter-sign {
    color: black;
  }
}


@media (max-width: 810px) {
  
  .text-left4 span.letter-u {
    color: white;
    padding-left: 2%;
  }

  .text-left4 span.letter-x {
    color: white;
  }

  .text-left4 span.letter-d {
    color: white;
  }

  .text-left4 span.letter-e {
    color: white;
  }

  .text-left4 span.letter-sign {
    color: black;
  }
}

@media (max-width: 800px) and (orientation: landscape) {

  .text-left4 span.letter-u {
    color: white;
    padding-left: 0%;
  }

  .text-left4 span.letter-sign {
    color: white;
  }
}

@media (max-width: 800px) and (orientation: portrait) {

  .text-left4 span.letter-sign {
    color: black;
  }
}

@media (max-width: 480px) {
  /* Change color of 'W' */
  .text-left4 span.letter-u {
    color:black;
    padding-left: 14%;
  }

  /* Change color of 'e' */
  .text-left4 span.letter-x {
    color: black;
  }

  /* Change color of 'b' */
  .text-left2 span.letter-b {
    color: black;
  }

  .text-left4 span.letter-d {
    color: black
  }

  .text-left4 span.letter-e {
    color: black
  }
}

@media (max-width: 428px) {
  /* Change color of 'W' */
  .text-left4 span.letter-u {
    color:black;
    padding-left: 16%;
  }

  .text-left4 span.letter-sign {
    color: black;
  }
}

@media (max-width: 414px) {
  /* Change color of 'W' */
  .text-left4 span.letter-u {
    color:black;
    padding-left: 15%;
  }
}

@media (max-width: 412px) {
  /* Change color of 'W' */
  .text-left4 span.letter-u {
    color:black;
    padding-left: 15%;
  }
}

@media (max-width: 390px) {
  /* Change color of 'W' */
  .text-left4 span.letter-u {
    color:black;
    padding-left: 14%;
  }
}

@media (max-width: 375px) {
  /* Change color of 'W' */
  .text-left4 span.letter-u {
    color:black;
    padding-left: 18%;
  }
}

@media (max-width: 360px) {
  /* Change color of 'W' */
  .text-left4 span.letter-u {
    color:black;
    padding-left: 16%;
  }
}

#carousel_Item {
  position: relative;
  /*border: 3px solid rgb(227, 16, 192);*/
  height: 460px;
  width: 100%;
}

#textCarousel {
  position: relative;
  /*border: 3px solid rgb(16, 19, 227);*/
  height: 100%;
  width: 100%;
}

.text-container2 {
  position: relative;
  /*border: 3px solid rgb(30, 52, 198);*/
  height: 35s0px;
  width: 400px;
  left: 72%;
  margin-top: -17%;
  transform: rotate(-180deg);
  z-index: 19;
}

@media only screen and (max-width: 1440px) and (orientation: landscape) {
  .text-container2 {
    position: relative;
    height: 300px;
    width: 350px;
    left: 70%;
    margin-top: -20%;
    transform: rotate(-180deg);
    z-index: 12;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  .text-container2 {
   position: relative;
   left: 65%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  .text-container2 {
   position: relative;
   left: 49%;
   margin-top: -18%;
   background-color: rgba(128, 128, 128, 0.95);
  }
}

@media only screen and (max-width: 854px) and (orientation: landscape) {
  .text-container2 {
   position: relative;
   left: 46%;
   margin-top: -18%;
   background-color: rgba(128, 128, 128, 0.95);
  }
}


@media only screen and (max-width: 812px) and (orientation: landscape) {
  .text-container2 {
   position: relative;
   left: 41.5%;
   height: 265px;
  }
}


@media only screen and (max-width: 810px) {
  .text-container2 {
   position: relative;
   height: 300px;
   left: 48%;
   margin-top: -20%;
   background-color: rgba(128, 128, 128, 0.95);
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  .text-container2 {
   position: relative;
   left: 39%;
   margin-top: -20%;
  }
}

@media only screen and (max-width: 768px) {
  .text-container2 {
    position: relative;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  .text-container2 {
   position: relative;
   left: 37%;
   margin-top: -30%;
  }
}

@media only screen and (max-width: 480px) {
  .text-container2 {
    position: relative;
    left: 21%;
    margin-top: -30%;
  }
}

@media only screen and (max-width: 428px) {
  .text-container2 {
    position: relative;
    left: 1%;
    margin-top: -60%;
  }
}

@media only screen and (max-width: 414px) {
  .text-container2 {
    position: relative;
    left: 0%;
  }
}

@media only screen and (max-width: 412px) {
  .text-container2 {
    position: relative;
    left: 2%;
  }
}

@media only screen and (max-width: 390px) {
  .text-container2 {
    position: relative;
    left: 0%;
    margin-top: -60%;
  }
}

@media only screen and (max-width: 375px) {
  .text-container2 {
    position: relative;
    left: 10%;
    margin-top: -60%;
    height: 250px;
    width: 300px;
  }
}

@media only screen and (max-width: 360px) {
  .text-container2 {
    position: relative;
    left: 8%;
    margin-top: -60%;
    height: 250px;
    width: 300px;
  }
}

.text-slide2 {
  position: relative;
  top: 35%;
  height: 148px;
  width: 400px;
  right: 4.5%;
  padding: 5%;
  /*border: 3px solid rgb(227, 104, 16);8*/
  transform: rotate(-180deg);
  /* Set the height of each text slide to fill its container */
  font-size: 22px;
}

@media only screen and (max-width: 1440px) and (orientation: landscape) {
  .text-slide2 {
    position: relative;
    top: 20%;
    height: 148px;
    width: 350px;
    right: 4.5%;
    padding: 5%;
    transform: rotate(-180deg);
    font-size: 16px;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  .text-slide2 {
   position: relative;
   right: 0.5%;
   top: 30%;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  .text-slide2 {
   position: relative;
   right: 0.5%;
   top: 26%;
  }
}

@media only screen and (max-width: 810px) {
  .text-slide2 {
   position: relative;
   font-size: 16px;
   width: 250px;
   right: -20%;
   padding-top: 0%;
   top: 28%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  .text-slide2 {
   position: relative;
   width: 250px;
   right: -15%;
   font-size: 14px;
   top: 48%;
  }
}

@media only screen and (max-width: 760px) {
  .text-slide2 {
    position: relative;
    padding-top: 0%;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  .text-slide2 {
   position: relative;
   width: 250px;
   right: -15%;
   font-size: 14px;
   top: 38%;
  }
}

@media only screen and (max-width: 480px) {
  .text-slide2 {
    position: relative;
  }
}

@media only screen and (max-width: 375px) {
  .text-slide2 {
    position: relative;
    height: 200px;
    width: 250px;
    right: -10%;
    top: 10%;
  }
}

#carousel_Item2 {
  position: relative;
  /*border: 3px solid rgb(227, 16, 192);*/
  height: 300px;
  width: 100%;
}

#textCarouse2 {
  position: relative;
  /*border: 3px solid rgb(16, 19, 227);*/
  height: 100%;
  width: 100%;
}

#imageCarousel_div {
  position: relative;
  width: 65%;
  height: 87%;
  margin-top: -35%;
  left: 5.6%;
  /*box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.72), 0 6px 20px 0 rgba(0, 0, 0, 0.72);*/
  /*border: 3px solid rgb(218, 120, 22);*/
}

@media only screen and (max-width: 1440px) {
  #imageCarousel_div {
    position: relative;
    width: 65%;
    height: 87%;
    margin-top: -38%;
    left: 5.6%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #imageCarousel_div {
   position: relative;
   left: 2%;
   height: 100%;
   margin-top: -45%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #imageCarousel_div {
   position: relative;
   margin-top: -55%;
  }
}

@media only screen and (max-width: 883px) and (orientation: landscape) {
  #imageCarousel_div {
   position: relative;
   width: 66%;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  #imageCarousel_div {
   position: relative;
  }
}

@media only screen and (max-width: 810px) {
  #imageCarousel_div {
   position: relative;
   height: 110%;
   width: 76.8%;
   margin-top: -66%;
   left: 0%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #imageCarousel_div {
   position: relative;
   height: 100%;
   width: 74.5%;
   margin-top: -65%;
   left: 0%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #imageCarousel_div {
    position: relative;
    width: 77%;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  #imageCarousel_div {
    position: relative;
    width: 81%;
    margin-top: -78%;
  }
}

@media only screen and (max-width: 480px) {
  #imageCarousel_div {
    position: relative;
    left: 8.6%;
    width: 85%;
    margin-top: -55%;
  }
}

@media only screen and (max-width: 428px) {
  #imageCarousel_div {
    position: relative;
    left: 1%;
    width: 100%;
    margin-top: -15%;
  }
}

@media only screen and (max-width: 414px) {
  #imageCarousel_div {
    position: relative;
    left: 0%;
    width: 100%;
    margin-top: -10%;
  }
}

@media only screen and (max-width: 375px) {
  #imageCarousel_div {
    position: relative;
    left: 2%;
    width: 95%;
  }
}


#imageCarousel {
  position: relative;
  /*border: 3px solid rgb(218, 244, 51);*/
  height: 100%;
  width: 100%;
  z-index: 9;
}

#carousel_item2 {
  position: relative;
  /*border: 3px solid rgb(191, 19, 13);*/
  height: 43rem;
  width: 100%;
}
@media only screen and (max-width: 1440px) {
  #carousel_item2 {
    position: relative;
    height: 35rem;
    width: 100%;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  #carousel_item2 {
   position: relative;
   height: 22rem;
  }
}

@media only screen and (max-width: 810px) {
  #carousel_item2 {
   position: relative;
   height: 35rem;
  }
}

#carousel_images {
  position: relative;
  width: 100%; /* Set the width of the image to 100% of its container */
  height: 100%; /* Set the height of the image to 100% of its container */
  object-fit: cover;
}

.carousel-control-prev,
.carousel-control-next {
  display: none;
}




#button_case {
  /*border: 3px solid rgb(53, 142, 37);*/
  display: flex;
  margin-top: -8%;
  z-index: 10;
  margin-bottom: 5%;
}

#button_divs {
  position: relative;
  /*border: 3px solid rgb(53, 142, 37);*/
  z-index: 10;
  width: 100px;
  height: 200px;
  margin-left: 10%;
  margin-top: -13%;
}

@media only screen and (max-width: 1440px) {
  #button_divs {
    position: relative;
    z-index: 10;
    width: 100px;
    height: 200px;
    margin-left: 10%;
    margin-top: -10%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #button_divs {
   position: relative;
   margin-left: 6.8%;
  }
}

@media only screen and (max-width: 1024px) and (orientation: landscape) {
  #button_divs {
   position: relative;
   margin-left: 1.7%;
   margin-top: -5%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #button_divs {
   position: relative;
   margin-left: 5%;
   margin-top: -20%;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  #button_divs {
   position: relative;
   margin-left: 1.7%;
   margin-top: -27%;
  }
}

@media only screen and (max-width: 810px) {
  #button_divs {
   position: relative;
   margin-left: 5%;
   margin-top: -35%;
  }
}

@media only screen and (max-width: 800px) {
  #button_divs {
   position: relative;
   margin-left: 5%;
   margin-top: -35%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #button_divs {
    position: relative;
    margin-left: 2.1%;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  #button_divs {
    position: relative;
    margin-left: 2.1%;
    margin-top: -30%;
  }
}

@media only screen and (max-width: 480px) {
  #button_divs {
    position: relative;
  }
}

@media only screen and (max-width: 428px) {
  #button_divs {
    position: relative;
    margin-top: -90%;
  }
}

@media only screen and (max-width: 414px) {
  #button_divs {
    position: relative;
    /*margin-top: -70%;*/
  }
}

@media only screen and (max-width: 375px) {
  #button_divs {
    position: relative;
    /*margin-top: -70%;*/
    margin-top: -100%;
  }
}

@media only screen and (max-width: 360px) {
  #button_divs {
    position: relative;
    /*margin-top: -75%;*/
    margin-top: -110%;
  }
}


#nxt_button {
  position: relative;
  left: 0%;
  margin-top: -2.3%;
  width: 100px;
  height: 100px;
  z-index: 10;
  outline: none; 
  border: none; 
  border-bottom: 2px solid white;
  overflow: hidden; 
}


#prv_button {
  position: relative;
  left: 0%;
  margin-top: 0%;
  width: 100px;
  height: 100px;
  z-index: 10;
  outline: none; 
  border: none; 
  overflow: hidden;
}


#nxt_button::before,
#prv_button::before {
  content: "";
  position: absolute;
  width: 100%; /* Make it larger to cover the entire button */
  height: 100%; /* Make it larger to cover the entire button */
  bottom: 0;
  right: 0;
  background: linear-gradient(to bottom right, transparent 50%, #545454 50%); /* Adjust gradient colors as needed */
  transition: transform 0.5s ease;
  transform-origin: top left;
  transform: translateX(100%) translateY(100%);
  opacity: .5;
}

#nxt_button:hover::before,
#prv_button:hover::before {
  transform: translateY(0%) translateX(0%) ; /* Adjust to stop in the middle */
}


.slides2 {
  display: flex;
  height: 100%;
  width: 100%;
  transition: transform 0.5s ease-in-out;
 
}

.slide2 {
  flex: 0 0 100%; /* Each slide takes up 50% of the container */
  box-sizing: border-box; /* Include padding and border in the total width */
  padding: 10px; /* Add padding if needed */
}


/* Adjust the styles for the individual slides as needed */
.slide2 img {
  width: 100%;
  height: 100%;
}

.slide2 p {
  position: relative;
 
}

#text_slide2 {
  position: relative;
  border: 3px solid rgb(50, 246, 53);
  margin-top: -30%;
  width: 20%;
  height: 5%;
  left: 10%;
}

button {
  margin: 5px;
  border: 3px solid rgb(50, 246, 53);
  left: 20%;
  margin-top: 25%;
}


/*.word7 {
  position: absolute;
  width: 85rem;
  height: 10rem;
  right: 25%;
  border-radius: 50%;
  margin-top: -30%;
  opacity: .1;
  transition: transform 0.2s; 
}*/

#wd_insert {
  position: relative;
  width: 100%;
  height: 30rem;
  /*border: 3px solid rgb(50, 246, 53);*/
  z-index: 10;
}

#wd_sub_insert {
  position: relative;
  /*border: 3px solid rgb(39, 148, 210);*/
  width: 60%;
  height:80%;
  margin-left: 20%;
  padding: 5%;
  background-color: black;
  color: white;
  font-size: 22px;
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #wd_sub_insert {
    position: relative;
    height:70%;
    margin-top: 10%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #wd_sub_insert {
    position: relative;
    font-size: 20px;
  }  
}

@media only screen and (max-width: 428px) {
  #wd_sub_insert {
    position: relative;
    width: 80%;
    height:80%;
    margin-left: 10%;
    margin-top: 20%;
    font-size: 18px;
  }
}

#wd_div6 {
  position: relative;
  /*border: 3px solid rgb(224, 39, 218);*/
  width: 100%;
  height: 80rem;
  margin-top: 5%;
}

@media only screen and (max-width: 1440px) {
  #wd_div6 {
    position: relative;
    width: 100%;
    height: 80rem;
    margin-top: 5%;
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #wd_div6 {
    position: relative;
    height: 85rem;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #wd_div6 {
    position: relative;
    height: 78rem;
    margin-top: 0%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #wd_div6 {
    position: relative;
    height: 67rem;
    margin-top: -10%;
  }
}


@media only screen and (max-width: 810px) {
  #wd_div6 {
   position: relative;
   height: 65rem;
   margin-top: -15%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #wd_div6 {
    position: relative;
    margin-top: -30%;
  }
}


@media only screen and (max-width: 760px) and (orientation: landscape) {
  #wd_div6 {
    position: relative;
    margin-top: -25%;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  #wd_div6 {
    position: relative;
    margin-top: -35%;
  }
}

@media only screen and (max-width: 480px) {
  #wd_div6 {
    position: relative;
  }
}

@media only screen and (max-width: 428px) {
  #wd_div6 {
    position: relative;
    margin-top: 0%;
    height: 75rem;
  }
}

@media only screen and (max-width: 414px) {
  #wd_div6 {
    position: relative;
    margin-top: -10%;
  }
}

@media only screen and (max-width: 390px) {
  #wd_div6 {
    position: relative;
    margin-top: -10%;

  }
}

@media only screen and (max-width: 375px) {
  #wd_div6 {
    position: relative;
    margin-top: -10%;
  }
}


.word3_5 {
  position: absolute;
  width: 85rem;
  height: 10rem;
  right: 45%;
  border-radius: 50%;
  margin-top: -25%;
  opacity: .1;
  transition: transform 0.2s; /* Add a transition for smoother animation */
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  .word3_5 {
    position: relative;
    right: 120%;
    margin-top: 0%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  .word3_5 {
    position: relative;
    right: 170%;
    margin-top: 0%;
  }
}

@media only screen and (max-width: 810px) {
  .word3_5  {
   position: relative;
   margin-top: 18%;
   right: 160%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  .word3_5  {
    position: relative;
    margin-top: 23%;
    right: 160%;
  }
}

@media only screen and (max-width: 480px) {
  .word3_5  {
    position: relative;
  }
}

@media only screen and (max-width: 428px) {
  .word3_5  {
    position: relative;
    margin-top: -5%;
    right: 230%;
  }
}

@media only screen and (max-width: 390px) {
  .word3_5  {
    position: relative;
    margin-top: -5%;
    right: 380%;
  }
}

@media only screen and (max-width: 360px) {
  .word3_5  {
    position: relative;
    margin-top: -5%;
    right: 400%;
  }
}

.circle4 {
  position: absolute;
  background-color: #541412;
  width: 25rem;
  height: 25rem;
  left: 5%;
  border-radius: 50%;
  margin-top: -45%;
  opacity: .1;
  z-index: 1;
  transition: transform 0.2s; /* Add a transition for smoother animation */
}


#bottom_wd_heading1 {
  position: relative;
  /*border: 3px solid rgb(50, 246, 53);*/
  width: 40%;
  height: 10rem;
  margin-top: 25%;
  left: 0%;
  text-align: center;
  z-index: 12;
}

@media only screen and (max-width: 1440px) {
  #bottom_wd_heading1 {
    position: relative;
    width: 40%;
    height: 10rem;
    margin-top: 25%;
    left: 8%;
    text-align: center;
    z-index: 12;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #bottom_wd_heading1 {
   position: relative;
   margin-top: 10%;
   left: 8%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #bottom_wd_heading1 {
   position: relative;
   margin-top: 0%;
   left: 6%;
  }
}


@media only screen and (max-width: 810px) {
  #bottom_wd_heading1 {
   position: relative;
   margin-top: 0%;
   left: 5%;
  }
}


@media only screen and (max-width: 760px) and (orientation: landscape) {
  #bottom_wd_heading1 {
    position: relative;
  }
}

@media only screen and (max-width: 428px) {
  #bottom_wd_heading1 {
    position: relative;
    margin-top: -5%;
    left: 30%;
  }
}

@media only screen and (max-width: 360px) {
  #bottom_wd_heading1 {
    position: relative;
    margin-top: -5%;
    left: 18%;
    width: 60%;
  }
}


#wd_div7 {
  position: relative;
  /*display: inline-flex;*/
  /*opacity: .5;*/
  background-image: url(./images/IMG_3662.jpeg);
  background-size: 100% 100%;
  margin-top: -20%;
  height: 40rem;
  width: 58%;
  padding: 2%;
  /*border: 3px solid rgb(50, 246, 53);*/
  margin-bottom: 20%;
  margin-left: 53%;
  z-index: 10;
  box-shadow: 0 16px 32px 0 rgba(187, 183, 183, 0.972), 0 6px 20px 0 rgba(187, 183, 183, 0.972);
}


@media only screen and (max-width: 1440px) {
  #wd_div7 {
    position: relative;
    margin-top: -20%;
    height: 40rem;
    width: 44%;
    padding: 2%;
    margin-bottom: 20%;
    margin-left: 52%;
    z-index: 10;
  }
}

@media only screen and (max-width: 1080px) {
  #wd_div7 {
   position: relative;
   height: 25rem;
   width: 35%;
   margin-left: 55%;
   margin-bottom: 20%;
   margin-top: -20%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #wd_div7 {
    position: relative;
    height: 22rem;
    width: 38%;
    margin-left: 55%;
  }
}

@media only screen and (max-width: 810px) {
  #wd_div7 {
    position: relative;
    height: 22rem;
    width: 35%;
    margin-left: 55%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #wd_div7 {
    position: relative;
    width: 45%;
    margin-left: 55%;
  }
}

@media only screen and (max-width: 480px) {
  #wd_div7 {
    position: relative;
    height: 18rem;
    width: 65%;
    margin-left: 18%;
    margin-bottom: 0%;
  }
}




#wd_div7:hover {
  background-image: none;
  background-color: rgba(158, 53, 210, 0.9);
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {
    opacity: .3;
  }
  to {
    opacity: 1;
  }
}

#wd_textdiv1 {
  position: relative;
  opacity: 1;
  /*border: 3px solid rgb(50, 246, 53);*/
  height: -webkit-fit-content;
  height:fit-content;
  width: -moz-fit-content;
  width:fit-content;
  font-size: 65px;
  margin-top: 10%;
  margin-left: 25%;
  color: white;
  text-shadow: 
      -1px -1px 0 #000,  
      1px -1px 0 #000,
      -1px 1px 0 #000,
      1px 1px 0 #000; 
}

@media only screen and (max-width: 1440px) {
  #wd_textdiv1 {
    position: relative;
    opacity: 1;
    height: -webkit-fit-content;
    height:fit-content;
    width: -moz-fit-content;
    width:fit-content;
    font-size: 65px;
    margin-top: 10%;
    margin-left: 16%;
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #wd_textdiv1 {
    position: relative;
    margin-left: 12%;
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #wd_textdiv1 {
    position: relative;
    font-size: 45px;
    margin-left: 8%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #wd_textdiv1 {
    position: relative;
    font-size: 40px;
    margin-left: 4%;
  }
}

@media only screen and (max-width: 810px) {
  #wd_textdiv1 {
    position: relative;
    font-size: 36px;
    margin-left: 6%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #wd_textdiv1 {
    position: relative;
    font-size: 38px;
    margin-left: 2%;
  }
}

@media only screen and (max-width: 800px) and (orientation: portrait) {
  #wd_textdiv1 {
    position: relative;
    font-size: 38px;
    margin-left: 2%;
  }
}

@media only screen and (max-width: 428px) {
  #wd_textdiv1 {
    position: relative;
    font-size: 40px;
    margin-left: 6%;
  }
}

@media only screen and (max-width: 390px) {
  #wd_textdiv1 {
    position: relative;
    font-size: 38px;
    margin-left: 4%;
  }
}

@media only screen and (max-width: 375px) {
  #wd_textdiv1 {
    position: relative;
    font-size: 38px;
    margin-left: 2%;
  }
}

@media only screen and (max-width: 360px) {
  #wd_textdiv1 {
    position: relative;
    font-size: 37px;
    margin-left: 1%;
  }
}

#wd_div7:hover #wd_textdiv1 {
  color: black;
  text-shadow: none;
}

#Tengulogocute_5 {
  position: relative;
  width: 50%;
  height: 50%;
  margin-left: 25%;
  opacity: 0;
}

#wd_div7:hover #Tengulogocute_5 {
  opacity: 1;
}

#wdtextdiv1_sub {
  position: relative;
  margin-top: 5%;
  margin-left: 28%;
  font-size: 35px;
  color: white;
  text-shadow: 
      -1px -1px 0 #000,  
      1px -1px 0 #000,
      -1px 1px 0 #000,
      1px 1px 0 #000; 
}

@media only screen and (max-width: 1440px) {
  #wdtextdiv1_sub {
    position: relative;
    margin-top: 5%;
    font-size: 25px;
    margin-left: 28%;
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #wdtextdiv1_sub {
    position: relative;
    margin-left: 25%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #wdtextdiv1_sub {
    position: relative;
    margin-left: 22%;
    margin-top: 5%;
    font-size: 16px;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #wdtextdiv1_sub {
    position: relative;
    margin-left: 16%;
  }
}

@media only screen and (max-width: 810px) {
  #wdtextdiv1_sub {
    position: relative;
    margin-top: 5%;
    margin-left: 12%;
    font-size: 16px;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #wdtextdiv1_sub {
    position: relative;
    margin-left: 12%;
  }
}

@media only screen and (max-width: 800px) and (orientation: portrait) {
  #wdtextdiv1_sub {
    position: relative;
    margin-top: 5%;
    margin-left: 10%;
  }
}

@media only screen and (max-width: 428px) {
  #wdtextdiv1_sub {
    position: relative;
    margin-top: 5%;
    margin-left: 16%;
  }
}

@media only screen and (max-width: 414px) {
  #wdtextdiv1_sub {
    position: relative;
    margin-top: 5%;
    margin-left: 14%;
  }
}

@media only screen and (max-width: 390px) {
  #wdtextdiv1_sub {
    position: relative;
    margin-top: 5%;
    margin-left: 12%;
  }
}

@media only screen and (max-width: 375px) {
  #wdtextdiv1_sub {
    position: relative;
    margin-top: 5%;
    margin-left: 10%;
  }
}

@media only screen and (max-width: 360px) {
  #wdtextdiv1_sub {
    position: relative;
    margin-top: 5%;
    margin-left: 8%;
  }
}


#wdtextdiv1_sub {
  color: white;
}

#wd_div7:hover #wdtextdiv1_sub {
  color: black;
  text-shadow: none;
}


#wd_div8 {
  position: relative;
  /*display: inline-flex;*/
  z-index: 10;
  background-image: url(./images/IMG_3303.JPG);
  background-size: 100% 100%;
  margin-top: -43%;
  height: 40rem;
  width: 58%;
  padding: 2%;
  /*border: 3px solid rgb(22, 31, 198);*/
  margin-bottom: 8%;
  margin-left: -8%;
  box-shadow: 0 16px 32px 0 rgba(187, 183, 183, 0.972), 0 6px 20px 0 rgba(187, 183, 183, 0.972);
}


@media only screen and (max-width: 1440px) {
  #wd_div8 {
    position: relative;
    z-index: 10;
    margin-top: -43%;
    height: 40rem;
    width: 44%;
    padding: 2%;
    margin-bottom: 8%;
    margin-left: 5%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #wd_div8 {
   position: relative;
   margin-top: -30%;
   height: 25rem;
   width: 35%;
   margin-left: 10%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #wd_div8 {
    position: relative;
    height: 22rem;
    width: 38%;
    margin-top: -42%;
    margin-left: 8%;
  }
}

@media only screen and (max-width: 810px) {
  #wd_div8 {
    position: relative;
    margin-top: -42%;
    height: 22rem;
    width: 35%;
    margin-left: 10%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #wd_div8 {
    position: relative;
    margin-top: -44%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #wd_div8 {
    position: relative;
    width: 45%;
    margin-left: 3%;
  }
}

@media only screen and (max-width: 480px) {
  #wd_div8 {
    position: relative;
    height: 18rem;
    width: 65%;
    margin-left: 18%;
    margin-top: 8%;
    margin-bottom: 0%;
  }
}

#wd_div8:hover {
  background-image: none;
  background-color: rgba(101, 9, 239, 0.9);
  animation-name: fade;
  animation-duration: 1.5s;
  text-shadow: 
      -1px -1px 0 #000,  
      1px -1px 0 #000,
      -1px 1px 0 #000,
      1px 1px 0 #000; /* Outline color and thickness */  
}

@keyframes fade {
  from {
    opacity: .5;
  }
  to {
    opacity: 1;
  }
}

#wd_textdiv2{
  position: relative;
  opacity: 1;
  /*border: 3px solid rgb(50, 246, 53);*/
  height: -webkit-fit-content;
  height:fit-content;
  width: -moz-fit-content;
  width:fit-content;
  font-size: 65px;
  margin-top: 10%;
  margin-left: 28%;
  color: white;
  text-shadow: 
      -1px -1px 0 #000,  
      1px -1px 0 #000,
      -1px 1px 0 #000,
      1px 1px 0 #000
}

@media only screen and (max-width: 1440px) {
  #wd_textdiv2{
    position: relative;
    opacity: 1;
    height: -webkit-fit-content;
    height:fit-content;
    width: -moz-fit-content;
    width:fit-content;
    font-size: 65px;
    margin-top: 10%;
    margin-left: 20%;
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #wd_textdiv2{
    position: relative;
    margin-left: 14%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #wd_textdiv2{
    position: relative;
    margin-left: 8%;
    font-size: 40px;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #wd_textdiv2{
    position: relative;
    margin-left: 8%;
    font-size: 40px;
  }
}

@media only screen and (max-width: 810px) {
  #wd_textdiv2{
    position: relative;
    margin-left: 8%;
    font-size: 36px;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #wd_textdiv2{
    position: relative;
    margin-left: 6%;
    font-size: 38px;
  }
}

@media only screen and (max-width: 800px) and (orientation: portrait) {
  #wd_textdiv2{
    position: relative;
    font-size: 40px;
    margin-left: 5%;
  }
}

@media only screen and (max-width: 428px) {
  #wd_textdiv2{
    position: relative;
    font-size: 40px;
    margin-left: 10%;
  }
}

@media only screen and (max-width: 414px) {
  #wd_textdiv2{
    position: relative;
    font-size: 40px;
    margin-left: 8%;
  }
}

@media only screen and (max-width: 390px) {
  #wd_textdiv2{
    position: relative;
    font-size: 38px;
    margin-left: 6%;
  }
}

@media only screen and (max-width: 375px) {
  #wd_textdiv2{
    position: relative;
    font-size: 38px;
    margin-left: 5%;
  }
}

@media only screen and (max-width: 360px) {
  #wd_textdiv2{
    position: relative;
    font-size: 37px;
    margin-left: 5%;
  }
}

#wd_div8:hover #wd_textdiv2 {
  color: black;
  text-shadow: none;
}

#Tengulogocute_6 {
  position: relative;
  width: 50%;
  height: 50%;
  margin-left: 25%;
  opacity: 0;
}

#wd_div8:hover #Tengulogocute_6 {
  opacity: 1;
}

#wdtextdiv2_sub {
  position: relative;
  margin-top: 5%;
  margin-left: 22%;
  font-size: 35px;
  color: white;
  text-shadow: 
      -1px -1px 0 #000,  
      1px -1px 0 #000,
      -1px 1px 0 #000,
      1px 1px 0 #000
}


@media only screen and (max-width: 1440px) {
  #wdtextdiv2_sub {
    position: relative;
    margin-top: 5%;
    font-size: 25px;
    margin-left: 22%;
    }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #wdtextdiv2_sub {
    position: relative;
    margin-left: 18%;
    }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #wdtextdiv2_sub {
    position: relative;
    margin-top: 5%;
    font-size: 16px;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #wdtextdiv2_sub {
    position: relative;
    margin-top: 5%;
    margin-left: 6%;
  }
}

@media only screen and (max-width: 810px) {
  #wdtextdiv2_sub {
    position: relative;
    margin-top: 5%;
    font-size: 16px;
    margin-left: 2%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #wdtextdiv2_sub {
    position: relative;
    margin-top: 5%;
    margin-left: 2%;
  }
}

@media only screen and (max-width: 800px) and (orientation: portrait) {
  #wdtextdiv2_sub {
    position: relative;
    margin-left: 2%;
  }
}


@media only screen and (max-width: 428px) {
  #wdtextdiv2_sub {
    position: relative;
    margin-top: 5%;
    margin-left: 8%;
  }
}

@media only screen and (max-width: 414px) {
  #wdtextdiv2_sub {
    position: relative;
    margin-top: 5%;
    margin-left: 8%;
  }
}

@media only screen and (max-width: 390px) {
  #wdtextdiv2_sub {
    position: relative;
    margin-top: 5%;
    margin-left: 4%;
  }
}

@media only screen and (max-width: 375px) {
  #wdtextdiv2_sub {
    position: relative;
    margin-top: 5%;
    margin-left: 2%;
  }
}

@media only screen and (max-width: 360px) {
  #wdtextdiv2_sub {
    position: relative;
    margin-top: 5%;
    margin-left: 0%;
  }
}

#wdtextdiv2_sub {
  color: white;
}

#wd_div8:hover #wdtextdiv2_sub {
  color: black;
  text-shadow: none;
}

#wd_div9 {
  position: relative;
  /*display: inline-flex;*/
  z-index: 10;
  background-image: url(./images/PC5.png);
  background-size: 100% 100%;
  margin-top: -27%;
  height: 40rem;
  width: 58%;;
  padding: 2%;
  /*border: 3px solid rgb(205, 86, 138);*/
  margin-bottom: 8%;
  margin-left: 53%;
  box-shadow: 0 16px 32px 0 rgba(187, 183, 183, 0.972), 0 6px 20px 0 rgba(187, 183, 183, 0.972);
}

@media only screen and (max-width: 1440px) {
    #wd_div9 {
      position: relative;
      z-index: 10;
      background-color: rgb(159, 159, 159, 0.5);
      margin-top: -28%;
      height: 40rem;
      width: 44%;
      padding: 2%;
      margin-bottom: 8%;
      margin-left: 52%;
    }
  }

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #wd_div9 {
   position: relative;
   height: 25rem;
   width: 35%;
   margin-left: 55%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #wd_div9 {
    position: relative;
    height: 22rem;
    width: 38%;
    margin-left: 55%;
  }
}

@media only screen and (max-width: 810px) {
  #wd_div9 {
    position: relative;
    height: 22rem;
    width: 35%;
    margin-left: 55%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #wd_div9 {
    position: relative;
    width: 45%;
  }
}

@media only screen and (max-width: 428px) {
  #wd_div9 {
    position: relative;
    height: 18rem;
    width: 65%;
    margin-left: 18%;
    margin-top: 8%;
  }
}

#wd_div9:hover {
  background-image: none;
  background-size: 100% 100%;
  background-color: rgba(69, 11, 13, 0.9);
  animation-name: fade;
  animation-duration: 1.5s;
  text-shadow: 
      -1px -1px 0 #000,  
      1px -1px 0 #000,
      -1px 1px 0 #000,
      1px 1px 0 #000; /* Outline color and thickness */    
}

@keyframes fade {
  from {
    opacity: .5;
  }
  to {
    opacity: 1;
  }
}

#wd_textdiv3{
  position: relative;
  opacity: 1;
  /*border: 3px solid rgb(50, 246, 53);*/
  height: -webkit-fit-content;
  height:fit-content;
  width: -moz-fit-content;
  width:fit-content;
  font-size: 65px;
  margin-top: 10%;
  margin-left: 32%;
  color: white;
  text-shadow: 
      -1px -1px 0 #000,  
      1px -1px 0 #000,
      -1px 1px 0 #000,
      1px 1px 0 #000
}

@media only screen and (max-width: 1440px) {
  #wd_textdiv3{
    position: relative;
    opacity: 1;
    height: -webkit-fit-content;
    height:fit-content;
    width: -moz-fit-content;
    width:fit-content;
    font-size: 65px;
    margin-top: 10%;
    margin-left: 28%;
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #wd_textdiv3{
    position: relative;
    margin-left: 22%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #wd_textdiv3{
    position: relative;
    font-size: 45px;
    margin-left: 22%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #wd_textdiv3{
    position: relative;
    font-size: 40px;
    margin-left: 20%;
  }
}

@media only screen and (max-width: 810px) {
  #wd_textdiv3{
    position: relative;
    font-size: 36px;
    margin-left: 20%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #wd_textdiv3{
    position: relative;
    font-size: 38px;
    margin-left: 18%;
  }
}


@media only screen and (max-width: 800px) and (orientation: portrait) {
  #wd_textdiv3{
    position: relative;
    font-size: 40px;
    margin-left: 18%;
  }
}

@media only screen and (max-width: 428px) {
  #wd_textdiv3{
    position: relative;
    font-size: 40px;
    margin-left: 20%;
  }
}

@media only screen and (max-width: 390px) {
  #wd_textdiv3{
    position: relative;
    font-size: 38px;
    margin-left: 20%;
  }
}

@media only screen and (max-width: 375px) {
  #wd_textdiv3{
    position: relative;
    font-size: 38px;
    margin-left: 18%;
  }
}

@media only screen and (max-width: 360px) {
  #wd_textdiv3{
    position: relative;
    font-size: 37px;
    margin-left: 18%;
  }
}

#wd_div9:hover #wd_textdiv3 {
  color: black;
  text-shadow: none;
}

#Tengulogocute_7 {
  position: relative;
  width: 50%;
  height: 50%;
  margin-left: 25%;
  opacity: 0;
}

#wd_div9:hover #Tengulogocute_7 {
  opacity: 1;
}

#wdtextdiv3_sub {
  position: relative;
  color: black;
  margin-top: 5%;
  margin-left: 12%;
  color: white;
  font-size: 35px;
  text-shadow: 
      -1px -1px 0 #000,  
      1px -1px 0 #000,
      -1px 1px 0 #000,
      1px 1px 0 #000
}

@media only screen and (max-width: 1440px) {
  #wdtextdiv3_sub {
    position: relative;
    margin-top: 5%;
    font-size: 25px;
    margin-left: 12%;
  }
  
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #wdtextdiv3_sub {
    position: relative;
    margin-left: 6%;
  }
  
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #wdtextdiv3_sub {
    position: relative;
    font-size: 16px;
    margin-left: 4%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #wdtextdiv3_sub {
    position: relative;
    font-size: 13px;
    margin-left: 2%;
  }
}

@media only screen and (max-width: 810px) {
  #wdtextdiv3_sub {
    position: relative;
    font-size: 12px;
    margin-left: 2%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #wdtextdiv3_sub {
    position: relative;
    font-size: 12px;
    margin-left: 0%;
  }
}

@media only screen and (max-width: 800px) and (orientation: portrait) {
  #wdtextdiv3_sub {
    position: relative;
    margin-left: 3%;
    
  }
  
}

@media only screen and (max-width: 428px) {
  #wdtextdiv3_sub {
    position: relative;
    margin-top: 5%;
    margin-left: 2%;
    font-size: 14px;
  }
}

@media only screen and (max-width: 414px) {
  #wdtextdiv3_sub {
    position: relative;
    margin-top: 5%;
    margin-left: 2%;
    font-size: 13px;
  }
}

@media only screen and (max-width: 390px) {
  #wdtextdiv3_sub {
    position: relative;
    margin-top: 5%;
    margin-left: 2%;
    font-size: 12px;
  }
}

@media only screen and (max-width: 375px) {
  #wdtextdiv3_sub {
    position: relative;
    margin-top: 5%;
    margin-left: 2%;
  }
}

@media only screen and (max-width: 360px) {
  #wdtextdiv3_sub {
    position: relative;
    margin-top: 5%;
    margin-left: 2%;
    font-size: 11px;
  }
}

#wdtextdiv3_sub {
  color: white;
}

#wd_div9:hover #wdtextdiv3_sub {
  color: black;
  text-shadow: none;
}

#bottom_wd_heading2 {
  position: relative;
  /*border: 3px solid rgb(50, 246, 53);*/
  width: 40%;
  height: 10rem;
  left: 12%;
  margin-top: -15%;
  text-align: center;
  z-index: 10;
}



/*App_develpment.js*/

#ad_div1 {
  position: relative;
  /*border: 3px solid rgb(181, 8, 40);*/
  max-width: 1920px;
  margin: 0 auto;
  /*border: 3px solid rgb(151, 5, 19);*/
  height: 100%;
 /* margin-top: -0%;*/
  /*width: 100%;*/
  /*height: 255rem;*/
  overflow: hidden;
}

#pic_14 {
  position: relative;
  height: 54rem;
  width: 100%;
  margin-top: 0%;
  z-index: 1;
}

@media only screen and (max-width: 1440px) {
  #pic_14 {
    position: relative;
    height: 50rem;
    width: 100%;
    margin-top: 0%;
    z-index: 1;
  }
}

#ad_div2 {
  position: relative;
  /*border: 3px solid rgb(45, 181, 8);*/
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 32%;
  margin-top: -30%;
  color: white;
  z-index: 10;
}

@media only screen and (max-width: 1440px) {
  #ad_div2 {
    position: relative;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: fit-content;
    left: 23%;
    margin-top: -30%;
    color: white;
    z-index: 10;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #ad_div2 {
    position: relative;
    left: 18%;
    margin-top: -40%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #ad_div2 {
    position: relative;
    left: 15%;
    margin-top: -60%;
  }
}

@media only screen and (max-width: 915px) and (orientation: landscape) {
  #ad_div2 {
    position: relative;
    left: 14%;
    margin-top: -60%;
  }
}

@media only screen and (max-width: 883px) and (orientation: landscape) {
  #ad_div2 {
    position: relative;
    left: 13%;
    margin-top: -65%;
  }
}

@media only screen and (max-width: 854px) and (orientation: landscape) {
  #ad_div2 {
    position: relative;
    left: 12%;
    margin-top: -70%;
  }
}

@media only screen and (max-width: 844px) and (orientation: landscape) {
  #ad_div2 {
    position: relative;
    left: 12%;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  #ad_div2 {
    position: relative;
    left: 11%;
    margin-top: -75%;
  }
}

@media only screen and (max-width: 810px) {
  #ad_div2 {
    position: relative;
    margin-top: -50%;
    left: 10%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #ad_div2 {
    position: relative;
    left: 11%;
    margin-top: -75%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #ad_div2 {
    position: relative;
    left: 9.5%;
    margin-top: -80%;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  #ad_div2 {
    position: relative;
    left: 5%;
    margin-top: -90%;
  }
}

@media only screen and (max-width: 428px) {
  #ad_div2 {
    position: relative;
    width: 80%;
    left: 12%;
    margin-top: -100%;
    text-align: center;
  }
}

@media only screen and (max-width: 390px) {
  #ad_div2 {
    position: relative;
    left: 11%;
    margin-top: -110%;
  }
}

.outlined-text {
  font-size: 24px;
  font-weight: bold;
  color: #fff; /* Text color */
  text-shadow: 
      -1px -1px 0 #000,  
      1px -1px 0 #000,
      -1px 1px 0 #000,
      1px 1px 0 #000; /* Outline color and thickness */
}

#ad_div3 {
  position: relative;
  margin-top: 30%;
  /*border: 3px solid rgb(39, 126, 224);*/
  width: 100%;
  height: 120rem;
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #ad_div3 {
    position: relative;
    margin-top: 35%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #ad_div3 {
    position: relative;
    margin-top: 55%;
  }
}

@media only screen and (max-width: 854px) and (orientation: landscape) {
  #ad_div3 {
    position: relative;
    margin-top: 60%;
  }
}

@media only screen and (max-width: 844px) and (orientation: landscape) {
  #ad_div3 {
    position: relative;
    margin-top: 65%;
  }
}

@media only screen and (max-width: 810px) {
  #ad_div3 {
    position: relative;
    margin-top: 50%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #ad_div3 {
    position: relative;
    margin-top: 65%;
  }
}

@media only screen and (max-width: 768px) {
  #ad_div3 {
    position: relative;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  #ad_div3 {
    position: relative;
    margin-top: 75%;
  }
}

@media only screen and (max-width: 428px) {
  #ad_div3 {
    position: relative;
    margin-top: 75%;
  }
}

@media only screen and (max-width: 390px) {
  #ad_div3 {
    position: relative;
    margin-top: 85%;
  }
}

#app_pic_div {
  position: relative;
  /*height: 300px;
  width: 500px;*/
  top: 5%;
  font-size: 1em;
  height: 38%;
  width: 40%;
  left: 10%;
  /*box-shadow: 2em 2em #5e0b0b;
  /*-webkit-box-shadow:0 50px 50px #5e0b0b;*/
  /*-webkit-filter: drop-shadow(1vw 50px 50px #5e0b0b); /* Chrome, Safari, Opera */
  /*filter: drop-shadow(1vw 50px 50px #5e0b0b);*/
  background-color: transparent;
  perspective: 1000px; /* 3D effect */
  z-index: 10;
}

@media only screen and (max-width: 1440px) {
  #app_pic_div {
    position: relative;
    top: 5%;
    font-size: 1em;
    height: 30%;
    width: 40%;
    left: 10%;
    background-color: transparent;
    perspective: 1000px; /* 3D effect */
    z-index: 10;
  }
}@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #app_pic_div {
    position: relative;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #app_pic_div {
    position: relative;
    height: 20%;
  }
}


@media only screen and (max-width: 854px) and (orientation: landscape) {
  #app_pic_div {
    position: relative;
    height: 19%;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  #app_pic_div {
    position: relative;
    height: 18%;
  }
}

@media only screen and (max-width: 810px) {
  #app_pic_div {
    position: relative;
    height: 30%;
    width: 43%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #app_pic_div {
    position: relative;
    height: 17.5%;
  }
}

@media only screen and (max-width: 768px) {
  #app_pic_div {
    position: relative;
  }
}

@media only screen and (max-width: 428px) {
  #app_pic_div {
    position: relative;
    width: 80%;
    height: 25%;
  }
}

@media only screen and (max-width: 390px) {
  #app_pic_div {
    position: relative;
    width: 80%;
    height: 25%;
  }
}

@media only screen and (max-width: 360px) {
  #app_pic_div {
    position: relative;
    width: 80%;
    height: 22%;
  }
}


#pic_13 {
  position: relative;
  width: 100%;
  height: 100%;
}

#app_pic_div-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
#app_pic_div:hover #app_pic_div-inner {
  transform: rotateY(180deg);
}

#app_pic_div-front, #app_pic_div-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

#app_pic_div-front {
  background-color: #bbb;
  color: black;
}

#app_pic_div-back {
  background-color: #5e0b0b;
  color: white;
  padding: 10%;
  font-size: 24px;
  transform: rotateY(180deg);
  padding-top: 20%;
}

@media only screen and (max-width: 1440px) {
  #app_pic_div-back {
    background-color: #5e0b0b;
    color: white;
    padding: 10%;
    font-size: 120%;
    transform: rotateY(180deg);
    padding-top: 20%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #app_pic_div-back {
    position: relative;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #app_pic_div-back {
    position: relative;
    padding-top: 10%;
    font-size: 100%;
  }
}

@media only screen and (max-width: 854px) and (orientation: landscape) {
  #app_pic_div-back {
    position: relative;
    padding-top: 8%;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  #app_pic_div-back {
    position: relative;
    font-size: 90%;
  }
}


@media only screen and (max-width: 810px) {
  #app_pic_div-back {
    position: relative;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #app_pic_div-back {
    position: relative;
    font-size: 85%;
  }
}

@media only screen and (max-width: 760px) {
  #app_pic_div-back {
    position: relative;
  }
}

@media only screen and (max-width: 428px) {
  #app_pic_div-back {
    position: relative;
  }
}

@media only screen and (max-width: 360px) {
  #app_pic_div-back {
    position: relative;
    font-size: 100%;
  }
}

#app_pic_div-back h1{
  position: relative;
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  #app_pic_div-back h1{
    position: relative;
    font-size: 180%;
  }
}


#ad_div4 {
  position: relative;
  padding: 5%;
  margin-top: -25%;
  width: 40%;
  height: 20%;
  left: 55%;
  text-align: center;
  z-index: 10;
  font-size: 24px;
   /*border: 3px solid rgb(39, 224, 165);*/
}

@media only screen and (max-width: 1440px) {
  #ad_div4 {
    position: relative;
    padding: 5%;
    margin-top: -25%;
    width: 40%;
    height: 20%;
    left: 55%;
    text-align: center;
    z-index: 10;
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #ad_div4 {
    position: relative;
    margin-top: -30%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #ad_div4 {
    position: relative;
    margin-top: -35%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #ad_div4 {
    position: relative;
    margin-top: -28%;
    font-size: 18px;
  }
}

@media only screen and (max-width: 854px) and (orientation: landscape) {
  #ad_div4 {
    position: relative;
    margin-top: -30%;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  #ad_div4 {
    position: relative;
    font-size: 17px;
    margin-top: -32%;
  }
}

@media only screen and (max-width: 810px) {
  #ad_div4 {
    position: relative;
    margin-top: -55%;
    font-size: 22px;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #ad_div4 {
    position: relative;
    margin-top: -30%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #ad_div4 {
    position: relative;
    font-size: 16px;
    margin-top: -32%;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  #ad_div4 {
    position: relative;
    margin-top: -38%;
    font-size: 90%;
  }
}

@media only screen and (max-width: 428px) {
  #ad_div4 {
    position: relative;
    margin-top: 35%;
    width: 80%;
    height: 20%;
    left: 10%;
  }
}

@media only screen and (max-width: 390px) {
  #ad_div4 {
    position: relative;
    margin-top: 30%;
    width: 80%;
    height: 20%;
    left: 10%;
  }
}

.circle5 {
  position: absolute;
  background-color: #5112a3;
  width: 40rem;
  height: 40rem;
  left: 50%;
  border-radius: 50%;
  margin-top: -50%;
  opacity: .1;
  transition: transform 0.2s; /* Add a transition for smoother animation */
  z-index: 0;
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  .circle5 {
    position: absolute;
    margin-top: -90%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  .circle5 {
    position: absolute;
    margin-top: -110%;
  }
}

@media only screen and (max-width: 810px) {
  .circle5 {
    position: absolute;
    margin-top: -110%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  .circle5 {
    position: absolute;
    margin-top: -120%;
  }
}

@media only screen and (max-width: 428px) {
  .circle5 {
    position: absolute;
    left: 50%;
    margin-top: -250%;
  }
}

@media only screen and (max-width: 390px) {
  .circle5 {
    position: absolute;
    left: 50%;
    margin-top: -290%;
  }
}

@media only screen and (max-width: 360px) {
  .circle5 {
    position: absolute;
    left: 50%;
    margin-top: -280%;
  }
}

.word5 {
  position: absolute;
  width: 85rem;
  height: 10rem;
  right: 35%;
  /*border-radius: 50%;*/
  margin-top: -15%;
  opacity: .1;
  transition: transform 0.2s; 
}

@media only screen and (max-width: 1440px) {
  .word5 {
    position: absolute;
    width: 85rem;
    height: 10rem;
    right: 25%;
    margin-top: -30%;
    opacity: .1;
    transition: transform 0.2s; 
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  .word5 {
    position: absolute;
    margin-top: -35%;
    
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  .word5 {
    position: absolute;
    margin-top: -40%;
    right: -5%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  .word5 {
    position: absolute;
    margin-top: -65%;
  }
}

@media only screen and (max-width: 854px) and (orientation: landscape) {
  .word5 {
    position: absolute;
    margin-top: -68%;
    right: -20%;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  .word5 {
    position: absolute;
    margin-top: -72%;
    right: -20%;
  }
}

@media only screen and (max-width: 810px) {
  .word5 {
    position: absolute;
    margin-top: -50%;
    right: -15%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  .word5 {
    position: absolute;
    margin-top: -78%;
    right: -30%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  .word5 {
    position: absolute;
    margin-top: -80%;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  .word5 {
    position: absolute;
    margin-top: -90%;
    right: -55%;
  }
}

@media only screen and (max-width: 428px) {
  .word5 {
    position: absolute;
    margin-top: -150%;
    right: -110%;
  }
}

@media only screen and (max-width: 414px) {
  .word5 {
    position: absolute;
    margin-top: -140%;
    right: -110%;
  }
}

@media only screen and (max-width: 390px) {
  .word5 {
    position: absolute;
    margin-top: -145%;
    right: -130%;
  }
}

@media only screen and (max-width: 375px) {
  .word5 {
    position: absolute;
    margin-top: -150%;
  }
}

@media only screen and (max-width: 360px) {
  .word5 {
    position: absolute;
    margin-top: -156%;
    right: -160%;
  }
}

#app_pic_div2 {
  /*height: 300px;
  width: 500px;*/
  top: -33%;
  font-size: 1em;
  height: 38%;
  width: 40%;
  position: relative;
  left: 55%;
  /*box-shadow: 2em 2em #5e0b0b;
  /*-webkit-box-shadow:0 50px 50px #5e0b0b;*/
  /*-webkit-filter: drop-shadow(1vw 50px 50px #5e0b0b); /* Chrome, Safari, Opera */
  /*filter: drop-shadow(1vw 50px 50px #5e0b0b);*/
  background-color: transparent;
  perspective: 1000px; /* 3D effect */
  z-index: 10;
}

@media only screen and (max-width: 1440px) {
  #app_pic_div2 {
    top: -45%;
    font-size: 1em;
    height: 30%;
    width: 40%;
    position: relative;
    left: 55%;
    background-color: transparent;
    perspective: 1000px; /* 3D effect */
    z-index: 10;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #app_pic_div2 {
    position: relative;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #app_pic_div2 {
    position: relative;
    top: -52%;
    height: 20%;
  }
}

@media only screen and (max-width: 854px) and (orientation: landscape) {
  #app_pic_div2 {
    position: relative;
    height: 19%;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  #app_pic_div2 {
    position: relative;
    height: 18%;
  }
}

@media only screen and (max-width: 810px) {
  #app_pic_div2 {
    position: relative;
    width: 43%;
    left: 47%;
    top: -44%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #app_pic_div2 {
    position: relative;
    top: -56%;
    height: 17.5%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #app_pic_div2 {
    position: relative;
    top: -53%;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  #app_pic_div2 {
    position: relative;
    top: -54%;
  }
}

@media only screen and (max-width: 428px) {
  #app_pic_div2 {
    position: relative;
    width: 80%;
    height: 25%;
    top: -60%;
    left: 10%;
  }
}

@media only screen and (max-width: 414px) {
  #app_pic_div2 {
    position: relative;
    top: -56%;
  }
}

@media only screen and (max-width: 390px) {
  #app_pic_div2 {
    position: relative;
    width: 80%;
    height: 25%;
    top: -56%;
    left: 10%;
  }
}

@media only screen and (max-width: 360px) {
  #app_pic_div2 {
    position: relative;
    height: 22%;
  }
}

#pic_10 {
  position: relative;
  width: 100%;
  height: 100%;
}

#app_pic_div-inner2 {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
#app_pic_div2:hover #app_pic_div-inner2 {
  transform: rotateY(180deg);
}

#app_pic_div-front2, #app_pic_div-back2 {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

#app_pic_div-front2 {
  background-color: #bbb;
  color: black;
}

#app_pic_div-back2 {
  background-color: #790ca4;
  color: white;
  padding: 10%;
  font-size: 24px;
  transform: rotateY(180deg);
  padding-top: 30%;
}

@media only screen and (max-width: 1440px) {
  #app_pic_div-back2 {
    background-color: #790ca4;
    color: white;
    padding: 10%;
    font-size: 120%;
    transform: rotateY(180deg);
    padding-top: 30%;
  }
}


@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #app_pic_div-back2 {
    position: relative;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #app_pic_div-back2 {
    position: relative;
    font-size: 100%;
  }
}

@media only screen and (max-width: 854px) and (orientation: landscape) {
  #app_pic_div-back2 {
    position: relative;
    padding-top: 20%;
  }
}

@media only screen and (max-width: 810px) {
  #app_pic_div-back2 {
    position: relative;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #app_pic_div-back2 {
    position: relative;
    font-size: 90%;
  }
}

@media only screen and (max-width: 768px) {
  #app_pic_div-back2 {
    position: relative;
  }
}

@media only screen and (max-width: 480px) {
  #app_pic_div-back2 {
    position: relative;
  }
}

#ad_div5 {
  position: relative;
  padding: 5%;
  margin-top: -65%;
  /*border: 3px solid rgb(39, 224, 165);*/
  width: 40%;
  height: 20%;
  left: 10%;
  text-align: center;
  font-size: 24px;
}

@media only screen and (max-width: 1440px) {
  #ad_div5 {
    position: relative;
    padding: 5%;
    margin-top: -90%;
    width: 40%;
    height: 20%;
    left: 10%;
    text-align: center;
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #ad_div5 {
    position: relative;
    margin-top: -108%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #ad_div5 {
    position: relative;
    margin-top: -130%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #ad_div5 {
    position: relative;
    font-size: 18px;
    margin-top: -150%;
  }
}

@media only screen and (max-width: 896px) and (orientation: landscape) {
  #ad_div5 {
    position: relative;
    font-size: 18px;
    margin-top: -155%;
  }
}

@media only screen and (max-width: 854px) and (orientation: landscape) {
  #ad_div5 {
    position: relative;
    margin-top: -160%;
  }
}

@media only screen and (max-width: 844px) and (orientation: landscape) {
  #ad_div5 {
    position: relative;
    margin-top: -162%;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  #ad_div5 {
    position: relative;
    font-size: 17px;
    margin-top: -168%;
  }
}

@media only screen and (max-width: 810px) {
  #ad_div5 {
    position: relative;
    margin-top: -170%;
    left: 5%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #ad_div5 {
    position: relative;
    margin-top: -178%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #ad_div5 {
    position: relative;
    font-size: 16px;
    margin-top: -179%;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  #ad_div5 {
    position: relative;
    margin-top: -205%;
    font-size: 90%;
  }
}

@media only screen and (max-width: 428px) {
  #ad_div5 {
    position: relative;
    margin-top: -255%;
    width: 80%;
    height: 20%;
    left: 10%;
  }
}

@media only screen and (max-width: 390px) {
  #ad_div5 {
    position: relative;
    margin-top: -270%;
    width: 80%;
    height: 20%;
    left: 10%;
  }
}

@media only screen and (max-width: 360px) {
  #ad_div5 {
    position: relative;
    margin-top: -290%;
  }
}

.circle6 {
  position: absolute;
  background-color: #5e0b0b;
  width: 40rem;
  height: 40rem;
  left: 5%;
  margin-top: -80%;
  border-radius: 50%;
  opacity: .1;
  transition: transform 0.2s; /* Add a transition for smoother animation */
  z-index: 0;
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  .circle6 {
    position: absolute;
    margin-top: -105%;
    left: -10%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  .circle6 {
    position: absolute;
    margin-top: -180%;
    left: -20%;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  .circle6 {
    position: absolute;
    margin-top: -210%;
  }
}

@media only screen and (max-width: 810px) {
  .circle6 {
    position: absolute;
    left: -20%;
    margin-top: -160%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  .circle6 {
    position: absolute;
    margin-top: -210%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  .circle6 {
    position: absolute;
    left: -40%;
    margin-top: -260%;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  .circle6 {
    position: absolute;
    margin-top: -255%;
  }
}

@media only screen and (max-width: 428px) {
  .circle6 {
    position: absolute;
    margin-top: -250%;
  }
}

@media only screen and (max-width: 360px) {
  .circle6 {
    position: absolute;
    margin-top: -300%;
  }
}


.word6 {
  position: absolute;
  width: 85rem;
  height: 10rem;
  right: 45%;
  /*border-radius: 50%;*/
  margin-top: -20%;
  opacity: .1;
  transition: transform 0.2s; 
}

@media only screen and (max-width: 1440px) {
  .word6 {
    position: absolute;
    width: 85rem;
    height: 10rem;
    right: 45%;
    margin-top: -60%;
    opacity: .1;
    transition: transform 0.2s; 
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  .word6 {
    position: absolute;
    margin-top: -80%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  .word6 {
    position: absolute;
    margin-top: -140%;
    right: 65%;
  }
}

@media only screen and (max-width: 854px) and (orientation: landscape) {
  .word6 {
    position: absolute;
    margin-top: -155%;
    right: 55%;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  .word6 {
    position: absolute;
    margin-top: -167%;
    right: 55%;
  }
}

@media only screen and (max-width: 810px) {
  .word6 {
    position: absolute;
    margin-top: -120%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  .word6 {
    position: absolute;
    margin-top: -185%;
    right: 55%;
  }
}

@media only screen and (max-width: 760px) {
  .word6 {
    position: absolute;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  .word6 {
    position: absolute;
    margin-top: -212%;
    right: 55%;
  }
}

@media only screen and (max-width: 428px) {
  .word6 {
    position: absolute;
    margin-top: -120%;
  }
}

@media only screen and (max-width: 414px) {
  .word6 {
    position: absolute;
    margin-top: -110%;
  }
}

@media only screen and (max-width: 390px) {
  .word6 {
    position: absolute;
    margin-top: -125%;
  }
}

@media only screen and (max-width: 375px) {
  .word6 {
    position: absolute;
    margin-top: -110%;
  }
}

@media only screen and (max-width: 360px) {
  .word6 {
    position: absolute;
    margin-top: -150%;
    right: 65%;
  }
}

#ad_div6 {
  position: relative;
  /*border: 3px solid rgb(224, 39, 218);*/
  width: 100%;
  height: 80rem;
  margin-top: -35%;
}

@media only screen and (max-width: 1440px) {
  #ad_div6 {
    position: relative;
    width: 100%;
    height: 84rem;
    margin-top: -90%;
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #ad_div6 {
    position: relative;
    height: 90rem;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #ad_div6 {
    position: relative;
    margin-top: -125%;
    height: 60rem;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #ad_div6 {
    position: relative;
    margin-top: -192%;
    height: 58rem;
  }
}

@media only screen and (max-width: 854px) and (orientation: landscape) {
  #ad_div6 {
    position: relative;
    margin-top: -210%;
    height: 60rem;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  #ad_div6 {
    position: relative;
    margin-top: -230%;
    height: 54rem;
  }
}

@media only screen and (max-width: 810px) {
  #ad_div6 {
    position: relative;
    margin-top: -180%;
    height: 68rem;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #ad_div6 {
    position: relative;
    margin-top: -246%;
    height: 57rem;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #ad_div6 {
    position: relative;
    margin-top: -252%;
    height: 58rem;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  #ad_div6 {
    position: relative;
    margin-top: -280%;
    height: 60rem;
  }
}

@media only screen and (max-width: 428px) {
  #ad_div6 {
    position: relative;
    margin-top: -245%;
    height: 65rem;
  }
}

@media only screen and (max-width: 414px) {
  #ad_div6 {
    position: relative;
    margin-top: -240%;
    height: 65rem;
  }
}

@media only screen and (max-width: 390px) {
  #ad_div6 {
    position: relative;
    margin-top: -265%;
    height: 65rem;
  }
}

@media only screen and (max-width: 375px) {
  #ad_div6 {
    position: relative;
    margin-top: -258%;
    height: 65rem;
  }
}

@media only screen and (max-width: 360px) {
  #ad_div6 {
    position: relative;
    margin-top: -295%;
    height: 65rem;
  }
}

#bottom_ad_heading1 {
  position: relative;
  /*border: 3px solid rgb(50, 246, 53);*/
  width: 40%;
  height: 10rem;
  margin-top: 5%;
  left: 10%;
  text-align: center;
  z-index: 12;
}


@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #bottom_ad_heading1 {
    position: relative;
    left: 11%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #bottom_ad_heading1 {
    position: relative;
    left: 9%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #bottom_ad_heading1 {
    position: relative;
    left: 8%;
  }
}

@media only screen and (max-width: 810px) {
  #bottom_ad_heading1 {
    position: relative;
    left: 8%;
  }
}

@media only screen and (max-width: 768px) {
  #bottom_ad_heading1 {
    position: relative;
  }
}

@media only screen and (max-width: 428px) {
  #bottom_ad_heading1 {
    position: relative;
    left: 30%;
  }
}


#ad_div7 {
  position: relative;
  /*display: inline-flex;*/
  /*opacity: .5;*/
  background-image: url(./images/pic_7.jpeg);
  background-size: 100% 100%;
  margin-top: -15%;
  height: 40rem;
  width: 40%;
  padding: 2%;
  /*border: 3px solid rgb(50, 246, 53);*/
  margin-bottom: 20%;
  margin-left: 52%;
  z-index: 10;
  box-shadow: 0 16px 32px 0 rgba(187, 183, 183, 0.972), 0 6px 20px 0 rgba(187, 183, 183, 0.972);
}

@media only screen and (max-width: 1440px) {
  #ad_div7 {
    position: relative;
    background-color: rgb(159, 159, 159, 0.5);
    margin-top: -15%;
    height: 40rem;
    width: 40%;
    padding: 2%;
    margin-bottom: 20%;
    margin-left: 52%;
    z-index: 10;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #ad_div7 {
   position: relative;
   height: 25rem;
   width: 35%;
   margin-left: 55%;
   /*width: 28%;
   margin-left: 55%;*/
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #ad_div7 {
    position: relative;
    width: 35%;
    margin-left: 55%;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  #ad_div7 {
    position: relative;
    height: 22rem;
    width: 35%;
    margin-left: 55%;
  }
}

@media only screen and (max-width: 810px) {
  #ad_div7 {
    position: relative;
    height: 30rem;
    width: 40%;
    margin-left: 52%;
  }
}

@media only screen and (max-width: 760px) {
  #ad_div7 {
    position: relative;
  }
}

@media only screen and (max-width: 428px) {
  #ad_div7 {
    position: relative;
    height: 18rem;
    width: 65%;
    margin-left: 18%;
    margin-bottom: 0%;
  }
}


#ad_div7:hover {
  background-image: none;
  background-color: rgba(101, 9, 239, 0.9);
  animation-name: fade;
  animation-duration: 1.5s;   
}

@keyframes fade {
  from {
    opacity: .3;
  }
  to {
    opacity: 1;
  }
}

#ad_textdiv1 {
  position: relative;
  opacity: 1;
  /*border: 3px solid rgb(50, 246, 53);*/
  height: -webkit-fit-content;
  height:fit-content;
  width: -moz-fit-content;
  width:fit-content;
  font-size: 65px;
  margin-top: 10%;
  margin-left: 32%;
  color: white;
  text-shadow: 
      -1px -1px 0 #000,  
      1px -1px 0 #000,
      -1px 1px 0 #000,
      1px 1px 0 #000; /* Outline color and thickness */   
}

@media only screen and (max-width: 1440px) {
  #ad_textdiv1 {
    margin-left: 24%;
  }
}


@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #ad_textdiv1 {
    font-size: 45px;
    margin-left: 26%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #ad_textdiv1 {
    margin-left: 20%;
  }
}

@media only screen and (max-width: 915px) and (orientation: landscape) {
  #ad_textdiv1 {
    margin-left: 18%;
  }
}

@media only screen and (max-width: 854px) and (orientation: landscape) {
  #ad_textdiv1 {
    margin-left: 16%;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  #ad_textdiv1 {
    margin-left: 15%;
  }
}

@media only screen and (max-width: 810px) {
  #ad_textdiv1 {
    font-size: 55px;
    margin-left: 12%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #ad_textdiv1 {
    margin-left: 13%;
    font-size: 45px;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  #ad_textdiv1 {
    margin-left: 10%;
    font-size: 42px;
  }
}

@media only screen and (max-width: 428px) {
  #ad_textdiv1 {
    margin-left: 16%;
    font-size: 45px;
  }
}

@media only screen and (max-width: 390px) {
  #ad_textdiv1 {
    margin-left: 14%;
  }
}

@media only screen and (max-width: 375px) {
  #ad_textdiv1 {
    margin-left: 12%;
  }
}

@media only screen and (max-width: 360px) {
  #ad_textdiv1 {
    margin-left: 10%;
  }
}

#ad_div7:hover #ad_textdiv1 {
  color: black;
  text-shadow: none;
}

#Tengulogocute_8 {
  position: relative;
  width: 50%;
  height: 50%;
  margin-left: 25%;
  opacity: 0;
}

#ad_div7:hover #Tengulogocute_8 {
  opacity: 1;
}

#adtextdiv1_sub {
  position: relative;
  color: white;
  margin-top: 5%;
  margin-left: 2%;
  font-size: 35px;
  text-shadow: 
      -1px -1px 0 #000,  
      1px -1px 0 #000,
      -1px 1px 0 #000,
      1px 1px 0 #000; /* Outline color and thickness */
}

@media only screen and (max-width: 1440px) {
  #adtextdiv1_sub {
    margin-left: 6%;
    font-size: 24px;
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #adtextdiv1_sub {
    margin-left: 2%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #adtextdiv1_sub {
    font-size: 16px;
    margin-left: 2%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #adtextdiv1_sub {
    margin-left: 0%;
    font-size: 14px;
  }
}

@media only screen and (max-width: 915px) and (orientation: landscape) {
  #adtextdiv1_sub {
    margin-left: 6%;
    font-size: 12px;
  }
}

@media only screen and (max-width: 896px) and (orientation: landscape) {
  #adtextdiv1_sub {
    margin-left: 4%;
    font-size: 12px;
  }
}

@media only screen and (max-width: 854px) and (orientation: landscape) {
  #adtextdiv1_sub {
    margin-left: 2%;
    font-size: 12px;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  #adtextdiv1_sub {
    margin-left: 0%;
  }
}

@media only screen and (max-width: 810px) {
  #adtextdiv1_sub {
    margin-left: 1%;
    font-size: 14px;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #adtextdiv1_sub {
    margin-left: 0%;
    font-size: 11px;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  #adtextdiv1_sub {
    margin-left: 2%;
    font-size: 9px;
  }
}

@media only screen and (max-width: 428px) {
  #adtextdiv1_sub {
    margin-top: 5%;
    margin-left: 2%;
    font-size: 12px;
  }
}

@media only screen and (max-width: 414px) {
  #adtextdiv1_sub {
    margin-left: 4%;
    font-size: 11px;
  }
}

@media only screen and (max-width: 390px) {
  #adtextdiv1_sub {
    margin-left: 2%;
    font-size: 11px;
  }
}

@media only screen and (max-width: 375px) {
  #adtextdiv1_sub {
    margin-left: 2%;
    font-size: 10px;
  }
}

#adtextdiv1_sub {
  color: white;
}

#ad_div7:hover #adtextdiv1_sub {
  color: black;
  text-shadow: none;
}


#ad_div8 {
  position: relative;
  /*display: inline-flex;*/
  z-index: 10;
  background-image: url(./images/PC5.png);
  background-size: 100% 100%;
  margin-top: -34%;
  height: 40rem;
  width: 40%;
  padding: 2%;
  /*border: 3px solid rgb(22, 31, 198);*/
  margin-bottom: 8%;
  margin-left: 10%;
  box-shadow: 0 16px 32px 0 rgba(187, 183, 183, 0.972), 0 6px 20px 0 rgba(187, 183, 183, 0.972);
}

@media only screen and (max-width: 1440px) {
  #ad_div8 {
    position: relative;
    z-index: 10;
    margin-top: -40%;
    height: 40rem;
    width: 40%;
    padding: 2%;
    margin-bottom: 8%;
    margin-left: 10%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #ad_div8 {
   position: relative;
   height: 25rem;
   width: 35%;
   margin-left: 10%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #ad_div8 {
    position: relative;
    width: 35%;
    margin-left: 10%;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  #ad_div8 {
    position: relative;
    height: 22rem;
    width: 35%;
    margin-left: 10%;
  }
}

@media only screen and (max-width: 810px) {
  #ad_div8 {
    position: relative;
    margin-top: -45%;
    height: 30rem;
    width: 40%;
    margin-left: 8%;
  }
}

@media only screen and (max-width: 760px) {
  #ad_div8 {
    position: relative;
  }
}

@media only screen and (max-width: 428px) {
  #ad_div8 {
    position: relative;
    height: 18rem;
    width: 65%;
    margin-left: 18%;
    margin-top: 8%;
    margin-bottom: 0%;
  }
}


#ad_div8:hover {
  background-color: rgba(69, 11, 13, 0.9);
  background-image: none;
  animation-name: fade;
  animation-duration: 1.5s;    
}

@keyframes fade {
  from {
    opacity: .5;
  }
  to {
    opacity: 1;
  }
}

#ad_textdiv2 {
  position: relative;
  opacity: 1;
  /*border: 3px solid rgb(50, 246, 53);*/
  height: -webkit-fit-content;
  height:fit-content;
  width: -moz-fit-content;
  width:fit-content;
  font-size: 65px;
  margin-top: 10%;
  margin-left: 34%;
  color: white;
  text-shadow: 
      -1px -1px 0 #000,  
      1px -1px 0 #000,
      -1px 1px 0 #000,
      1px 1px 0 #000; /* Outline color and thickness */    
}

@media only screen and (max-width: 1440px) {
  #ad_textdiv2 {
    margin-left: 28%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #ad_textdiv2 {
    font-size: 45px;
    margin-left: 26%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #ad_textdiv2 {
    font-size: 45px;
    margin-left: 24%;
  }
}

@media only screen and (max-width: 896px) and (orientation: landscape) {
  #ad_textdiv2 {
    font-size: 45px;
    margin-left: 22%;
  }
}

@media only screen and (max-width: 854px) and (orientation: landscape) {
  #ad_textdiv2 {
    font-size: 45px;
    margin-left: 20%;
  }
}

@media only screen and (max-width: 810px) {
  #ad_textdiv2 {
    font-size: 55px;
    margin-left: 18%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #ad_textdiv2 {
    font-size: 45px;
    margin-left: 16%;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  #ad_textdiv2 {
    font-size: 42px;
    margin-left: 16%;
  }
}

@media only screen and (max-width: 428px) {
  #ad_textdiv2 {
    margin-left: 20%;
    font-size: 45px;
  }
}

@media only screen and (max-width: 390px) {
  #ad_textdiv2 {
    margin-left: 18%;
    font-size: 45px;
  }
}

@media only screen and (max-width: 375px) {
  #ad_textdiv2 {
    margin-left: 16%;
    font-size: 45px;
  }
}

#ad_div8:hover #ad_textdiv2 {
  color: black;
  text-shadow: none;
}

#Tengulogocute_9 {
  position: relative;
  width: 50%;
  height: 50%;
  margin-left: 25%;
  opacity: 0;
}

#ad_div8:hover #Tengulogocute_9 {
  opacity: 1;
}

#adtextdiv2_sub {
  position: relative;
  color: white;
  margin-top: 5%;
  margin-left: 10%;
  font-size: 35px;
  text-shadow: 
      -1px -1px 0 #000,  
      1px -1px 0 #000,
      -1px 1px 0 #000,
      1px 1px 0 #000; /* Outline color and thickness */    
}

@media only screen and (max-width: 1440px) {
  #adtextdiv2_sub {
    font-size: 24px;
    margin-left: 14%;
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #adtextdiv2_sub {
    margin-left: 10%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #adtextdiv2_sub {
    font-size: 16px;
    margin-left: 8%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #adtextdiv2_sub {
    font-size: 14px;
    margin-left: 8%;
  }
}

@media only screen and (max-width: 896px) and (orientation: landscape) {
  #adtextdiv2_sub {
    font-size: 14px;
    margin-left: 6%;
  }
}

@media only screen and (max-width: 854px) and (orientation: landscape) {
  #adtextdiv2_sub {
    font-size: 14px;
    margin-left: 4%;
  }
}

@media only screen and (max-width: 844px) and (orientation: landscape) {
  #adtextdiv2_sub {
    font-size: 14px;
    margin-left: 2%;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  #adtextdiv2_sub {
    font-size: 13px;
    margin-left: 2%;
  }
}

@media only screen and (max-width: 810px) {
  #adtextdiv2_sub {
    font-size: 16px;
    margin-left: 4%;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  #adtextdiv2_sub {
    font-size: 11px;
    margin-left: 3%;
  }
}

@media only screen and (max-width: 428px) {
  #adtextdiv2_sub {
    font-size: 14px;
    margin-left: 4%;
  }
}

@media only screen and (max-width: 414px) {
  #adtextdiv2_sub {
    margin-left: 3%;
  }
}

@media only screen and (max-width: 390px) {
  #adtextdiv2_sub {
    margin-left: 2%;
    font-size: 13px;
  }
}

@media only screen and (max-width: 375px) {
  #adtextdiv2_sub {
    margin-left: 1%;
    font-size: 13px;
  }
}

@media only screen and (max-width: 375px) {
  #adtextdiv2_sub {
    margin-left: 2%;
    font-size: 12px;
  }
}



#adtextdiv2_sub {
  color: white;
}

#ad_div8:hover #adtextdiv2_sub {
  color: black;
  text-shadow: none;
}


#ad_div9 {
  position: relative;
  /*display: inline-flex;*/
  z-index: 10;
  background-image: url(./images/pic_11.JPG);
  background-size: 100% 100%;
  margin-top: -21%;
  height: 40rem;
  width: 40%;
  padding: 2%;
  /*border: 3px solid rgb(205, 86, 138);*/
  margin-bottom: 8%;
  margin-left: 52%;
  box-shadow: 0 16px 32px 0 rgba(187, 183, 183, 0.972), 0 6px 20px 0 rgba(187, 183, 183, 0.972);
}

@media only screen and (max-width: 1440px) {
  #ad_div9 {
    position: relative;
    z-index: 10;
    background-color: rgb(159, 159, 159, 0.5);
    margin-top: -28%;
    height: 40rem;
    width: 40%;
    padding: 2%;
    margin-bottom: 8%;
    margin-left: 52%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #ad_div9 {
   position: relative;
   height: 25rem;
   width: 35%;
   margin-left: 55%;
   margin-top: -22%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #ad_div9 {
    position: relative;
    width: 35%;
    margin-left: 55%;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  #ad_div9 {
    position: relative;
    height: 22rem;
    width: 35%;
    margin-left: 55%;
  }
}

@media only screen and (max-width: 810px) {
  #ad_div9 {
    position: relative;
    margin-top: -32%;
    height: 30rem;
    width: 40%;
    margin-left: 52%;
  }
}

@media only screen and (max-width: 760px) {
  #ad_div9 {
    position: relative;
  }
}

@media only screen and (max-width: 480px) {
  #ad_div9 {
    position: relative;
    height: 18rem;
    width: 65%;
    margin-left: 18%;
    margin-top: 8%;
  }
}

#ad_div9:hover {
  background-image: none;
  background-color: rgba(158, 53, 210, 0.9);
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {
    opacity: .5;
  }
  to {
    opacity: 1;
  }
}

#ad_textdiv3 {
  position: relative;
  opacity: 1;
  /*border: 3px solid rgb(50, 246, 53);*/
  height: -webkit-fit-content;
  height:fit-content;
  width: -moz-fit-content;
  width:fit-content;
  font-size: 65px;
  margin-top: 10%;
  margin-left: 26%;
  color: white;
  text-shadow: 
      -1px -1px 0 #000,  
      1px -1px 0 #000,
      -1px 1px 0 #000,
      1px 1px 0 #000; 
}

@media only screen and (max-width: 1440px) {
  #ad_textdiv3 {
    margin-left: 16%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #ad_textdiv3 {
    font-size: 45px;
    margin-left: 16%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #ad_textdiv3 {
    font-size: 45px;
    margin-left: 10%;
  }
}

@media only screen and (max-width: 854px) and (orientation: landscape) {
  #ad_textdiv3 {
    font-size: 45px;
    margin-left: 8%;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  #ad_textdiv3 {
    font-size: 45px;
    margin-left: 6%;
  }
}

@media only screen and (max-width: 810px) {
  #ad_textdiv3 {
    font-size: 55px;
    margin-left: 2%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #ad_textdiv3 {
    font-size: 45px;
    margin-left: 2%;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  #ad_textdiv3 {
    font-size: 42px;
    margin-left: 0%;
  }
}

@media only screen and (max-width: 428px) {
  #ad_textdiv3 {
    font-size: 45px;
    margin-left: 6%;
  }
}

@media only screen and (max-width: 390px) {
  #ad_textdiv3 {
    font-size: 45px;
    margin-left: 2%;
  }
}

@media only screen and (max-width: 375px) {
  #ad_textdiv3 {
    margin-left: 0%;
  }
}

@media only screen and (max-width: 360px) {
  #ad_textdiv3 {
    font-size: 40px;
    margin-left: 5%;
  }
}

#ad_div9:hover #ad_textdiv3 {
  color: black;
  text-shadow: none;
}

#Tengulogocute_10 {
  position: relative;
  width: 50%;
  height: 50%;
  margin-left: 25%;
  opacity: 0;
}

#ad_div9:hover #Tengulogocute_10 {
  opacity: 1;
}


#adtextdiv3_sub {
  position: relative;
  margin-top: 5%;
  color: white;
  margin-left: 20%;
  font-size: 35px;
  text-shadow: 
      -1px -1px 0 #000,  
      1px -1px 0 #000,
      -1px 1px 0 #000,
      1px 1px 0 #000; 
}

@media only screen and (max-width: 1440px) {
  #adtextdiv3_sub {
   font-size: 24px;
    margin-left: 22%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #adtextdiv3_sub {
   font-size: 16px;
    margin-left: 20%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #adtextdiv3_sub {
   font-size: 14px;
    margin-left: 20%;
  }
}

@media only screen and (max-width: 896px) and (orientation: landscape) {
  #adtextdiv3_sub {
   font-size: 14px;
    margin-left: 18%;
  }
}

@media only screen and (max-width: 854px) and (orientation: landscape) {
  #adtextdiv3_sub {
   font-size: 14px;
    margin-left: 16%;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  #adtextdiv3_sub {
   font-size: 14px;
    margin-left: 14%;
  }
}

@media only screen and (max-width: 810px) {
  #adtextdiv3_sub {
    font-size: 16px;
    margin-left: 16%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #adtextdiv3_sub {
   font-size: 14px;
    margin-left: 11%;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  #adtextdiv3_sub {
   font-size: 14px;
    margin-left: 6%;
  }
}

@media only screen and (max-width: 428px) {
  #adtextdiv3_sub {
    font-size: 16px;
    margin-left: 10%;
  }
}

@media only screen and (max-width: 390px) {
  #adtextdiv3_sub {
    font-size: 16px;
    margin-left: 8%;
  }
}

@media only screen and (max-width: 375px) {
  #adtextdiv3_sub {
    font-size: 16px;
    margin-left: 6%;
  }
}

@media only screen and (max-width: 360px) {
  #adtextdiv3_sub {
    margin-left: 4%;
  }
}


#adtextdiv3_sub {
  color: white;
}

#ad_div9:hover #adtextdiv3_sub {
  color: black;
  text-shadow: none;
}


#bottom_ad_heading2 {
  position: relative;
  /*border: 3px solid rgb(50, 246, 53);*/
  width: 40%;
  height: 10rem;
  left: 12%;
  margin-top: -15%;
  text-align: center;
  z-index: 10;
}

.circle7 {
  position: absolute;
  background-color: #790ca4;
  width: 20rem;
  height: 20rem;
  left: 0%;
  border-radius: 50%;
  margin-top: -15%;
  opacity: .1;
  z-index: 1;
  transition: transform 0.2s; /* Add a transition for smoother animation */
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  .circle7 {
    position: relative;
    left: -60%;
    margin-top: -20%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  .circle7 {
    position: relative;
    left: -54;
    margin-top: -30%
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  .circle7 {
    position: relative;
    left: -80%;
    margin-top: -50%
  }
}

@media only screen and (max-width: 896px) and (orientation: landscape) {
  .circle7 {
    position: relative;
    left: -90%;
    margin-top: -50%
  }
}


@media only screen and (max-width: 810px) {
  .circle7 {
    position: relative;
    left: -100%;
    margin-top: -45%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  .circle7 {
    position: relative;
    left: -125%;
    margin-top: -60%
  }
}

@media only screen and (max-width: 428px) {
  .circle7 {
    position: relative;
    left: -200%;
    margin-top: -85%;
  }
}

@media only screen and (max-width: 390px) {
  .circle7 {
    position: relative;
    left: -230%;
    margin-top: -100%;
  }
}

@media only screen and (max-width: 375px) {
  .circle7 {
    position: relative;
    left: -260%;
    margin-top: -100%;
  }
}


/*security.js*/

#security_div1 {
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  height: 100%;
  /*width: 100%;*/
  /*height: 160rem;*/
  /*border: 3px solid rgb(181, 8, 40);*/
  overflow: hidden;
}

#security_div2 {
  position: relative;
  /*border: 3px solid rgb(15, 180, 192);*/
  margin-top: 15%;
  left: 55%;
  width: 35%;
  height: 25rem;
  z-index: 10;
  padding: 5%;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(187, 183, 183, 0.972), 0 6px 10px 0 rgba(187, 183, 183, 0.972);
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #security_div2 {
    position: relative;
    margin-top: 25%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #security_div2 {
    position: relative;
    height: 23rem;
    margin-top: 6%;
  }
}

@media only screen and (max-width: 915px) and (orientation: landscape) {
  #security_div2 {
    position: relative;
    margin-top: 3%;
  }
}

@media only screen and (max-width: 854px) and (orientation: landscape) {
  #security_div2 {
    position: relative;
    margin-top: 3%;
    height: 22rem;
    font-size: 90%;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  #security_div2 {
    position: relative;
    margin-top: 3%;
    height: 21rem;
  }
}

@media only screen and (max-width: 810px) {
  #security_div2 {
    position: relative;
    margin-top: 25%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #security_div2 {
    position: relative;
    margin-top: 3%;
    height: 20rem;
    width: 40%;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  #security_div2 {
    position: relative;
    margin-top: 5%;
  }
}

@media only screen and (max-width: 428px) {
  #security_div2 {
    position: relative;
    margin-top: 110%;
    width: 65%;
    height: 20rem;
    font-size: 90%;
    left: 30%;
  }
}

@media only screen and (max-width: 390px) {
  #security_div2 {
    position: relative;
    
  }
}

@media only screen and (max-width: 375px) {
  #security_div2 {
    position: relative;
    
  }
}

#security_div3 {
  position: relative;
  /*border: 3px solid rgb(18, 192, 15);*/
  margin-top: -30%;
  left: 5%;
  width: 75%;
  height: 42rem;
}

@media only screen and (max-width: 1440px) {
  #security_div3 {
    position: relative;
    margin-top: -35%;
    left: 5%;
    width: 75%;
    height: 35rem;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #security_div3 {
    position: relative;
    margin-top: -45%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #security_div3 {
    position: relative;
    margin-top: -43%;
    height: 25rem;
  }
}

@media only screen and (max-width: 915px) and (orientation: landscape) {
  #security_div3 {
    position: relative;
    margin-top: -42%;
    height: 24rem;
  }
}

@media only screen and (max-width: 854px) and (orientation: landscape) {
  #security_div3 {
    position: relative;
    margin-top: -42%;
    height: 22rem;
  }
}

@media only screen and (max-width: 810px) {
  #security_div3 {
    position: relative;
    margin-top: -55%;
    left: 5%;
    width: 75%;
    height: 35rem;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #security_div3 {
    position: relative;
    margin-top: -42%;
    height: 21rem;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #security_div3 {
    position: relative;
    height: 20rem;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  #security_div3 {
    position: relative;
    height: 21rem;
    margin-top: -48%;
  }
}

@media only screen and (max-width: 428px) {
  #security_div3 {
    position: relative;
    margin-top: -160%;
    left: 5%;
    width: 90%;
    height: 35rem;
  }
}

@media only screen and (max-width: 390px) {
  #security_div3 {
    position: relative;
    
  }
}

@media only screen and (max-width: 375px) {
  #security_div3 {
    position: relative;
    
  }
}

#PC5 {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.word7 {
  position: absolute;
  width: 85rem;
  height: 10rem;
  right: 15%;
  margin-top: 10%;
  opacity: .1;
  transition: transform 0.2s; 
}

@media only screen and (max-width: 1440px) and (orientation: landscape) {
  .word7 {
    position: absolute;
    width: 85rem;
    height: 10rem;
    right: 10%;
    margin-top: 5%;
    opacity: .1;
    transition: transform 0.2s; 
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  .word7 {
    position: relative;
    right: 15%;
    margin-top: 10%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  .word7 {
    position: relative;
    
  }
}

@media only screen and (max-width: 810px) {
  .word7 {
    position: relative;
    right: 25%;
    margin-top: 10%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  .word7 {
    position: relative;
    right: 8%;
    margin-top: 12%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  .word7 {
    position: relative;
    
  }
}

@media only screen and (max-width: 428px) {
  .word7 {
    position: relative;
    right: 40%;
    margin-top: 40%;
  }
}

@media only screen and (max-width: 390px) {
  .word7 {
    position: relative;
    
  }
}

@media only screen and (max-width: 375px) {
  .word7 {
    position: relative;
    
  }
}

.circle9 {
  position: absolute;
  background-color: #240594;
  width: 55rem;
  height: 55rem;
  left: -10%;
  margin-top: -20%;
  border-radius: 50%;
  opacity: .1;
  transition: transform 0.2s; 
  z-index: 0;
}

@media only screen and (max-width: 1440px) {
  .circle9 {
    position: absolute;
    width: 40rem;
    height: 40rem;
    left: 0%;
    margin-top: -20%;
    border-radius: 50%;
    opacity: .1;
    transition: transform 0.2s; 
    z-index: 0;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  .circle9 {
    position: absolute;
    margin-top: -55%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  .circle9 {
    position: absolute;
    left: -10%;
  }
}

@media only screen and (max-width: 428px) {
  .circle9 {
    position: absolute;
    margin-top: -60%;
  }
}

@media only screen and (max-width: 414px) {
  .circle9 {
    position: absolute;
    margin-top: -100%;
  }
}

@media only screen and (max-width: 360px) {
  .circle9 {
    position: absolute;
  }
}

#security_div4 {
  position: relative;
  /*border: 3px solid rgb(18, 192, 15);*/
  width: 100%;
  height: 25%;
  margin-top: -24%;
}

@media only screen and (max-width: 1440px){
  #security_div4 {
    position: relative;
    margin-top: -40%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #security_div4 {
    position: relative;
    margin-top: -70%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #security_div4 {
    position: relative;
    margin-top: -80%;
    margin-left: -10%;
  }
}

@media only screen and (max-width: 810px) {
  #security_div4 {
    position: relative;
    width: 130%;
    margin-top: -85%;
    margin-left: -30%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #security_div4 {
    position: relative;
    margin-top: -90%;
    margin-left: -10%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #security_div4 {
    position: relative;
    margin-top: -105%;
  }
}

@media only screen and (max-width: 428px) {
  #security_div4 {
    position: relative;
    width: 160%;
    margin-top: -170%;
    margin-left: -53%;
  }
}

@media only screen and (max-width: 360px) {
  #security_div4 {
    position: relative;
    margin-top: -190%;
  }
}

#sec_subhead {
  position: relative;
  /*border: 3px solid rgb(18, 192, 15);*/
  margin-top: 14%;
  background-color: black;
  color: white;
  width: 50%;
  height: 50%;
  left: 40%;
  padding: 5%;
  border-radius: 10px;
}



#sec_div5 {
  position: relative;
  /*border: 3px solid rgb(224, 39, 218);*/
  width: 100%;
  height: 78rem;
  margin-top: 26%;
}

@media only screen and (max-width: 1440px) {
  #sec_div5 {
    position: relative;
    width: 100%;
    height: 85rem;
    margin-top: 10%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #sec_div5 {
   position: relative;
   margin-top: -8%;
   height: 80rem;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #sec_div5 {
    position: relative;
    margin-top: -22%;
    height: 52rem;
  }
}

@media only screen and (max-width: 883px) and (orientation: landscape) {
  #sec_div5 {
    position: relative;
    margin-top: -16%;
    height: 48rem;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  #sec_div5 {
    position: relative;
    margin-top: -16%;
    height: 52rem;
  }
}

@media only screen and (max-width: 810px) {
  #sec_div5 {
    position: relative;
    margin-top: -10%;
    height: 60rem;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #sec_div5 {
    position: relative;
    margin-top: -18%;
    height: 46rem;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #sec_div5 {
    position: relative;
    margin-top: -26%;
    height: 50rem;
  }
}

@media only screen and (max-width: 428px) {
  #sec_div5 {
    position: relative;
    height: 70rem;
    margin-top: -44%;
  }
}



#bottom_sec_heading1 {
  position: relative;
  /*border: 3px solid rgb(50, 246, 53);*/
  width: 40%;
  height: 10rem;
  margin-top: 5%;
  left: 10%;
  text-align: center;
  z-index: 12;
}

@media only screen and (max-width: 1440px) {
  #bottom_sec_heading1 {
    position: relative;
    width: 40%;
    height: 10rem;
    margin-top: 5%;
    left: 11%;
    text-align: center;
    z-index: 12;
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #bottom_sec_heading1 {
    position: relative;
    left: 10%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #bottom_sec_heading1 {
    position: relative;
    
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #bottom_sec_heading1 {
    position: relative;
    margin-top: 15%;
    left: 8%;
  }
}

@media only screen and (max-width: 915px) and (orientation: landscape) {
  #bottom_sec_heading1 {
    position: relative;
    left: 10%;
  }
}

@media only screen and (max-width: 810px) {
  #bottom_sec_heading1 {
    position: relative;
    margin-top: 25%;
    left: 10%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #bottom_sec_heading1 {
    position: relative;
    left: 8%;
  }
}

@media only screen and (max-width: 428px) {
  #bottom_sec_heading1 {
    position: relative;
    margin-top: 0%;
    left: 30%;
  }
}

#sec_div6 {
  position: relative;
  /*display: inline-flex;*/
  /*opacity: .5;*/
  background-image: url(./images/pic_12.JPG);
  background-size: 100% 100%;
  margin-top: -15%;
  height: 40rem;
  width: 40%;
  padding: 2%;
  /*border: 3px solid rgb(50, 246, 53);*/
  margin-bottom: 20%;
  margin-left: 52%;
  z-index: 10;
  box-shadow: 0 16px 32px 0 rgba(187, 183, 183, 0.972), 0 6px 20px 0 rgba(187, 183, 183, 0.972);
}

@media only screen and (max-width: 1440px) {
  #sec_div6 {
    position: relative;
    margin-top: -15%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #sec_div6 {
   position: relative;
   width: 35%;
   height: 35rem;
   margin-left: 54%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #sec_div6 {
    position: relative;
    height: 26rem;
    margin-top: -25%;
  }
}

@media only screen and (max-width: 883px) and (orientation: landscape) {
  #sec_div6 {
    position: relative;
    height: 24rem;
  }
}

@media only screen and (max-width: 810px) {
  #sec_div6 {
    position: relative;
    width: 38%;
    height: 30rem;
    margin-left: 52%;
    margin-top: -35%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #sec_div6 {
    position: relative;
    height: 22rem;
  }
}

@media only screen and (max-width: 428px) {
  #sec_div6 {
    position: relative;
    margin-top: 0%;
    height: 18rem;
    width: 65%;
    margin-left: 18%;
    margin-bottom: 0%;
  }
}



#sec_div6:hover {
  background-color: rgba(69, 11, 13, 0.9);
  background-image: none;
  animation-name: fade;
  animation-duration: 1.5s; 
}

@keyframes fade {
  from {
    opacity: .3;
  }
  to {
    opacity: 1;
  }
}

#sec_textdiv1 {
  position: relative;
  opacity: 1;
  /*border: 3px solid rgb(50, 246, 53);*/
  height: -webkit-fit-content;
  height:fit-content;
  width: -moz-fit-content;
  width:fit-content;
  font-size: 65px;
  margin-top: 10%;
  margin-left: 25%;
  color: white;
  text-shadow: 
      -1px -1px 0 #000,  
      1px -1px 0 #000,
      -1px 1px 0 #000,
      1px 1px 0 #000; /* Outline color and thickness */ 
}

@media only screen and (max-width: 1440px) {
  #sec_textdiv1 {
    margin-left: 18%;
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #sec_textdiv1 {
    margin-left: 16%;
  }
}


@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #sec_textdiv1 {
    margin-left: 2%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #sec_textdiv1 {
    margin-left: 10%;
    font-size: 45px;
  }
}

@media only screen and (max-width: 915px) and (orientation: landscape) {
  #sec_textdiv1 {
    margin-left: 8%;
  }
}

@media only screen and (max-width: 854px) and (orientation: landscape) {
  #sec_textdiv1 {
    margin-left: 6%;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  #sec_textdiv1 {
    margin-left: 4%;
  }
}

@media only screen and (max-width: 810px) {
  #sec_textdiv1 {
    font-size: 45px;
    margin-left: 8%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #sec_textdiv1 {
    margin-left: 4%;
    font-size: 44px;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #sec_textdiv1 {
    margin-left: 4%;
    font-size: 38px;
  }
}

@media only screen and (max-width: 428px) {
  #sec_textdiv1 {
    font-size: 40px;
    margin-left: 10%;
  }
}

@media only screen and (max-width: 390px) {
  #sec_textdiv1 {
    margin-left: 7%;
  }
}

@media only screen and (max-width: 360px) {
  #sec_textdiv1 {
    font-size: 38px;
    margin-left: 7%;
  }
}

#sec_div6:hover #sec_textdiv1 {
  color: black;
  text-shadow: none;
}

#Tengulogocute_11 {
  position: relative;
  width: 50%;
  height: 50%;
  margin-left: 25%;
  opacity: 0;
}

#sec_div6:hover #Tengulogocute_11 {
  opacity: 1;
}

#sectextdiv1_sub {
  position: relative;
  color: white;
  margin-top: 5%;
  margin-left: 20%;
  font-size: 35px;
  text-shadow: 
      -1px -1px 0 #000,  
      1px -1px 0 #000,
      -1px 1px 0 #000,
      1px 1px 0 #000; /* Outline color and thickness */
}

@media only screen and (max-width: 1440px) {
  #sectextdiv1_sub {
    margin-left: 10%;
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #sectextdiv1_sub {
    margin-left: 6%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #sectextdiv1_sub {
    margin-left: 0%;
    font-size: 28px;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #sectextdiv1_sub {
    margin-left: 0%;
    font-size: 24px;
  }
}

@media only screen and (max-width: 915px) and (orientation: landscape) {
  #sectextdiv1_sub {
    margin-left: 0%;
    font-size: 23px;
  }
}

@media only screen and (max-width: 883px) and (orientation: landscape) {
  #sectextdiv1_sub {
    margin-left: 0%;
    font-size: 22px;
  }
}

@media only screen and (max-width: 854px) and (orientation: landscape) {
  #sectextdiv1_sub {
    margin-left: 6%;
    font-size: 18px;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  #sectextdiv1_sub {
    margin-left: 4%;
    font-size: 18px;
  }
}

@media only screen and (max-width: 810px) {
  #sectextdiv1_sub {
    margin-top: 5%;
    font-size: 20px;
    margin-left: 4%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #sectextdiv1_sub {
    margin-left: 2%;
    font-size: 18px;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #sectextdiv1_sub {
    margin-left: 4%;
    font-size: 14px;
  }
}

@media only screen and (max-width: 428px) {
  #sectextdiv1_sub {
    margin-top: 5%;
    font-size: 16px;
    margin-left: 10%;
  }
}

@media only screen and (max-width: 412px) {
  #sectextdiv1_sub {
    margin-top: 5%;
    font-size: 16px;
    margin-left: 8%;
  }
}

@media only screen and (max-width: 390px) {
  #sectextdiv1_sub {
    margin-top: 5%;
    margin-left: 6%;
  }
}

@media only screen and (max-width: 375px) {
  #sectextdiv1_sub {
    margin-top: 5%;
    margin-left: 5%;
  }
}

@media only screen and (max-width: 360px) {
  #sectextdiv1_sub {
    margin-top: 5%;
    margin-left: 3%;
  }
}


#sectextdiv1_sub {
  color: white;
}

#sec_div6:hover #sectextdiv1_sub {
  color: black;
  text-shadow: none;
}

#sec_div7 {
  position: relative;
  /*display: inline-flex;*/
  z-index: 10;
  background-image: url(./images/pic_13.jpeg);
  background-size: 100% 100%;
  margin-top: -34%;
  height: 40rem;
  width: 40%;
  padding: 2%;
  /*border: 3px solid rgb(22, 31, 198);*/
  margin-bottom: 8%;
  margin-left: 10%;
  box-shadow: 0 16px 32px 0 rgba(187, 183, 183, 0.972), 0 6px 20px 0 rgba(187, 183, 183, 0.972);
}

@media only screen and (max-width: 1440px) {
  #sec_div7 {
    position: relative;
    margin-top: -40%;
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #sec_div7 {
   position: relative;
   margin-top: -42%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #sec_div7 {
   position: relative;
   width: 35%;
   height: 35rem;
   margin-left: 12%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #sec_div7 {
    position: relative;
    height: 26rem;
    margin-top: -40%;
  }
}

@media only screen and (max-width: 883px) and (orientation: landscape) {
  #sec_div7 {
    position: relative;
    height: 24rem;
    margin-top: -40%;
  }
}

@media only screen and (max-width: 810px) {
  #sec_div7 {
    position: relative;
    width: 38%;
    height: 30rem;
    margin-top: -48%;
    margin-left: 10%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #sec_div7 {
    position: relative;
    height: 22rem;
    margin-top: -40%;
  }
}

@media only screen and (max-width: 760px) {
  #sec_div7 {
    position: relative;
  }
}

@media only screen and (max-width: 428px) {
  #sec_div7 {
    position: relative;
    height: 18rem;
    width: 65%;
    margin-left: 18%;
    margin-top: 8%;
    margin-bottom: 0%;
  }
}


#sec_div7:hover {
  background-image: none;
  background-color: rgba(158, 53, 210, 0.9);
  animation-name: fade;
  animation-duration: 1.5s;  
}

@keyframes fade {
  from {
    opacity: .5;
  }
  to {
    opacity: 1;
  }
}

#sec_textdiv2 {
  position: relative;
  opacity: 1;
  /*border: 3px solid rgb(50, 246, 53);*/
  height: -webkit-fit-content;
  height:fit-content;
  width: -moz-fit-content;
  width:fit-content;
  font-size: 65px;
  margin-top: 10%;
  margin-left: 34%;
  color: white;
  text-shadow: 
      -1px -1px 0 #000,  
      1px -1px 0 #000,
      -1px 1px 0 #000,
      1px 1px 0 #000; /* Outline color and thickness */
}

@media only screen and (max-width: 1440px) {
  #sec_textdiv2 {
    margin-left: 26%;
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #sec_textdiv2 {
    margin-left: 26%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #sec_textdiv2 {
    margin-left: 14%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #sec_textdiv2 {
    font-size: 45px;
    margin-left: 20%;
  }
}

@media only screen and (max-width: 854px) and (orientation: landscape) {
  #sec_textdiv2 {
    margin-left: 18%;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  #sec_textdiv2 {
    margin-left: 16%;
  }
}

@media only screen and (max-width: 810px) {
  #sec_textdiv2 {
    font-size: 45px;
    margin-left: 19%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #sec_textdiv2 {
    margin-left: 16%;
    font-size: 44px;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #sec_textdiv2 {
    margin-left: 14%;
    font-size: 38px;
  }
}

@media only screen and (max-width: 428px) {
  #sec_textdiv2 {
    font-size: 40px;
    margin-left: 22%;
  }
}

@media only screen and (max-width: 412px) {
  #sec_textdiv2 {
    font-size: 40px;
    margin-left: 20%;
  }
}

@media only screen and (max-width: 390px) {
  #sec_textdiv2 {
    font-size: 40px;
    margin-left: 15%;
  }
}

@media only screen and (max-width: 360px) {
  #sec_textdiv2 {
    font-size: 38px;
    margin-left: 15%;
  }
}

#sec_div7:hover #sec_textdiv2 {
  color: black;
  text-shadow: none;
}

#Tengulogocute_12 {
  position: relative;
  width: 50%;
  height: 50%;
  margin-left: 25%;
  opacity: 0;
}

#sec_div7:hover #Tengulogocute_12 {
  opacity: 1;
}

#sectextdiv2_sub {
  position: relative;
  color: white;
  margin-top: 5%;
  font-size: 35px;
  margin-left: 8%;
  text-shadow: 
      -1px -1px 0 #000,  
      1px -1px 0 #000,
      -1px 1px 0 #000,
      1px 1px 0 #000; /* Outline color and thickness */
}

@media only screen and (max-width: 1440px) {
  #sectextdiv2_sub {
    margin-left: 1%;
    font-size: 30px;
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #sectextdiv2_sub {
    margin-left: 1%;
    font-size: 27px;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #sectextdiv2_sub {
    margin-left: 2%;
    font-size: 18px;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #sectextdiv2_sub {
    margin-left: 0%;
    font-size: 16px;
  }
}

@media only screen and (max-width: 915px) and (orientation: landscape) {
  #sectextdiv2_sub {
    margin-left: 2%;
    font-size: 15px;
  }
}

@media only screen and (max-width: 854px) and (orientation: landscape) {
  #sectextdiv2_sub {
    margin-left: 2%;
    font-size: 14px;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  #sectextdiv2_sub {
    margin-left: 2%;
    font-size: 13px;
  }
}

@media only screen and (max-width: 810px) {
  #sectextdiv2_sub {
    margin-left: 2%;
    font-size: 14px;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #sectextdiv2_sub {
    margin-left: 2%;
    font-size: 12px;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #sectextdiv2_sub {
    margin-left: 0%;
    font-size: 11px;
  }
}

@media only screen and (max-width: 428px) {
  #sectextdiv2_sub {
    margin-top: 5%;
    font-size: 14px;
    margin-left: 2%;
  }
}

@media only screen and (max-width: 414px) {
  #sectextdiv2_sub {
    font-size: 14px;
    margin-left: 0%;
  }
}

@media only screen and (max-width: 412px) {
  #sectextdiv2_sub {
    font-size: 13px;
    margin-left: 3%;
  }
}

@media only screen and (max-width: 390px) {
  #sectextdiv2_sub {
    font-size: 13px;
    margin-left: 0%;
  }
}

@media only screen and (max-width: 384px) {
  #sectextdiv2_sub {
    font-size: 12px;
    margin-left: 3%;
  }
}

@media only screen and (max-width: 360px) {
  #sectextdiv2_sub {
    font-size: 11px;
    margin-left: 3%;
  }
}


#sectextdiv2_sub {
  color: white;
}

#sec_div7:hover #sectextdiv2_sub {
  color: black;
  text-shadow: none;
}


#sec_div8 {
  position: relative;
  /*display: inline-flex;*/
  z-index: 10;
  background-image: url(./images/IMG_3809.JPG);
  background-size: 100% 100%;
  margin-top: -22%;
  height: 40rem;
  width: 40%;
  padding: 2%;
  /*border: 3px solid rgb(205, 86, 138);*/
  margin-bottom: 8%;
  margin-left: 52%;
  box-shadow: 0 16px 32px 0 rgba(187, 183, 183, 0.972), 0 6px 20px 0 rgba(187, 183, 183, 0.972);
}

@media only screen and (max-width: 1440px) {
  #sec_div8 {
    position: relative;
    margin-top: -26%;
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #sec_div8 {
   position: relative;
   margin-top: -30%;
   margin-left: 54%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #sec_div8 {
   position: relative;
   height: 35rem;
    width: 35%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #sec_div8 {
    position: relative;
    height: 26rem;
    margin-top: -28%;
  }
}

@media only screen and (max-width: 883px) and (orientation: landscape) {
  #sec_div8 {
    position: relative;
    height: 24rem;
    margin-top: -28%;
  }
}

@media only screen and (max-width: 810px) {
  #sec_div8 {
    position: relative;
    width: 38%;
    height: 30rem;
    margin-left: 52%;
    margin-top: -34%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #sec_div8 {
    position: relative;
    height: 22rem;
    margin-top: -28%;
  }
}

@media only screen and (max-width: 768px) {
  #sec_div8 {
    position: relative;
  }
}

@media only screen and (max-width: 480px) {
  #sec_div8 {
    position: relative;
    height: 18rem;
    width: 65%;
    margin-left: 18%;
    margin-top: 8%;
  }
}


#sec_div8:hover {
  background-image: none;
  background-color: rgba(101, 9, 239, 0.9);
  animation-name: fade;
  animation-duration: 1.5s; 
}

@keyframes fade {
  from {
    opacity: .5;
  }
  to {
    opacity: 1;
  }
}

#sec_textdiv3 {
  position: relative;
  opacity: 1;
  /*border: 3px solid rgb(50, 246, 53);*/
  height: -webkit-fit-content;
  height:fit-content;
  width: -moz-fit-content;
  width:fit-content;
  font-size: 65px;
  margin-left: 25%;
  margin-top: 10%;
  color: white;
  text-shadow: 
      -1px -1px 0 #000,  
      1px -1px 0 #000,
      -1px 1px 0 #000,
      1px 1px 0 #000; /* Outline color and thickness */   
}

@media only screen and (max-width: 1440px) {
  #sec_textdiv3 {
    margin-left: 16%;
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #sec_textdiv3 {
    margin-left: 12%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #sec_textdiv3 {
    margin-left: 0%;
    font-size: 63px;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #sec_textdiv3 {
    margin-left: 8%;
    font-size: 45px;
  }
}

@media only screen and (max-width: 883px) and (orientation: landscape) {
  #sec_textdiv3 {
    margin-left: 6%;
    font-size: 45px;
  }
}

@media only screen and (max-width: 854px) and (orientation: landscape) {
  #sec_textdiv3 {
    margin-left: 4%;
    font-size: 45px;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  #sec_textdiv3 {
    margin-left: 2%;
  }
}

@media only screen and (max-width: 810px) {
  #sec_textdiv3 {
    font-size: 45px;
    margin-left: 6%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #sec_textdiv3 {
    margin-left: 0%;
    font-size: 44px;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #sec_textdiv3 {
    margin-left: 0%;
    font-size: 38px;
  }
}

@media only screen and (max-width: 428px) {
  #sec_textdiv3 {
    font-size: 40px;
    margin-left: 9%;
  }
}

@media only screen and (max-width: 412px) {
  #sec_textdiv3 {
    font-size: 40px;
    margin-left: 7%;
  }
}

@media only screen and (max-width: 390px) {
  #sec_textdiv3 {
    font-size: 40px;
    margin-left: 5%;
  }
}

@media only screen and (max-width: 375px) {
  #sec_textdiv3 {
    margin-left: 2%;
  }
}

@media only screen and (max-width: 360px) {
  #sec_textdiv3 {
    font-size: 38px;
    margin-left: 3%;
  }
}


#sec_div8:hover #sec_textdiv3 {
  color: black;
  text-shadow: none;
}

#Tengulogocute_13 {
  position: relative;
  width: 50%;
  height: 50%;
  margin-left: 25%;
  opacity: 0;
}

#sec_div8:hover #Tengulogocute_13 {
  opacity: 1;
}

#sectextdiv3_sub {
  position: relative;
  color:white;
  margin-top: 5%;
  margin-left: 26.5%;
  font-size: 35px;
  text-shadow: 
      -1px -1px 0 #000,  
      1px -1px 0 #000,
      -1px 1px 0 #000,
      1px 1px 0 #000; /* Outline color and thickness */
}

@media only screen and (max-width: 1440px) {
  #sectextdiv3_sub {
    margin-left: 20%;
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #sectextdiv3_sub {
    margin-left: 14%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #sectextdiv3_sub {
    margin-left: 1%;
    font-size: 34px;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #sectextdiv3_sub {
    margin-left: 10%;
    font-size: 24px;
  }
}

@media only screen and (max-width: 883px) and (orientation: landscape) {
  #sectextdiv3_sub {
    margin-left: 8%;
    font-size: 24px;
  }
}

@media only screen and (max-width: 854px) and (orientation: landscape) {
  #sectextdiv3_sub {
    margin-left: 16%;
    font-size: 18px;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  #sectextdiv3_sub {
    margin-left: 12%;
    font-size: 18px;
  }
}

@media only screen and (max-width: 810px) {
  #sectextdiv3_sub {
    font-size: 20px;
    margin-left: 14%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #sectextdiv3_sub {
    margin-left: 14%;
    font-size: 16px;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #sectextdiv3_sub {
    margin-left: 14%;
    font-size: 14px;
  }
}

@media only screen and (max-width: 428px) {
  #sectextdiv3_sub {
    font-size: 16px;
    margin-left: 18%;
  }
}

@media only screen and (max-width: 412px) {
  #sectextdiv3_sub {
    font-size: 16px;
    margin-left: 15%;
  }
}

@media only screen and (max-width: 390px) {
  #sectextdiv3_sub {
    margin-left: 15%;
  }
}

@media only screen and (max-width: 360px) {
  #sectextdiv3_sub {
    margin-left: 10%;
  }
}


#sectextdiv3_sub {
  color: white;
}

#sec_div8:hover #sectextdiv3_sub {
  color: black;
  text-shadow: none;
}

#bottom_sec_heading2 {
  position: relative;
  /*border: 3px solid rgb(50, 246, 53);*/
  width: 40%;
  height: 10rem;
  left: 12%;
  margin-top: -15%;
  text-align: center;
  z-index: 10;
}

.circle8 {
  position: absolute;
  background-color: #1256f5;
  width: 35rem;
  height: 35rem;
  left: -30%;
  border-radius: 50%;
  margin-top: -20%;
  opacity: .2;
  z-index: 0;
  transition: transform 0.2s; 
}

@media only screen and (max-width: 1440px) {
  .circle8 {
    position: relative;
    width: 30rem;
    height: 30rem;
    left: -10%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  .circle8 {
    position: relative;
    left: -30%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  .circle8 {
    position: relative;
    left: -40%;
    margin-top: -30%;
  }
}

@media only screen and (max-width: 810px) {
  .circle8 {
    position: relative;
    left: -30%;
    margin-top: -40%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  .circle8 {
    position: relative;
    left: -70%;
    margin-top: -55%;
  }
}

@media only screen and (max-width: 428px) {
  .circle8 {
    position: relative;
    left: -110%;
    margin-top: -60%;
  }
}

@media only screen and (max-width: 414px) {
  .circle8 {
    position: relative;
    width: 20rem;
    height: 20rem;
    left: -130%;
    margin-top: -80%;
  }
}

@media only screen and (max-width: 390px) {
  .circle8 {
    position: relative;
    left: -140%;
    margin-top: -70%;
  }
}



/*contact.js*/

#contact_div1 {
  position: relative;
  /*border: 3px solid rgb(181, 8, 40);*/
  /*margin-top: -0%;*/
  max-width: 1920px;
  margin: 0 auto;
  height: 100%;
  overflow: hidden;
}

#pic_15 {
  position: relative;
  height: 55rem;
  width: 100%;
  margin-top: 0%;
}

@media only screen and (max-width: 1440px) {
  #pic_15 {
    position: relative;
    height: 50rem;
    width: 100%;
    margin-top: 0%;
  }
}

#contact_head {
  position: relative;
  /*border: 3px solid rgb(181, 8, 40);*/
  width: 40%;
  height: 10rem;
  margin-left: 15%;
  margin-top: -37%;
  color: white;
}

@media only screen and (max-width: 1440px) {
  #contact_head {
    position: relative;
    width: 40%;
    height: 10rem;
    margin-left: 10%;
    margin-top: -45%;
    color: white;
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #contact_head {
    position: relative;
    margin-top: -50%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #contact_head {
    position: relative;
    margin-top: -60%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #contact_head {
    position: relative;
    margin-top: -70%;
    margin-left: 5%;
  }
}

@media only screen and (max-width: 854px) and (orientation: landscape) {
  #contact_head {
    position: relative;
    margin-top: -75%;
    margin-left: 3%;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  #contact_head {
    position: relative;
    margin-top: -80%;
    margin-left: 3%;
  }
}

@media only screen and (max-width: 810px) {
  #contact_head {
    position: relative;
    margin-left: 3%;
    margin-top: -80%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #contact_head {
    position: relative;
    margin-top: -81%;
    margin-left: 2%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #contact_head {
    position: relative;
    margin-top: -85%;
    margin-left: 4%;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  #contact_head {
    position: relative;
    margin-top: -97%;
    margin-left: 4%;
  }
}

@media only screen and (max-width: 428px) {
  #contact_head {
    position: relative;
    margin-left: 5%;
    margin-top: -150%;
  }
}

@media only screen and (max-width: 414px) {
  #contact_head {
    position: relative;
    margin-left: 5%;
    margin-top: -155%;
  }
}

@media only screen and (max-width: 390px) {
  #contact_head {
    position: relative;
    margin-left: 5%;
    margin-top: -165%;
  }
}

@media only screen and (max-width: 375px) {
  #contact_head {
    position: relative;
    margin-left: 5%;
    margin-top: -170%;
  }
}

@media only screen and (max-width: 360px) {
  #contact_head {
    position: relative;
    margin-left: 5%;
    margin-top: -175%;
  }
}

#contact_head h1{
  font-size: 50px;
}

@media only screen and (max-width: 810px) {
  #contact_head h1{
    font-size: 47px;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #contact_head h1{
    font-size: 47px;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #contact_head h1{
    font-size: 42px;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  #contact_head h1{
    font-size: 36px;
  }
}

@media only screen and (max-width: 428px) {
  #contact_head h1{
    font-size: 36px;
  }
}

@media only screen and (max-width: 390px) {
  #contact_head h1{
    font-size: 32px;
  }
}

#contact_div3 {
  position: relative;
  /*border: 3px solid rgb(181, 8, 40);*/
  width: 40%;
  height: 25rem;
  margin-left: 50%;
  margin-top: -8%;
  padding-top: 5%;
  padding-left: 3%;
  padding-right: 3%;
  /*background-color: rgba(76, 71, 71, 0.8);*/
  background-color: black;
  /*background-color: rgba(255, 255, 255, 0.7);*/
  font-size: 25px;
  color: white;
  border-radius: 10px;
}

@media only screen and (max-width: 1440px) {
  #contact_div3 {
    position: relative;
    width: 40%;
    height: 25rem;
    margin-left: 50%;
    margin-top: -10%;
    padding-top: 5%;
    padding-left: 3%;
    padding-right: 3%;
    background-color: black;
    font-size: 25px;
    color: white;
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #contact_div3 {
    position: relative;
    margin-top: -11%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #contact_div3 {
    position: relative;
    width: 50%;
    height: 18rem;
    margin-top: -20%;
    font-size: 20px;
    margin-left: 40%;
  }
}

@media only screen and (max-width: 915px) and (orientation: landscape) {
  #contact_div3 {
    position: relative;
    width: 50%;
    height: 16rem;
    margin-top: -20%;
    font-size: 20px;
    margin-left: 40%;
    padding-top: 3%;
  }
}

@media only screen and (max-width: 896px) and (orientation: landscape) {
  #contact_div3 {
    position: relative;
    margin-top: -22%;
  }
}

@media only screen and (max-width: 883px) and (orientation: landscape) {
  #contact_div3 {
    position: relative;
    margin-top: -23%;
  }
}

@media only screen and (max-width: 854px) and (orientation: landscape) {
  #contact_div3 {
    position: relative;
    margin-top: -23%;
    width: 50%;
    height: 14rem;
    padding-top: 1%;
    padding-left: 5%;
    margin-left: 42%;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  #contact_div3 {
    position: relative;
    margin-top: -23%;
    width: 50%;
    height: 14rem;
    padding-top: 0%;
    padding-left: 5%;
    margin-left: 42%;
  }
}

@media only screen and (max-width: 810px) {
  #contact_div3 {
    position: relative;
    margin-top: -20%;
    padding-top: 2%;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #contact_div3 {
    position: relative;
    height: 13rem;
    font-size: 19px;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #contact_div3 {
    position: relative;
    height: 13rem;
    font-size: 17px;
    margin-top: -25%;
  }
}

@media only screen and (max-width: 428px) {
  #contact_div3 {
    position: relative;
    margin-top: -40%;
    padding-top: 2%;
    padding-left: 5%;
    width: 50%;
    height: 18rem;
    font-size: 17px;
    margin-left: 40%;
  }
}

@media only screen and (max-width: 414px) {
  #contact_div3 {
    position: relative;
    width: 50%;
    height: 20rem;
    padding-left: 6%;
    font-size: 17px;
  }
}

@media only screen and (max-width: 390px) {
  #contact_div3 {
    position: relative;
    height: 22rem;
  }
}

@media only screen and (max-width: 375px) {
  #contact_div3 {
    position: relative;
    margin-top: -45%;
  }
}



/*#contact_subheading {
  position: relative;
  border: 3px solid rgb(181, 8, 40);
  width: 50%;
  height: 30%;
  left: 20%;
}*/

#contact_div4 {
  position: relative;
  text-align: center;
  /*border: 3px solid rgb(54, 192, 26);*/
  margin-left: 5%;
  height: 55rem;
  width: 60%;
  margin-top: -20%;
  background-color: white;
  box-shadow: 0 16px 32px 0 rgba(142, 139, 139, 0.972), 0 6px 20px 0 rgba(187, 183, 183, 0.972);
}

@media only screen and (max-width: 428px) {
  #contact_div4 {
    position: relative;
    margin-left: 5%;
    height: 54rem;
    width: 90%;
    margin-top: -70%;
  }
}

#contact_div5 {
  background-image: url(./images/IMG_3808.JPG);
  background-size: cover;
  /*background-size: 100% 100%;*/
  /*border: 3px solid rgb(54, 192, 26);*/
  position: relative;
  height: 48rem;
  width: 30%;
  margin-left: 68%;
  margin-top: -40.5%;
  margin-bottom: 10%;
}

@media only screen and (max-width: 1440px) {
  #contact_div5 {
    background-image: url(./images/IMG_3808.JPG);
    background-size: cover;
    position: relative;
    height: 45rem;
    width: 30%;
    margin-left: 68%;
    margin-top: -50.5%;
    margin-bottom: 10%;
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #contact_div5 {
    position: relative;
    margin-top: -56%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #contact_div5 {
    position: relative;
    height: 50rem;
    margin-top: -74%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #contact_div5 {
    position: relative;
    height: 43rem;
    margin-top: -74%;
  }
}

@media only screen and (max-width: 915px) and (orientation: landscape) {
  #contact_div5 {
    position: relative;
    height: 42rem;
    margin-top: -73.5%;
  }
}

@media only screen and (max-width: 896px) and (orientation: landscape) {
  #contact_div5 {
    position: relative;
    height: 41.2rem;
    margin-top: -73.5%;
  }
}

@media only screen and (max-width: 883px) and (orientation: landscape) {
  #contact_div5 {
    position: relative;
    height: 40.7rem;
    margin-top: -73.5%;
  }
}

@media only screen and (max-width: 854px) and (orientation: landscape) {
  #contact_div5 {
    position: relative;
    height: 39.3rem;
    margin-top: -73.5%;
  }
}

@media only screen and (max-width: 844px) and (orientation: landscape) {
  #contact_div5 {
    position: relative;
    height: 38.8rem;
    margin-top: -73.5%;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  #contact_div5 {
    position: relative;
    height: 37.4rem;
    margin-top: -73.5%;
  }
}

@media only screen and (max-width: 810px) {
  #contact_div5 {
    position: relative;
    margin-top: -95%;
    height: 48.2rem;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  #contact_div5 {
    position: relative;
    height: 36.8rem;
    margin-top: -73.5%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #contact_div5 {
    position: relative;
    height: 36.8rem;
    margin-top: -77.5%;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  #contact_div5 {
    position: relative;
    height: 37.8rem;
    margin-top: -90.5%;
  }
}

@media only screen and (max-width: 428px) {
  #contact_div5 {
    position: relative;
    margin-top: 10%;
    height: 48.2rem;
    width: 80%;
    margin-left: 10%;
  }
}

@media only screen and (max-width: 390px) {
  #contact_div5 {
    position: relative;
  
  }
}

#contact_heading {
  position: relative;
  top: 5%;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  z-index: 10;
  margin-left: 5%;
  font-size: 30px;
  color: white;
  /*border: 3px solid rgb(181, 8, 40);*/
}


@media only screen and (max-width: 844px) and (orientation: landscape) {
  #contact_heading {
    position: relative;
    margin-left: 3%;
  }
}

@media only screen and (max-width: 810px) {
  #contact_heading {
    position: relative;
    margin-left: 3%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #contact_heading {
    position: relative;
    margin-left: 8%;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  #contact_heading {
    position: relative;
    margin-left: 9%;
  }
}

@media only screen and (max-width: 428px) {
  #contact_heading {
    position: relative;
    margin-left: 15%;
  }
}

@media only screen and (max-width: 390px) {
  #contact_heading {
    position: relative;
    margin-left: 12%;
  }
}

@media only screen and (max-width: 375px) {
  #contact_heading {
    position: relative;
    margin-left: 10%;
  }
}

@media only screen and (max-width: 360px) {
  #contact_heading {
    position: relative;
    margin-left: 8%;
  }
}

#contact_heading h1{
    position: relative;
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #contact_heading h1{
    position: relative;
    font-size: 28px;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  #contact_heading h1{
    position: relative;
    font-size: 24px;
  }
}

@media only screen and (max-width: 428px) {
  #contact_heading h1{
    position: relative;
    font-size: 35px;
  }
}




#contact_div2 {
  position: relative;
  /*border: 3px solid rgb(181, 8, 40);*/
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  top: 5%;
  margin-left: 5%;
  font-size: 25px;
  color: white;
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #contact_div2 {
    position: relative;
    font-size: 20px;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #contact_div2 {
    position: relative;
    
  }
}

@media only screen and (max-width: 854px) and (orientation: landscape) {
  #contact_div2 {
    position: relative;
    font-size: 18px;
  }
}

@media only screen and (max-width: 844px) and (orientation: landscape) {
  #contact_div2 {
    position: relative;
    margin-left: 3%;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  #contact_div2 {
    position: relative;
    margin-left: 4%;
    font-size: 17px;
  }
}

@media only screen and (max-width: 810px) {
  #contact_div2 {
    position: relative;
    margin-left: 3%;
    font-size: 17px;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #contact_div2 {
    position: relative;
    margin-left: 4%;
    font-size: 16px;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  #contact_div2 {
    position: relative;
    margin-left: 4%;
    font-size: 14px;
  }
}

@media only screen and (max-width: 428px) {
  #contact_div2 {
    position: relative;
    margin-left: 12%;
    font-size: 20px;
  }
}

@media only screen and (max-width: 390px) {
  #contact_div2 {
    position: relative;
    margin-left: 10%;
  }
}

@media only screen and (max-width: 375px) {
  #contact_div2 {
    position: relative;
    margin-left: 8%;
  }
}

@media only screen and (max-width: 360px) {
  #contact_div2 {
    position: relative;
    margin-left: 6%;
  }
}

#cont_number {
  position: relative;
}

@media only screen and (max-width: 428px) {
  #cont_number {
    position: relative;
    margin-left: 22%;
  }
}


#social-icons2 {
  position: relative;
  display: flex;
  /*justify-content: space-around;*/
  margin-top: -7%;
  left: 48%;
  width: 20%;
  height: 10rem;
  /*border: 3px solid rgb(50, 246, 53);*/
  padding: 1px;
}

@media screen and (max-width: 1080px) and (orientation: landscape) {
  #social-icons2 {
    position: relative;
    margin-top: 15%;
    left: 0%;
  }
}

@media screen and (max-width: 926px) and (orientation: landscape) {
  #social-icons2 {
    position: relative;
  
  }
}

@media screen and (max-width: 915px) and (orientation: landscape) {
  #social-icons2 {
    position: relative;
    top: 27%;
  }
}

@media screen and (max-width: 896px) and (orientation: landscape) {
  #social-icons2 {
    position: relative;
  }
}

@media screen and (max-width: 844px) and (orientation: landscape) {
  #social-icons2 {
    position: relative;
  }
}

@media screen and (max-width: 810px) {
  #social-icons2 {
    position: relativ;
    margin-top: 20%;
    left: 0%;
  }
}

@media screen and (max-width: 768px) and (orientation: landscape) {
  #social-icons2 {
   position: relative;
  }
}

@media screen and (max-width: 480px) {
  #social-icons2 {
    position: relative;
    margin-top: 18%;
    left: 20%;
  }
}

@media screen and (max-width: 360px) {
  #social-icons2 {
    position: relative;
    left: 16%;
  }
}

#social-icons2 a {
  /*color: white;*/
  text-decoration: none;
  padding-left: 10%;
  transition: color 0.3s ease;
}


#social-icons2 a .facebook {
  color: #3b5998;
  padding-left: 20%;
}


#social-icons2 a .instagram {
  color: #e4405f;
  padding-left: 20%;
}

#social-icons2 a .linkedin {
  color: #0077b5;
  padding-left: 20%;
}

#social-icons2 a:hover {
  color: #a09898; /* Change to your desired hover color */
}

#social-icons2 a:hover .facebook {
  color: #a09898; /* Change to your desired hover color */
}

#social-icons2 a:hover .instagram {
  color: #a09898; /* Change to your desired hover color */
}

#social-icons2 a:hover .linkedin {
  color: #a09898; /* Change to your desired hover color */
}




#contactform_div {
  position: relative;
  height: 100%;
  width: 100%;
  margin-top: 55%;
  /*border: 3px solid rgb(93, 11, 160);*/
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #contactform_div {
    position: relative;
    
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #contactform_div {
    position: relative;
    margin-top: 75%;
  }
}

@media only screen and (max-width: 810px) {
  #contactform_div {
    position: relative;
    margin-top: 70%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #contactform_div {
    position: relative;
    margin-top: 80%;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  #contactform_div {
    position: relative;
    margin-top: 90%;
  }
}

@media only screen and (max-width: 428px) {
  #contactform_div {
    position: relative;
    margin-top: 120%;
  }
}

@media only screen and (max-width: 390px) {
  #contactform_div {
    position: relative;
  
  }
}


/*ContactForm.js*/

#formcontainer {
  position: relative;
  /*border: 3px solid rgb(31, 251, 222);*/
  height: 100%;
}

#contact_form_title {
  position: relative;
  margin-top: 5%;
  margin-left: -5%;
}

#firstnamediv {
  position: relative;
  display:inline-block;
  /*border: 3px solid rgb(251, 31, 31);*/
  width: 45%;
  margin-top: 5%;
}

#firstname {
  position: relative;
  margin-right: 72%;
}

@media only screen and (max-width: 1440px) {
  #firstname {
    position: relative;
    margin-right: 68%;
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #firstname {
    position: relative;
    margin-right: 64%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #firstname {
    position: relative;
    margin-right: 60%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #firstname {
    position: relative;
    margin-right: 56%;
  }
}

@media only screen and (max-width: 854px) and (orientation: landscape) {
  #firstname {
    position: relative;
    margin-right: 52%;
  }
}

@media only screen and (max-width: 810px) {
  #firstname {
    position: relative;
    margin-right: 52%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #firstname {
    position: relative;
    margin-right: 48%;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  #firstname {
    position: relative;
    margin-right: 42%;
  }
}

@media only screen and (max-width: 428px) {
  #firstname {
    position: relative;
    margin-right: 41%;
  }
}

@media only screen and (max-width: 390px) {
  #firstname {
    position: relative;
    margin-right: 36%;
  }
}

@media only screen and (max-width: 375px) {
  #firstname {
    position: relative;
    margin-right: 33%;
  }
}

@media only screen and (max-width: 360px) {
  #firstname {
    position: relative;
    margin-right: 31%;
  }
}



#lastnamediv {
  position: relative;
  display:inline-block;
  /*border: 3px solid rgb(236, 31, 251);*/
  width: 45%;
  margin-left: 2%;
  margin-top: 5%;
}


#lastname {
  position: relative;
  margin-right: 73%;
}

@media only screen and (max-width: 1440px) {
  #lastname {
    position: relative;
    margin-right: 68%;
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #lastname {
    position: relative;
    margin-right: 65%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #lastname {
    position: relative;
    margin-right: 60%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #lastname {
    position: relative;
    margin-right: 56%;
  }
}

@media only screen and (max-width: 854px) and (orientation: landscape) {
  #lastname {
    position: relative;
    margin-right: 52%;
  }
}

@media only screen and (max-width: 810px) {
  #lastname {
    position: relative;
    margin-right: 52%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #lastname {
    position: relative;
    margin-right: 48%;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  #lastname {
    position: relative;
    margin-right: 42%;
  }
}

@media only screen and (max-width: 428px) {
  #lastname {
    position: relative;
    margin-right: 42%;
  }
}

@media only screen and (max-width: 390px) {
  #lastname {
    position: relative;
    margin-right: 36%;
  }
}

@media only screen and (max-width: 375px) {
  #lastname {
    position: relative;
    margin-right: 34%;
  }
}

@media only screen and (max-width: 360px) {
  #lastname {
    position: relative;
    margin-right: 31%;
  }
}


#emaildiv {
  position: relative;
  display:inline-block;
  /*border: 3px solid rgb(242, 10, 246);*/
  width: 45%;
  margin-top: 5%;
}

#email2 {
  position: relative;
  margin-right: 80%;
}

@media only screen and (max-width: 1440px) {
  #email2 {
    position: relative;
    margin-right: 78%;
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #email2 {
    position: relative;
    margin-right: 75%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #email2 {
    position: relative;
    margin-right: 75%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #email2 {
    position: relative;
    margin-right: 71%;
  }
}

@media only screen and (max-width: 854px) and (orientation: landscape) {
  #email2 {
    position: relative;
    margin-right: 68%;
  }
}

@media only screen and (max-width: 810px) {
  #email2 {
    position: relative;
    margin-right: 69%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #email2 {
    position: relative;
    margin-right: 66%;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  #email2 {
    position: relative;
    margin-right: 64%;
  }
}

@media only screen and (max-width: 428px) {
  #email2 {
    position: relative;
    margin-right: 64%;
  }
}

@media only screen and (max-width: 390px) {
  #email2 {
    position: relative;
    margin-right: 62%;
  }
}

@media only screen and (max-width: 375px) {
  #email2 {
    position: relative;
    margin-right: 60%;
  }
}

@media only screen and (max-width: 360px) {
  #email2 {
    position: relative;
    margin-right: 57%;
  }
}


#phonediv {
  position: relative;
  display:inline-block;
  /*border: 3px solid rgb(10, 246, 30);*/
  width: 45%;
  margin-left: 2%;
  margin-top: 5%;
}

#phone {
  position: relative;
  margin-right: 66%;
}

@media only screen and (max-width: 1440px) {
  #phone {
    position: relative;
    margin-right: 60%;
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #phone {
    position: relative;
    margin-right: 55%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #phone {
    position: relative;
    margin-right: 51%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #phone {
    position: relative;
    margin-right: 44%;
  }
}

@media only screen and (max-width: 854px) and (orientation: landscape) {
  #phone {
    position: relative;
    margin-right: 40%;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  #phone {
    position: relative;
    margin-right: 38%;
  }
}

@media only screen and (max-width: 810px) {
  #phone {
    position: relative;
    margin-right: 38%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #phone {
    position: relative;
    margin-right: 32%;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  #phone {
    position: relative;
    margin-right: 25%;
  }
}

@media only screen and (max-width: 428px) {
  #phone {
    position: relative;
    margin-right: 23%;
  }
}

@media only screen and (max-width: 390px) {
  #phone {
    position: relative;
    margin-right: 17%;
  }
}

@media only screen and (max-width: 375px) {
  #phone {
    position: relative;
    margin-right: 14%;
  }
}

@media only screen and (max-width: 360px) {
  #phone {
    position: relative;
    margin-right: 10%;
  }
}



#companydiv {
  position: relative;
  /*border: 3px solid rgb(24, 104, 243);*/
  width: 93%;
  margin-left: 3%;
  margin-top: 5%;
}

#company2 {
  position: relative;
  margin-right: 86%;
}

@media only screen and (max-width: 1440px) {
  #company2 {
    position: relative;
    margin-right: 83.5%;
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #company2 {
    position: relative;
    margin-right: 82.5%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #company2 {
    position: relative;
    margin-right: 80.5%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #company2 {
    position: relative;
    margin-right: 78.5%;
  }
}

@media only screen and (max-width: 854px) and (orientation: landscape) {
  #company2 {
    position: relative;
    margin-right: 77.5%;
  }
}

@media only screen and (max-width: 810px) {
  #company2 {
    position: relative;
    margin-right: 77.5%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #company2 {
    position: relative;
    margin-right: 75.5%;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  #company2 {
    position: relative;
    margin-right: 73%;
  }
}

@media only screen and (max-width: 428px) {
  #company2 {
    position: relative;
    margin-right: 73%;
  }
}

@media only screen and (max-width: 390px) {
  #company2 {
    position: relative;
    margin-right: 71%;
  }
}

@media only screen and (max-width: 375px) {
  #company2 {
    position: relative;
    margin-right: 70%;
  }
}

@media only screen and (max-width: 375px) {
  #company2 {
    position: relative;
    margin-right: 69%;
  }
}



#servdiv {
  position: relative;
  /*border: 3px solid rgb(151, 103, 228);*/
  width: 93%;
  margin-left: 3%;
  margin-top: 5%;
}

#service {
  position: relative;
  margin-right: 80%;
}

@media only screen and (max-width: 1440px) {
  #service {
    position: relative;
    margin-right: 75%;
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #service {
    position: relative;
    margin-right: 73.5%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #service {
    position: relative;
    margin-right: 71%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #service {
    position: relative;
    margin-right: 66%;
  }
}

@media only screen and (max-width: 854px) and (orientation: landscape) {
  #service {
    position: relative;
    margin-right: 64%;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  #service {
    position: relative;
    margin-right: 63%;
  }
}

@media only screen and (max-width: 810px) {
  #service {
    position: relative;
    margin-right: 63%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #service {
    position: relative;
    margin-right: 60%;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  #service {
    position: relative;
    margin-right: 55%;
  }
}

@media only screen and (max-width: 428px) {
  #service {
    position: relative;
    margin-right: 55%;
  }
}

@media only screen and (max-width: 390px) {
  #service {
    position: relative;
    margin-right: 51%;
  }
}

@media only screen and (max-width: 375px) {
  #service {
    position: relative;
    margin-right: 49%;
  }
}

@media only screen and (max-width: 360px) {
  #service {
    position: relative;
    margin-right: 48%;
  }
}

#messagediv {
  position: relative;
  /*border: 3px solid rgb(10, 22, 246);*/
  width: 93%;
  margin-left: 3%;
  margin-top: 5%;
}

#message2 {
  position: relative;
  margin-right: 86%;
}

@media only screen and (max-width: 1440px) {
  #message2 {
    position: relative;
    margin-right: 83.5%;
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #message2 {
    position: relative;
    margin-right: 83%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #message2 {
    position: relative;
    margin-right: 80.5%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #message2 {
    position: relative;
    margin-right: 78.5%;
  }
}

@media only screen and (max-width: 854px) and (orientation: landscape) {
  #message2 {
    position: relative;
    margin-right: 77%;
  }
}

@media only screen and (max-width: 810px) {
  #message2 {
    position: relative;
    margin-right: 77%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #message2 {
    position: relative;
    margin-right: 75%;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  #message2 {
    position: relative;
    margin-right: 73%;
  }
}

@media only screen and (max-width: 428px) {
  #message2 {
    position: relative;
    margin-right: 73%;
  }
}

@media only screen and (max-width: 390px) {
  #message2 {
    position: relative;
    margin-right: 71%;
  }
}

@media only screen and (max-width: 375px) {
  #message2 {
    position: relative;
    margin-right: 70%;
  }
}

@media only screen and (max-width: 360px) {
  #message2 {
    position: relative;
    margin-right: 70%;
  }
}


#formbutton {
  position: absolute;
  width: 350px;
  height: 50px;
  background-color: #5228db;
  margin-top: -5%;
  margin-left: 18%;
  color: white;
  border: none; /* Remove border */
  outline: none; /* Remove outline */
  border-radius: 10px;
}

@media only screen and (max-width: 1440px) {
  #formbutton {
    position: relative;
    margin-top: 1%;
    margin-left: -37%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #formbutton {
    position: relative;
    margin-top: 6%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #formbutton {
    position: relative;
  }
}

@media only screen and (max-width: 854px) and (orientation: landscape) {
  #formbutton {
    position: relative;
    margin-top: 6%;
  }
}

@media only screen and (max-width: 810px) {
  #formbutton {
    position: relative;
    margin-top: 6%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #formbutton {
    position: relative;
    margin-top: 6%;
  }
}

@media only screen and (max-width: 428px) {
  #formbutton {
    position: relative;
    margin-top: 8%;
    width: 350px;
    height: 50px;
    margin-left: -40%;
  }
}

@media only screen and (max-width: 414px) {
  #formbutton {
    position: relative;
    margin-top: 8%;
    width: 330px;
    height: 50px;
    margin-left: -40%;
  }
}

@media only screen and (max-width: 390px) {
  #formbutton {
    position: relative;
    margin-top: 8%;
    width: 300px;
    height: 50px;
    margin-left: -40%;
  }
}

#recaptcha {
  position: relative;
  left: 6%;
  margin-top: 1%;
}

@media only screen and (max-width: 1440px) {
  #recaptcha {
    position: relative;
    left: 34%;
    margin-top: 1%;
  }
}

@media only screen and (max-width: 1280px) and (orientation: landscape) {
  #recaptcha {
    position: relative;
    left: 32%;
  }
}

@media only screen and (max-width: 1080px) and (orientation: landscape) {
  #recaptcha {
    position: relative;
    left: 28%;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #recaptcha {
    position: relative;
    left: 24%;
  }
}

@media only screen and (max-width: 883px) and (orientation: landscape) {
  #recaptcha {
    position: relative;
    left: 23%;
  }
}

@media only screen and (max-width: 854px) and (orientation: landscape) {
  #recaptcha {
    position: relative;
    left: 22%;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  #recaptcha {
    position: relative;
    left: 28%;
  }
}

@media only screen and (max-width: 810px) {
  #recaptcha {
    position: relative;
    left: 20%;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  #recaptcha {
    position: relative;
    left: 18%;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  #recaptcha {
    position: relative;
    left: 14%;
  }
}

@media only screen and (max-width: 428px) {
  #recaptcha {
    position: relative;
    left: 10.5%;
  }
}

@media only screen and (max-width: 414px) {
  #recaptcha {
    position: relative;
    left: 9.5%;
  }
}

@media only screen and (max-width: 390px) {
  #recaptcha {
    position: relative;
    left: 7%;
  }
}

@media only screen and (max-width: 375px) {
  #recaptcha {
    position: relative;
    left: 5.5%;
  }
}

@media only screen and (max-width: 360px) {
  #recaptcha {
    position: relative;
    left: 3.5%;
  }
}


/*footer.js*/

#footer1 {
  width: 100%;
  height: 48rem;
  /*border: 3px solid rgb(50, 246, 53);*/
  /*background-color: #541412;*/
  /*background: linear-gradient(to bottom, #3a0ca3, #7209b7, #c625f7, #000);*/
  background: linear-gradient(to bottom left, #000, #45088b, #7209b7, #c625f7);
  position: relative;
  color: white;
  margin-top: 5%;
  display: flex;
  z-index: 1;
}

@media screen and (max-width: 1440px) {
  #footer1 {
    height: 45rem;
  }
}

@media only screen and (max-width: 926px) and (orientation: landscape) {
  #footer1 {
    height: 30rem;
  }
}

#waterCanvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: -1;
}


.slide-in-div-footer {
  position: relative;
  opacity: 0;
  transform: translateY(50%);
  transition: opacity 2s, transform 3s;
  z-index: 1;
  display: flex;
  /*justify-content: center;*/
  flex-direction: column;
  align-items: center;
}

.slide-in-div-footer.visible {
  opacity: 1;
  transform: translateY(0);
}

#footerdiv1 {
  position: relative;
  /*border: 3px solid rgb(62, 65, 250);*/
  height: 90%;
  width: 80%;
  left: 10%;
  margin-top: 3%;
  z-index: 1;
}

@media screen and (max-width: 1440px) {
  #footerdiv1 {
    margin-top: 5%;
  }
}

#tengucute_div {
  position: relative;
  /*border: 3px solid rgb(50, 246, 53);*/
  width: 20%;
  height: 40%;
  padding: 1px;
}

@media screen and (max-width: 375px) {
  #tengucute_div {
    margin-top: 20%;
  }
}

/*@media screen and (max-width: 1440px) {
  #tengucute_div {
    position: relative;
  }
}

/*@media screen and (max-width: 1280px) {
  #tengucute_div {
    left: 3%;
  }
}

@media screen and (max-width: 810px) {
  #tengucute_div {
    left: 45%;
    margin-top: 3%;
  }
}

@media screen and (max-width: 768px) {
  #tengucute_div {
    left: 45%;
    margin-top: 3%;
  }
}

@media screen and (max-width: 480px) {
  #tengucute_div {
    left: 45%;
    margin-top: 5%;
  }
}*/

#tengucute_image {
  position: relative;
  height: 100%;
  width: 100%;
}

#yokaifooter_header {
  position: relative;
  font-size: 5rem;
  font-weight: 700;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  /*border: 3px solid rgb(50, 246, 53);*/
  padding: 1px;
  margin-top: 1%;
}

/*@media screen and (max-width: 1440px) {
  #yokaifooter_header {
    position: relative;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: fit-content;
    padding: 1px;
  }
}

@media screen and (max-width: 1280px) {
  #yokaifooter_header {
    margin-top: -4.5%;
    left: 34%;
  }
}

@media screen and (max-width: 1080px) {
  #yokaifooter_header {
    margin-top: -5%;
    left: 29%;
  }
}*/

@media screen and (max-width: 926px) and (orientation: landscape) {
  #yokaifooter_header {
    font-size: 3rem;
  }
}

/*@media screen and (max-width: 810px) {
  #yokaifooter_header {
    margin-top: 0%;
    left: 43%;
  }
}

@media screen and (max-width: 768px) {
  #yokaifooter_header {
    margin-top: 0%;
    left: 43%;
  }
}

@media screen and (max-width: 480px) {
  #yokaifooter_header {
    margin-top: 5%;
    left: 40%;
  }
}*/

#footerdiv2 {
  position: relative;
  font-size: 2rem;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  /*border: 3px solid rgb(50, 246, 53);*/
  padding: 1px;
  
}

/*@media screen and (max-width: 1440px) {
  #footerdiv2 {
    position: relative;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  padding: 1px;
  }
}

@media screen and (max-width: 1280px) {
  #footerdiv2 {
    margin-top: -3.8%;
    left: 47%;
  }
}

@media screen and (max-width: 1080px) {
  #footerdiv2 {
    left: 41%;
    margin-top: -4.2%;
  }
}*/

@media screen and (max-width: 926px)  and (orientation: landscape) {
  #footerdiv2 {
    font-size: 1.5rem;
    margin-top: -3%;
  }
}

/*@media screen and (max-width: 810px) {
  #footerdiv2 {
    left: 30%;
    margin-top: -2.2%;
  }
}

@media screen and (max-width: 800px) {
  #footerdiv2 {
    left: 32%;
  }
}

@media screen and (max-width: 768px) {
  #footerdiv2 {
    left: 30%;
    margin-top: -2.2%;
  }
}

@media screen and (max-width: 480px) {
  #footerdiv2 {
    left: 30%;
    margin-top: -2%;
  }
}*/

@media screen and (max-width: 428px) {
  #footerdiv2 {
    text-align: center;
  }
}

/*@media screen and (max-width: 360px) {
  #footerdiv2 {
    left: 12%;
    margin-top: -2%;
  }
}*/

#footerdiv3 {
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  /*border: 3px solid rgb(50, 246, 53);*/
  padding: 1px;
  display: none;
}

/*@media screen and (max-width: 1440px) {
  #footerdiv3 {
    position: relative;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: fit-content;
    padding: 1px;
    
  }
}

@media screen and (max-width: 1280px) {
  #footerdiv3 {
    left: 75%;
    margin-top: -4%;
  }
}

@media screen and (max-width: 1080px) {
  #footerdiv3 {
    left: 73%;
    margin-top: -4.5%;
  }
}

@media screen and (max-width: 926px) {
  #footerdiv3 {
    width: 30%;
    left: 78%;
    margin-top: -5.5%;
    text-align: center;
  }
}

@media screen and (max-width: 854px) {
  #footerdiv3 {
    width: 30%;
    left: 78%;
    margin-top: -6.5%;
    text-align: center;
  }
}

@media screen and (max-width: 810px) {
  #footerdiv3 {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  #footerdiv3 {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  #footerdiv3 {
    display: none;
  }
}*/

#footerdiv4 {
  position: relative;
  width: 40%;
  height: 50%;
  /*border: 3px solid rgb(50, 246, 53);*/
  padding: 1px;
  left: 3%;
  margin-top: -1%;
  display: none;
}

/*@media screen and (max-width: 1440px) {
  #footerdiv4 {
    position: relative;
    width: 40%;
    height: 50%;
    padding: 1px;
    left: 0%;
    margin-top: 0%;
  }
}

@media screen and (max-width: 926px) {
  #footerdiv4 {
    margin-top: -10%;
    left: -5%;
  }
}

@media screen and (max-width: 896px) {
  #footerdiv4 {
    margin-top: -12%;
  }
}

@media screen and (max-width: 854px) {
  #footerdiv4 {
    margin-top: -12.5%;
    left: -5%;
  }
}

@media screen and (max-width: 844px) {
  #footerdiv4 {
    margin-top: -12%;
    left: -5%;
  }
}

@media screen and (max-width: 812px) {
  #footerdiv4 {
    margin-top: -10%;
    left: -5%;
  }
}


@media screen and (max-width: 810px) {
  #footerdiv4 {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  #footerdiv4 {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  #footerdiv4 {
    display: none;
  }
}*/

#yokaifooter_header2 {
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  margin-top: 2%;
  /*border: 3px solid rgb(50, 246, 53);*/
}

#footer_contactinfo {
  position: relative;
  width: 100%;
  /*border: 3px solid rgb(50, 246, 53);*/
}

/*@media screen and (max-width: 896px) {
  #footer_contactinfo {
     margin-top: 12%;
  }
}

@media screen and (max-width: 854px) {
  #footer_contactinfo {
     margin-top: 12%;
  }
}

@media screen and (max-width: 844px) {
  #footer_contactinfo {
     margin-top: 14%;
  }
}*/

#footerdiv5 {
  position: relative;
  /*width: 32.5%;
  height: 12%;*/
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  text-align: center;
  font-size: 4rem;
  /*border: 3px solid rgb(50, 246, 53);*/
  padding: 0px;
  color: white;
  z-index: 10;
}

/*@media screen and (max-width: 1440px) {
  #footerdiv5 {
    position: relative;
    width: 25%;
    height: 20%;
    text-align: center;
    font-size: 35px;
    padding: 3px;
    color: white;
    z-index: 10;
  }
}

@media screen and (max-width: 1280px) {
  #footerdiv5 {
    width: 40%;
    margin-top: -10%;
    left: 32%;
  }
}


@media screen and (max-width: 1080px) {
  #footerdiv5 {
    width: 40%;
    margin-top: -15%;
    left: 30%;
  }
}*/

@media screen and (max-width: 926px) {
  #footerdiv5 {
    font-size: 3rem;
  }
}

/*@media screen and (max-width: 854px) {
  #footerdiv5 {
    width: 50%;
    height: 20%;
    left: 27%;
    margin-top: -12%;
    
  }
}

@media screen and (max-width: 844px) {
  #footerdiv5 {
    width: 50%;
    height: 20%;
    left: 27%;
    margin-top: -12%;
    
  }
}

@media screen and (max-width: 812px) {
  #footerdiv5 {
    margin-top: -14%;
  }
}

@media screen and (max-width: 810px) {
  #footerdiv5 {
    margin-top: 0%;
    width: 60%;
    height: 20%;
    left: 20%;
  }
}

@media screen and (max-width: 768px) {
  #footerdiv5 {
    margin-top: 0%;
    width: 60%;
    height: 20%;
    left: 20%;
  }
}

@media screen and (max-width: 428px) {
  #footerdiv5 {
    margin-top: -0%;
    width: 80%;
    height: 20%;
    left: 10%;
    font-size: 30px;
  }
}

@media screen and (max-width: 414px) {
  #footerdiv5 {
    margin-top: 0%;
    width: 80%;
    height: 20%;
    left: 10%;
    font-size: 28px;
  }
}

@media screen and (max-width: 390px) {
  #footerdiv5 {
    left: 13%;
  }
}

@media screen and (max-width: 360px) {
  #footerdiv5 {
    left: 12%;
    font-size: 28px;
  }
}*/


#footerdiv5 a {
  
  color: white;
  text-decoration: none;
}

/*#footerdiv5 a:hover {
  text-decoration: underline; 
}*/

#footerdiv5 a::before,
#footerdiv5 a::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 50%; /* Start from the center */
  width: 0; /* Start with no width */
  height: 5px;
  background-color: #bd0f1d;
  transition: width 0.3s ease, left 0.3s ease;
}

#footerdiv5 a::before {
  transform: translateX(-45%);
}

#footerdiv5 a::after {
  transform: translateX(45%);
}

#footerdiv5 a:hover::before {
  width: 50%; /* Extend to the left */
  left: 25%; /* Move the pseudo-element to the left edge */
}

#footerdiv5 a:hover::after {
  width: 50%; /* Extend to the right */
  left: 25%; /* Move the pseudo-element to the right edge */
}

#href_footer {
  border-bottom: none;
  color: white;
  text-decoration: none;
  font-weight: bolder;
}

#href_footer:hover {
 color: #bd0f1d;
}


#social-icons {
  position: relative;
  display: flex;
  justify-content: space-around;
  margin-top: -4.5%;
  left: 76%;
  width: 20%;
  height: -webkit-fit-content;
  height: fit-content;
  /*border: 3px solid rgb(50, 246, 53);*/
  padding: 1px;
  display: none;
}

/*@media screen and (max-width: 1440px) {
  #social-icons {
    position: relative;
    display: flex;
    justify-content: space-around;
    margin-top: -5.5%;
    left: 78%;
    width: 20%;
    height: -webkit-fit-content;
    height: fit-content;
    padding: 1px;
  }
}

@media screen and (max-width: 1280px) {
  #social-icons {
    margin-top: -6%;
    left: 80%;
  }
}

@media screen and (max-width: 926px) {
  #social-icons {
    margin-top: -5.5%;
    left: 83%;
  }
}

@media screen and (max-width: 896px) {
  #social-icons {
    left: 83%;
    margin-top: -5%;
  }
}

@media screen and (max-width: 844px) {
  #social-icons {
    left: 83%;
    margin-top: -5.5%;
  }
}

@media screen and (max-width: 812px) {
  #social-icons {
    left: 83%;
    margin-top: -6.5%;
  }
}

@media screen and (max-width: 810px) {
  #social-icons {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  #social-icons {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  #social-icons {
    display: none;
  }
}*/

#social-icons a {
  /*color: white;*/
  text-decoration: none;
  
  transition: color 0.3s ease;
  display: none;
}


/*#social-icons a .facebook {
  color: #3b5998;
}


#social-icons a .instagram {
  color: #e4405f;
}

#social-icons a .linkedin {
  color: #0077b5;
}

#social-icons a:hover {
  color: #a09898; 
}

#social-icons a:hover .facebook {
  color: #a09898; 
}

#social-icons a:hover .instagram {
  color: #a09898; 

#social-icons a:hover .linkedin {
  color: #a09898; 
}*/



#copyright {
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  /*border: 3px solid rgb(50, 246, 53);*/
  padding: 1px;
  margin-top: 5%;
}

/*@media screen and (max-width: 1440px) {
  #copyright {
    position: relative;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: fit-content;
    padding: 1px;
  }
}

@media screen and (max-width: 1080px) {
  #copyright {
    left: 72%;
    margin-top: 7%;
  }
}

@media screen and (max-width: 926px) {
  #copyright {
    left: 35%;
    margin-top: 6%;
  }
}

@media screen and (max-width: 844px) {
  #copyright {
    left: 35%;
  }
}
@media screen and (max-width: 812px) {
  #copyright {
    margin-top: 5%;
  }
}

@media screen and (max-width: 810px) {
  #copyright {
    left: 30%;
    margin-top: 5%;
  }
}

@media screen and (max-width: 768px) {
  #copyright {
    left: 30%;
    margin-top: 5%;
  }
}

@media screen and (max-width: 480px) {
  #copyright {
    left: 18%;
    margin-top: 5%;
  }
}

@media screen and (max-width: 428px) {
  #copyright {
    left: 15%;
  }
}

@media screen and (max-width: 390px) {
  #copyright {
    left: 14%;
  }
}

@media screen and (max-width: 375px) {
  #copyright {
    left: 13.5%;
    margin-top: 10%;
  }
}

@media screen and (max-width: 360px) {
  #copyright {
    left: 8%;
  }
}*/

/* styles.css */

/*.smoke-cursor {
  position: fixed;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: rgba(150, 50, 200, 0.7); /* Adjust colors and opacity */
  /*z-index: 9999; /* Ensure it's above other content */
  /*pointer-events: none; /* Make sure it doesn't interfere with mouse events */
  /*mix-blend-mode: screen; /* Optional: Apply blending mode for a softer effect */
  /*animation: smoke 2s linear infinite; /* Adjust animation duration and timing */
/*}

@keyframes smoke {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(3);
    opacity: 0;
  }
}*/

/* Smoke cursor effect */
/*.smoke-cursor {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: rgba(255, 255, 255, 0.8); 
  border-radius: 50%;
  pointer-events: none;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5); 
  animation: smoke 2s ease-out infinite;
}

@keyframes smoke {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(3);
    opacity: 0;
  }
}*/

/*.smoke-cursor {
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  pointer-events: none;
  filter: url(#smoke);
  animation: smoke-animation 3s ease-out infinite;
}

@keyframes smoke-animation {
  0% {
    transform: translate(0, 0) scale(1);
    opacity: 1;
  }
  50% {
    transform: translate(-50px, -50px) scale(1.5);
    opacity: 0.5;
  }
  100% {
    transform: translate(-100px, -100px) scale(2);
    opacity: 0;
  }
}
  */

